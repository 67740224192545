// react hooks
import { useEffect, useState, useCallback } from 'react'

// react router dom hooks and components
import { useNavigate, useParams } from 'react-router-dom'

// axios instance
import axios from 'axios'

// @mui components
import {
  Grid,
  Box,
  Button,
  TextField,
  Typography,
  IconButton,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Input
} from '@mui/material'

// @mui icons
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import { ArrowBack, AttachFile, Delete } from '@mui/icons-material'
// Application Users context
import { useUsersContext } from '../../context/UserContext'

// Generic Components
import { DashboardLayout } from '../../components'

// Specific components
import DefenseDialog from './components/DefenseDialog'
import ConfirmDialog from './components/ConfirmDialog'
import DownloadAnnexButton from './components/DownloadAnnexButton'

// utils functions
import {
  downloadAnnexDefense,
  downloadPdfGuide,
  getGuides,
  updateDefense
} from '../../utils/axios'

function DynamicGuide () {
  const {
    loadingDash,
    changeLoadingDash,
    handleErrorData,
    handleErrorModalState
  } = useUsersContext()

  const { id } = useParams()
  const navigate = useNavigate()

  const [petInfo, setPetInfo] = useState({})
  const [guideInfo, setGuideInfo] = useState({})
  const [showDefense, setShowDefense] = useState(false)

  const [procedure, setProcedure] = useState({})
  const [openDefense, setOpenDefense] = useState(false)

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [inputFiles, setInputFiles] = useState([])
  const [attachments, setAttachments] = useState([])
  const [refreshAttach, setRefreshAttach] = useState(false)
  
  const attachmentsBaseUrl = 'https://appdocuments-prd.s3.amazonaws.com/'

  const email = localStorage.getItem('email')

  function handleDefenseDialog (state) {
    setOpenDefense(state)
  }

  function handleConfirmDialog (state) {
    setOpenConfirmDialog(state)
  }

  const getAttachmentUrl = fileName => {
    return `${attachmentsBaseUrl}${fileName}`
  }

  const handleProcedureDefense = useCallback(
    (defesa, index) => {
      setGuideInfo({
        ...guideInfo,
        procedimentos: [
          ...guideInfo.procedimentos.slice(0, index),
          {
            ...guideInfo.procedimentos[index],
            defesa: defesa
          },
          ...guideInfo.procedimentos.slice(index + 1)
        ]
      })
    },
    [guideInfo]
  )

  const handleProcedureFile = useCallback(
    (file, index) => {
      setGuideInfo({
        ...guideInfo,
        procedimentos: [
          ...guideInfo.procedimentos.slice(0, index),
          {
            ...guideInfo.procedimentos[index],
            file: file
          },
          ...guideInfo.procedimentos.slice(index + 1)
        ]
      })
    },
    [guideInfo]
  )

  const handleDisableDefenseButton = () => {
    for (const procedimento of guideInfo?.procedimentos) {
      if (
        !procedimento.hasOwnProperty('defesa') ||
        procedimento.defesa === '' ||
        guideInfo?.defended_by
      ) {
        return true
      }
    }

    return false
  }

  async function handleGetGuia () {
    const { data, status } = await getGuides(id, 'guia')

    if (status !== 200) {
      handleErrorData({
        title: 'Erro ao carregar os dados',
        message: data?.message
      })

      handleErrorModalState(true)
      return
    }

    if (
      data[0].status_auditoria === 'parcialmente glosada' ||
      data[0].status_auditoria === 'glosado totalmente'
    ) {
      setShowDefense(true)
    }

    setGuideInfo(data[0])
    return data[0]
  }

  async function getAttachments () {
    // if (!microchip_number) {
    //   return;
    // }

    const api = `https://q7amrtd8t8.execute-api.us-east-1.amazonaws.com/dev/autorizador/get_attachments?guia_number=${id}`

    try {
      const response = await axios.get(api, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      })
      setAttachments(response?.data?.files)
    } catch (error) {}
  }

  async function getTablePets (microchip_number) {
    if (!microchip_number) {
      return
    }

    const api = `https://q7amrtd8t8.execute-api.us-east-1.amazonaws.com/dev/customer/pets/list_by_params?params=microchip&values=${microchip_number}`

    try {
      const response = await axios.get(api, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      })

      setPetInfo(response?.data[0])
    } catch (error) {}
  }

  const handleUploadFile = event => {
    const files = event.target.files

    setInputFiles([...inputFiles, ...files])
  }

  const handleRemoveFile = index => {
    const newFiles = [...inputFiles]
    newFiles.splice(index, 1)
    setInputFiles(newFiles)
  }
  const uploadToS3 = async (presignedUrl, file) => {
    try {
      const response = await axios.put(presignedUrl, file, {
        headers: {
          'Content-Type': file.type,
          'x-amz-acl': 'public-read'
        }
      })
      // console.log('Upload do arquivo para o S3 bem-sucedido:', response)
    } catch (error) {
      console.error(
        `Erro ao fazer upload do arquivo ${file.name} para o S3:`,
        error
      )
      throw error
    }
  }

  const handleSendToLambda = async () => {
    if (!inputFiles || inputFiles.length === 0) {
      console.error('Nenhum arquivo fornecido.')
      return
    }
    changeLoadingDash(true)

    const promises = inputFiles.map(async file => {
      try {
        const formData = new FormData()
        formData.append('file_name', file.name)
        formData.append('guia', guideInfo?.numero_guia)

        const response = await axios.post(
          'https://q7amrtd8t8.execute-api.us-east-1.amazonaws.com/dev/autorizador/generate_attached_url',
          formData,
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json'
            }
          }
        )

        const presignedUrl = response.data.presigned_url

        await uploadToS3(presignedUrl, file)
        changeLoadingDash(false)
        // console.log(`Arquivo ${file.name} enviado com sucesso!`)
      } catch (error) {
        changeLoadingDash(false)
        alert('Algo deu errado no envio dos arquivos')
        console.error(`Erro ao enviar arquivo ${file.name}:`, error)
      }
    })

    await Promise.all(promises)
    changeLoadingDash(false)
    alert('Arquivo enviado com sucesso.')
    setInputFiles([])
    
    console.log('Todos os arquivos foram enviados com sucesso!')
  }

  async function handleDownloadPdf (guia, microchip) {
    changeLoadingDash(true)

    const { data = {}, status = 500 } = await downloadPdfGuide(guia, microchip)

    if (status !== 200) {
      changeLoadingDash(false)

      handleErrorData({
        title: 'Erro ao carregar os dados',
        message: data?.message
      })

      handleErrorModalState(true)
      return
    }

    if (!data.download_link) {
      changeLoadingDash(false)

      handleErrorData({
        title: 'Erro ao carregar os dados',
        message: 'Não foi possível gerar o PDF.'
      })

      handleErrorModalState(true)
      return
    }

    // Extrair o URL do arquivo do corpo da resposta
    const fileUrl = data.download_link

    // Criar um link de download e iniciar o download
    const a = document.createElement('a')
    a.href = fileUrl
    a.target = '_blank' // Abrir o link em uma nova aba
    a.click()

    changeLoadingDash(false)
  }

  async function handleDownloadAnnexDefense (cnpj) {
    changeLoadingDash(true)

    const { data, status } = await downloadAnnexDefense(cnpj)

    if (status !== 200) {
      handleErrorData({
        title: 'Erro ao baixar o arquivo',
        message: data?.message
      })

      handleErrorModalState(true)
      changeLoadingDash(false)
      return
    }

    const fileUrl = data.download_link

    // Criar um link de download e iniciar o download
    const a = document.createElement('a')
    a.href = fileUrl
    a.target = '_blank' // Abrir o link em uma nova aba
    a.click()

    changeLoadingDash(false)
  }

  async function handleDefendGlosa () {
    changeLoadingDash(true)
    const { data, status } = await updateDefense({
      ...guideInfo,
      defended_by: email
    })

    if (status !== 200) {
      handleErrorData({
        title: 'Erro ao carregar os dados',
        message: data?.message
      })

      handleErrorModalState(true)
      changeLoadingDash(false)
      return
    }

    changeLoadingDash(false)

    handleErrorData({
      title: 'Guia defendida com sucesso',
      message: 'A guia foi defendida com sucesso!',
      type: 'success'
    })
    handleErrorModalState(true)

    navigate(-1)
  }

  useEffect(() => {
    changeLoadingDash(true)
    
    try {
      handleGetGuia()
        .then(res => getTablePets(res?.microchip_number))
        .then(res => getAttachments())
        .then(() => changeLoadingDash(false))
  
    } catch (error) {
      changeLoadingDash(true)
    }
  }, [id])

  useEffect(() => {
    if (!guideInfo.procedimentos) return

    handleDisableDefenseButton()
  }, [guideInfo])

  const textFieldsInfoGuia = [
    [
      {
        label: 'número da guia',
        value: guideInfo?.numero_guia
      },
      {
        label: 'nome do credenciado',
        value: guideInfo?.name_credenciado
      },
      {
        label: 'cnpj/cpf',
        value:
          guideInfo?.cnpj_lab === guideInfo?.cnpj_solicitante
            ? guideInfo?.cnpj_solicitante
            : guideInfo?.cnpj_lab
      },
      {
        label: 'tipo atendimento',
        value: guideInfo?.tipo_atendimento
      }
    ],
    [
      {
        label: 'plano',
        value: guideInfo?.plano
      },
      {
        label: 'tipo guia',
        value: guideInfo?.tipo_guia
      },
      {
        label: 'data solicitação',
        value: guideInfo?.created_at
      },
      {
        label: 'data retroativa',
        value: guideInfo?.dataRetro
      }
    ],
    [
      {
        label: 'veterinário solicitante',
        value: guideInfo?.vet_solicitante,
        multiline: true
      },
      {
        label: 'microchip',
        value: guideInfo?.microchip_number
      },

      {
        label: 'nome do pet',
        value: guideInfo?.pet_name
      },
      {
        label: 'peso',
        value: guideInfo?.peso
      }
    ],
    [
      {
        label: 'espécie',
        value: petInfo?.metadata?.pet_especie
      },
      {
        label: 'raça',
        value: petInfo?.metadata?.pet_raca
      },
      {
        label: 'sexo',
        value: petInfo?.metadata?.pet_sexo
      },
      {
        label: 'data de nascimento',
        value: petInfo?.metadata?.pet_nascimento
      }
    ],
    [
      {
        label: 'observacoes',
        value: guideInfo?.observacoes
      },
      {
        label: 'status',
        value: guideInfo?.status
      },
      {
        label: 'laudo',
        value: guideInfo?.laudo,
        multiline: true
      }
    ]
  ]

  return (
    <DashboardLayout>
      <DefenseDialog
        procedure={procedure}
        openDefense={openDefense}
        handleDefenseDialog={handleDefenseDialog}
        handleProcedureDefense={handleProcedureDefense}
        handleProcedureFile={handleProcedureFile}
      />

      <ConfirmDialog
        openConfirmDialog={openConfirmDialog}
        handleConfirmDialog={handleConfirmDialog}
        handleDefendGlosa={handleDefendGlosa}
      />

      <Box py={2}>
        <Box paddingBottom={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Box
                marginX={2}
                paddingX={6}
                paddingY={6}
                borderRadius='20px'
                sx={{ backgroundColor: '#FFFFFF' }}
              >
                <Box marginBottom={4}>
                  <IconButton
                    sx={{ width: 10, left: 0 }}
                    aria-label='Voltar'
                    onClick={() => navigate(-1)}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                </Box>

                <Box
                  gap={2}
                  display='flex'
                  flexDirection='row'
                  marginBottom={4}
                  justifyContent='space-between'
                >
                  <Box>
                    <Typography
                      variant='body1'
                      style={{
                        fontStyle: 'normal',
                        fontWeight: 500,
                        fontSize: '16px',
                        lineHeight: '14px',
                        letterSpacing: '-0.8px',
                        textTransform: 'uppercase',
                        color: '#9c9da9'
                      }}
                    >
                      guias
                    </Typography>
                    <Typography
                      variant='body1'
                      style={{
                        fontStyle: 'normal',
                        fontWeight: 500,
                        fontSize: '22px',
                        lineHeight: '14px',
                        letterSpacing: '-1px',
                        textTransform: 'uppercase',
                        color: '#32D1A0',
                        marginTop: '18px'
                      }}
                    >
                      dados gerais
                    </Typography>
                  </Box>

                  {/* <Box>
                    <Button
                      fullWidth
                      variant="contained"
                      onClick={() =>
                        handleDownloadPdf(
                          guideInfo?.numero_guia,
                          guideInfo?.microchip_number
                        )
                      }
                    >
                      gerar pdf
                    </Button>
                  </Box>  */}
                </Box>

                <Box marginBottom={4}>
                  {textFieldsInfoGuia.map((textFieldBlock, index) => {
                    return (
                      <Grid key={index} container marginBottom={2} spacing={8}>
                        {textFieldBlock.map((textField, index) => {
                          return (
                            <Grid key={index} item xs={3}>
                              <Typography
                                variant='body1'
                                style={{
                                  fontStyle: 'normal',
                                  fontWeight: 500,
                                  fontSize: '14px',
                                  lineHeight: '20px',
                                  letterSpacing: '-1px',
                                  textTransform: 'uppercase',
                                  color: '#AFBCD2'
                                }}
                              >
                                {textField.label}
                              </Typography>

                              <TextField
                                variant='filled'
                                multiline={textField.multiline}
                                value={textField.value || 'Sem dado'}
                                disabled
                                fullWidth
                                inputProps={{
                                  style: {
                                    textAlign: 'left',
                                    textTransform: 'uppercase',
                                    padding: '5px'
                                  }
                                }}
                                InputProps={{
                                  disableUnderline: true,
                                  style: {
                                    backgroundColor: '#F5F7FB',
                                    boxShadow:
                                      '0px 1px 1px rgba(205, 205, 205, 0.25)',
                                    borderRadius: '7px',
                                    fontSize: '14px',
                                    textTransform: 'uppercase',
                                    padding: '5px'
                                  }
                                }}
                              />
                            </Grid>
                          )
                        })}
                      </Grid>
                    )
                  })}
                </Box>

                <Box>
                  <Typography
                    marginBottom={4}
                    color='primary'
                    style={{
                      fontWeight: 600,
                      fontSize: '22px',
                      lineHeight: '14px',
                      letterSpacing: '-1px'
                    }}
                  >
                    Procedimentos
                  </Typography>

                  <TableContainer>
                    <Table style={{ width: '100%' }}>
                      <TableHead style={{ display: 'table-header-group' }}>
                        <TableRow>
                          <TableCell
                            align='center'
                            style={{
                              verticalAlign: 'middle',
                              fontFamily: 'Plus Jakarta Sans',
                              fontStyle: 'normal',
                              fontWeight: 500,
                              fontSize: '14px',
                              lineHeight: '20px',
                              letterSpacing: '-1px',
                              textTransform: 'uppercase',
                              color: '#32d1a0'
                            }}
                          >
                            ID
                          </TableCell>

                          <TableCell
                            align='center'
                            style={{
                              verticalAlign: 'middle',
                              fontFamily: 'Plus Jakarta Sans',
                              fontStyle: 'normal',
                              fontWeight: 500,
                              fontSize: '14px',
                              lineHeight: '20px',
                              letterSpacing: '-1px',
                              textTransform: 'uppercase',
                              color: '#32d1a0'
                            }}
                          >
                            Nome
                          </TableCell>

                          {/* <TableCell
                            align='center'
                            style={{
                              verticalAlign: 'middle',
                              fontFamily: 'Plus Jakarta Sans',
                              fontStyle: 'normal',
                              fontWeight: 500,
                              fontSize: '14px',
                              lineHeight: '20px',
                              letterSpacing: '-1px',
                              textTransform: 'uppercase',
                              color: '#32d1a0'
                            }}
                          >
                            Status
                          </TableCell> */}

                          {showDefense && (
                            <>
                              <TableCell
                                align='center'
                                style={{
                                  verticalAlign: 'middle',
                                  fontFamily: 'Plus Jakarta Sans',
                                  fontStyle: 'normal',
                                  fontWeight: 500,
                                  fontSize: '14px',
                                  lineHeight: '20px',
                                  letterSpacing: '-1px',
                                  textTransform: 'uppercase',
                                  color: '#32d1a0'
                                }}
                              >
                                Justificativa auditoria
                              </TableCell>

                              <TableCell
                                align='center'
                                style={{
                                  verticalAlign: 'middle',
                                  fontFamily: 'Plus Jakarta Sans',
                                  fontStyle: 'normal',
                                  fontWeight: 500,
                                  fontSize: '14px',
                                  lineHeight: '20px',
                                  letterSpacing: '-1px',
                                  textTransform: 'uppercase',
                                  color: '#32d1a0'
                                }}
                              >
                                Defesa
                              </TableCell>
                            </>
                          )}
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {guideInfo?.procedimentos?.map(
                          (procedimento, index) => {
                            return (
                              <TableRow key={procedimento.id}>
                                <TableCell align='center'>
                                  {procedimento?.id}
                                </TableCell>

                                <TableCell align='center'>
                                  {procedimento?.nome}
                                </TableCell>

                                {/* <TableCell
                                  align='center'
                                  sx={{ textTransform: 'capitalize' }}
                                >
                                  {procedimento?.status || 'Não auditado'}
                                </TableCell> */}

                                {showDefense && (
                                  <>
                                    <TableCell align='center'>
                                      <TextField
                                        value={procedimento?.justificativa}
                                        fullWidth
                                        multiline
                                        disabled
                                      />
                                    </TableCell>

                                    <TableCell align='center'>
                                      {guideInfo?.defended_by ? (
                                        <TextField
                                          value={procedimento?.defesa}
                                          fullWidth
                                          multiline
                                          disabled
                                        />
                                      ) : (
                                        <Button
                                          variant='contained'
                                          color='primary'
                                          disabled={
                                            procedimento?.status !== 'glosado'
                                          }
                                          sx={{
                                            boxShadow:
                                              '0px 1px 1px rgba(205, 205, 205, 0.25)'
                                          }}
                                          onClick={() => {
                                            setProcedure({
                                              ...procedimento,
                                              index
                                            })
                                            setOpenDefense(true)
                                          }}
                                        >
                                          Justificar
                                        </Button>
                                      )}
                                    </TableCell>

                                    {guideInfo?.defended_by && (
                                      <TableCell align='center'>
                                        <DownloadAnnexButton
                                          handleDownloadAnnexDefense={
                                            handleDownloadAnnexDefense
                                          }
                                        />
                                      </TableCell>
                                    )}
                                  </>
                                )}
                              </TableRow>
                            )
                          }
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>

                {showDefense && (
                  <Grid
                    item
                    xs={12}
                    display='flex'
                    justifyContent='end'
                    marginTop={4}
                  >
                    <Button
                      variant='contained'
                      color='primary'
                      disabled={handleDisableDefenseButton()}
                      onClick={() => handleConfirmDialog(true)}
                    >
                      Defender
                    </Button>
                  </Grid>
                )}
                <Box marginTop={4}>
                  <Typography
                    marginBottom={4}
                    color='primary'
                    style={{
                      fontWeight: 600,
                      fontSize: '22px',
                      lineHeight: '14px',
                      letterSpacing: '-1px'
                    }}
                  >
                    Anexos
                  </Typography>
                  <Box>
                    
                    <ul>
                      {attachments.map((file, index) => (
                        <li key={index}>
                          <a
                            href={getAttachmentUrl(file)}
                            target='_blank'
                            rel='noopener noreferrer'
                            style={{ color: '#007bff', textDecoration: 'underline', cursor: 'pointer' }}
                          >
                            {file.substring(file.lastIndexOf('/') + 1)}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </Box>

                  <Box marginBottom={4}>
                    <Typography
                      variant='body1'
                      style={{
                        fontStyle: 'normal',
                        fontWeight: 500,
                        fontSize: '14px',
                        lineHeight: '20px',
                        letterSpacing: '-1px',
                        textTransform: 'uppercase',
                        color: '#AFBCD2'
                      }}
                    >
                      Anexos (anexar pdf):
                    </Typography>

                    <Box
                      sx={{
                        position: 'relative',
                        overflow: 'hidden',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        width: '196px',
                        height: '40px',
                        background: '#F5F7FB',
                        boxShadow: '0px 1px 1px rgba(205, 205, 205, 0.25)',
                        borderRadius: '7px',
                        textAlign: 'right'
                      }}
                    >
                      <Input
                        id='upload-file'
                        type='file'
                        // multiple
                        onChange={handleUploadFile}
                        sx={{
                          // width: '100%',
                          // height: '100%',
                          // zIndex: 1
                          opacity: 0,
                          width: '100%',
                          height: '100%',
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          cursor: 'pointer'
                        }}
                        inputProps={{
                          multiple: true,
                          'aria-label': 'upload file',
                          style: {
                            width: '100%',
                            height: '100%',
                            opacity: 0
                          }
                        }}
                      />

                      <AttachFile
                        color='primary'
                        sx={{ position: 'absolute', zIndex: 0 }}
                      />

                      {/* </Box> */}
                    </Box>

                    {inputFiles?.length > 0 && (
                      <Box>
                        {inputFiles?.map((file, index) => (
                          <Box key={index} display='flex' alignItems='center'>
                            <Typography
                              style={{ color: '#9FA9C4', marginLeft: '10px' }}
                            >
                              {file.name}
                            </Typography>
                            <IconButton
                              aria-label={`Remover arquivo ${index + 1}`}
                              onClick={() => handleRemoveFile(index)}
                              sx={{ marginLeft: '10px' }}
                            >
                              <Delete fontSize='small' />
                            </IconButton>
                          </Box>
                        ))}
                      </Box>
                    )}
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={() => handleSendToLambda()}
                      // disabled={editAuditoriaButton || editAuditoria}
                      style={{
                        boxShadow: '0px 1px 1px rgba(205, 205, 205, 0.25)',
                        marginTop: '2%'
                      }}
                    >
                      Anexar
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </DashboardLayout>
  )
}

export default DynamicGuide
