// @mui components
import { Button } from "@mui/material";

function DownloadAnnexButton({ handleDownloadAnnexDefense }) {
  return (
    <Button
      color="download"
      variant="contained"
      onClick={handleDownloadAnnexDefense}
    >
      Baixar
    </Button>
  );
}

export default DownloadAnnexButton;
