// react hooks
import { useRef } from "react";

// axios instance
import axios from "axios";

// @mui components
import { Button } from "@mui/material";

// Application Users context
import { useUsersContext } from "../../../context/UserContext";

function InputFileButton({ handleProcedureFile, index }) {
  const inputRef = useRef(null);
  const cnpj = localStorage.getItem("cnpj");
  const { changeLoadingDash } = useUsersContext();

  const handleFileChange = async (event) => {
    changeLoadingDash(true);
    const fileObj = event?.target?.files[0];

    if (!fileObj) {
      return;
    }

    // Leitura do arquivo em Base64
    const reader = new FileReader();
    reader.onload = async (e) => {
      const imageBase64 = e.target.result;

      // Crie um objeto FormData com os dados da imagem em Base64
      const formData = new FormData();
      formData.append("cnpj", cnpj);
      formData.append("data", "08/2023");
      formData.append("image", imageBase64); // Use "image" como chave para a imagem

      try {
        // Faça a chamada para a API Gateway que aciona a função Lambda
        const response = await axios.post(
          "https://q7amrtd8t8.execute-api.us-east-1.amazonaws.com/dev/autorizador/create_attached", // Substitua pela URL do seu API Gateway
          formData,
          {
            headers: {
              "Content-Type": "application/json", // Altere para 'application/json'
            },
          }
        );

        changeLoadingDash(false);
        handleProcedureFile(fileObj, index);
      } catch (error) {
        console.error("Erro ao fazer upload:", error);
      }
    };

    // Leitura do arquivo em Base64
    reader.readAsDataURL(fileObj);

    // Limpe o input file
    event.target.value = null;
  };

  return (
    <>
      <input
        id="contained-button-file"
        style={{ display: "none" }}
        ref={inputRef}
        type="file"
        accept="image/*" // Aceita apenas arquivos de imagem
        onChange={handleFileChange}
      />
      <label htmlFor="contained-button-file">
        <Button color="primary" variant="contained" component="span">
          Anexo
        </Button>
      </label>
    </>
  );
}

export default InputFileButton;
