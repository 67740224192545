// react hooks
import { useState, useEffect, useMemo } from "react";

// react-router components
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";

import axios from "axios";

// @mui components
import { ThemeProvider } from "@mui/material/styles";

// Material Dashboard 2 React themes
import theme from "../theme/theme-light";
import themeRTL from "../theme/theme-light/theme-rtl";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "../theme/theme-dark";
import themeDarkRTL from "../theme/theme-dark/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";

// Application context
import { useUsersContext } from "../context/UserContext";

// @mui contexts
import { useMaterialUIController, setMiniSidenav } from "../context/MUIContext";

// routes
import routes from "./routes";
import routesBackOffice from "./routesNavbar";
import ProtectedRoutes from "./ProtectedRoutes";

// Application pages
import {
  AccreditedReceipts,
  DynamicGuide,
  GenerateGuide,
  GlosedGuides,
  HistoryGuides,
  LaboratoryGuides,
  Login,
  OnBoarding,
  PendingGuides,
  Profile,
  RecoverPassword,
  Register,
} from "../pages";

// Generic components
import { Configurator, Sidenav } from "../components";

// Images
import LifepetLogo from "../assets/images/lifepet-pro.png";
import AccreditedIndicationReceipts from "../pages/AccreditedIndicationReceipts/AccreditedIndicationReceipts";

export default function ApplicationRoutes() {
  const [controller, dispatch] = useMaterialUIController();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);

  const { miniSidenav, direction, layout, sidenavColor, darkMode } = controller;

  const {
    getCnpj,
    getEmail,
    handleCompletedOnboarding,
    idTesteNoPay,
    getIdNoPay,
    getNameCred,
    completedOnboarding,
    getAffiliate,
    isAffiliate
  } = useUsersContext();

  const email = localStorage.getItem("email");

  async function getUsersPro(email) {
    const api = `https://q7amrtd8t8.execute-api.us-east-1.amazonaws.com/dev/autorizador/get_info_pro?params=${email}`;

    await axios
      .get(api, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((response) => {
        getIdNoPay(response?.data[0]?.liberado?.S);
        getNameCred(response?.data[0]?.nomeEmpresa?.S);
        getAffiliate(response?.data[0]?.is_affiliate?.S)
        localStorage.setItem('isAffiliate', response?.data[0]?.is_affiliate?.S)
        localStorage.setItem("nameCred", response.data[0]?.nomeEmpresa?.S);
      })
      .catch((error) => {
        console.error("🚀 ~ getUsersPro ~ error:", error);
      });
  }

  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    if (email) getUsersPro(email);
  }, [email]);

  // useEffect(() => {
  //   if (
  //     localStorage.getItem("onBoarding") === "false" && localStorage.getItem("authorized") === "true"
  //   ) {
  //     navigate("/pro/onboarding", { replace: true });
  //   }
  // }, [completedOnboarding]);

  useEffect(() => {
    const localCnpj = localStorage.getItem("cnpj");
    const localEmail = localStorage.getItem("email");
    const localOnboarding = localStorage.getItem("onBoarding");

    if (localCnpj) getCnpj(localCnpj);
    if (localEmail) getEmail(localEmail);
    if (localOnboarding) handleCompletedOnboarding(localOnboarding);
  }, []);

  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
        {layout === "dashboard" && (
          <>
            {idTesteNoPay === "false" ? null : (
              <Sidenav
                color={sidenavColor}
                brand={LifepetLogo}
                brandName="Material Dashboard 2"
                routes={routes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
            )}
          </>
        )}

        {layout === "vr" && <Configurator />}

        <Routes>
          <Route path="/" element={<ProtectedRoutes />}>
            <Route path="/" element={<Login />}>
              <Route path="/" element={<Navigate replace to="dashboard" />} />
            </Route>
          </Route>
        </Routes>
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      {layout === "dashboard" && (
        <>
          {idTesteNoPay === "false" || idTesteNoPay === undefined ? null : (
            <Sidenav
              color={sidenavColor}
              brand={LifepetLogo}
              routes={routesBackOffice}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
          )}
        </>
      )}

      {layout === "vr" && <Configurator />}

      <Routes>
        <Route element={<ProtectedRoutes />}>
          <Route element={<OnBoarding />} path="/pro/onboarding" exact />
          <Route element={<GenerateGuide />} path="/pro/guias" exact />
          <Route element={<PendingGuides />} path="/pro/pendentes" exact />
          <Route
            element={<LaboratoryGuides />}
            path="/pro/laboratoriais"
            exact
          />
          <Route element={<GlosedGuides />} path="/pro/glosadas" exact />
          <Route element={<HistoryGuides />} path="/pro/historico" exact />
          <Route element={<DynamicGuide />} path="/pro/guia/:id" exact />
          <Route element={<Profile />} path="/pro/perfil" exact />
          {/* Recebimentos */}
          <Route
            element={<AccreditedReceipts />}
            path="/pro/recebimentos"
            exact
          />
          <Route
            element={<AccreditedIndicationReceipts />}
            path="/pro/recebimentos/indicacoes"
            exact
          />
        </Route>
        <Route element={<Login />} path="/" />
        <Route element={<Register />} path="/cadastrar" exact />
        <Route element={<RecoverPassword />} path="/recuperar" exact />
      </Routes>
    </ThemeProvider>
  );
}
