import {
  useCallback,
  useMemo,
  createContext,
  useState,
  useContext,
  useEffect,
} from "react";

export const UsersContext = createContext();

export function ListUsersProvider({ children }) {
  const [active, setActive] = useState("usuarios");
  const [activeSchedule, setActiveSchedule] = useState("inicial");
  const [activeLaudo, setActiveLaudo] = useState("todas");
  const [isLoja, setIsLoja] = useState("loja");
  const [row, setRow] = useState();
  const [rowAvaliacao, setRowAvaliacao] = useState();
  const [users, setUsers] = useState([]);
  const [address, setAddress] = useState([]);
  const [payment, setPayment] = useState([]);
  const [timeline, setTimeline] = useState([]);
  const [allTimeline, setAllTimeline] = useState([]); //todas as avaliações
  const [jornada, setJornada] = useState([]); //pegar dados da tabela de jornada
  const [rowStatus, setRowStatus] = useState([]); //salvar linha selecionada
  const [openModal, setOpenModal] = useState(false); //modal de infos gerais
  const [openModalUpload, setOpenModalUpload] = useState(false); //modal de infos gerais
  const [openModalPolitica, setOpenModalPolitica] = useState(false); //modal de política de privacidade
  const [openModalPayment, setOpenModalPayment] = useState("false"); //modal de política de privacidade
  const [vendas, setVendas] = useState([]); //pegar dados da tabela de vendas
  const [titleQuestions, setTitleQuestions] = useState([]); //perguntas
  const [answers, setAnswers] = useState([]); //respostas
  /* const [isAuthenticated, setIsAuthenticated] = useState(false); */
  const [loggedUser, setLoggedUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingDash, setLoadingDash] = useState(false);
  const [agendamentos, setAgendamentos] = useState([]); //pegar dados da tabela de agendamento
  const [formTeste, setFormTeste] = useState("");
  const [schedules, setSchedules] = useState([]);
  const [activeData, setActiveData] = useState("selectdata");
  const [userData, setUserData] = useState([]); //salva dados inseridos no agendamento
  const [idTesteNoPay, setIdTesteNoPay] = useState(null);
  const [tokenDevice, setTokenDevice] = useState(null);
  const [email, setEmail] = useState("");
  const [verifyB2B, setVerifyB2B] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isEspecialista, setIsEspecialista] = useState(false);
  const [rowFatura, setRowFatura] = useState();
  const [cnpj, setCnpj] = useState("");
  const [nameCred, setNameCred] = useState("");
  const [loadingGuias, setLoadingGuias] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [errorModalState, setErrorModalState] = useState(false); //controla modal de erro
  const [errorData, setErrorData] = useState({}); //salva dados do erro
  const [completedOnboarding, setCompletedOnboarding] = useState(null);
  const [isAffiliate, setIsAffiliate] = useState("")

  useEffect(() => {
    const recoveredUser = localStorage.getItem("user");

    if (recoveredUser) {
      setLoggedUser(JSON.parse(recoveredUser));
    }

    setLoading(false);
  }, []);

  function changeActive(params) {
    setActive(params);
  }

  function getFatura(params) {
    setRowFatura(params);
  }

  function changeActiveSchedule(params) {
    setActiveSchedule(params);
  }

  function getRow(params) {
    setRow(params);
  }

  function getCnpj(params) {
    setCnpj(params);
  }

  function getRowAvaliacao(params) {
    setRowAvaliacao(params);
  }

  function getType(params) {
    setIsLoja(params);
  }

  function getUsers(params) {
    setUsers(params);
  }

  function getAddress(params) {
    setAddress(params);
  }

  function getPayment(params) {
    setPayment(params);
  }

  function getTimeline(params) {
    setTimeline(params);
  }

  function getAllTimeline(params) {
    setAllTimeline(params);
  }

  function getJornada(params) {
    setJornada(params);
  }

  function getRowStatus(params) {
    setRowStatus(params);
  }

  function getNameCred(params) {
    setNameCred(params);
  }

  function handleModal(params) {
    setOpenModal(params);
  }

  function handleModalUpload(params) {
    setOpenModalUpload(params);
  }

  function handleModalPolitica(params) {
    setOpenModalPolitica(params);
  }

  function handleModalPayment(params) {
    setOpenModalPayment(params);
  }

  function getVendas(params) {
    setVendas(params);
  }

  function getAgendamentos(params) {
    setAgendamentos(params);
  }

  function getQuestions(params) {
    setTitleQuestions(params);
  }

  function getAnswers(params) {
    setAnswers(params);
  }

  function getSchedules(params) {
    setSchedules(params);
  }

  /* function getAuthenticated(params) {
    setIsAuthenticated(params)
  } */

  function getLoggedUser(params) {
    setLoggedUser(params);
  }

  function getTesteName(params) {
    setFormTeste(params);
  }

  function getActiveData(params) {
    setActiveData(params);
  }

  function getUserData(params) {
    setUserData(params);
  }

  function getIdNoPay(params) {
    setIdTesteNoPay(params);
  }

  function getActiveLaudo(params) {
    setActiveLaudo(params);
  }

  function getTokenDevice(params) {
    setTokenDevice(params);
  }

  function getEmail(params) {
    setEmail(params);
  }

  function getVerifyB2B(params) {
    setVerifyB2B(params);
  }

  function changeRefresh(params) {
    setRefresh(params);
  }

  function changeLoadingPage(params) {
    setLoadingPage(params);
  }

  function changeLoadingDash(params) {
    setLoadingDash(params);
  }

  function getEspecialista(params) {
    setIsEspecialista(params);
  }

  function getLoadingGuias(params) {
    setLoadingGuias(params);
  }

  function getAffiliate(params) {
    setIsAffiliate(params);
  }

  const handleNotifications = useCallback((notifications) => {
    setNotifications(notifications);
  }, []);

  const handleErrorModalState = useCallback((errorModalState) => {
    setErrorModalState(errorModalState);
  }, []);

  const handleErrorData = useCallback((errorData) => {
    setErrorData(errorData);
  }, []);

  const handleCompletedOnboarding = useCallback((params) => {
    setCompletedOnboarding(params);
  }, []);

  const memoizedContext = useMemo(
    () => ({
      isAuthenticated: !!loggedUser,
      active,
      changeActive,
      activeSchedule,
      changeActiveSchedule,
      row,
      rowAvaliacao,
      getRow,
      getRowAvaliacao,
      isLoja,
      getType,
      users,
      getUsers,
      address,
      getAddress,
      payment,
      getPayment,
      timeline,
      getTimeline,
      allTimeline,
      getAllTimeline,
      jornada,
      getJornada,
      rowStatus,
      getRowStatus,
      openModal,
      handleModal,
      vendas,
      getVendas,
      titleQuestions,
      getQuestions,
      answers,
      getAnswers,
      openModalUpload,
      handleModalUpload,
      /* isAuthenticated,
      getAuthenticated, */
      loggedUser,
      getLoggedUser,
      loading,
      agendamentos,
      getAgendamentos,
      formTeste,
      getTesteName,
      schedules,
      getSchedules,
      activeData,
      getActiveData,
      openModalPolitica,
      handleModalPolitica,
      userData,
      getUserData,
      idTesteNoPay,
      getIdNoPay,
      activeLaudo,
      getActiveLaudo,
      tokenDevice,
      getTokenDevice,
      email,
      getEmail,
      verifyB2B,
      getVerifyB2B,
      openModalPayment,
      handleModalPayment,
      refresh,
      changeRefresh,
      isEspecialista,
      getEspecialista,
      rowFatura,
      getFatura,
      cnpj,
      getCnpj,
      loadingPage,
      changeLoadingPage,
      loadingDash,
      changeLoadingDash,
      loadingGuias,
      getLoadingGuias,
      nameCred,
      getNameCred,
      notifications,
      handleNotifications,
      errorModalState,
      handleErrorModalState,
      errorData,
      handleErrorData,
      completedOnboarding,
      handleCompletedOnboarding,
      isAffiliate,
      getAffiliate
    }),
    [
      loggedUser,
      active,
      activeSchedule,
      row,
      rowAvaliacao,
      isLoja,
      users,
      address,
      payment,
      timeline,
      allTimeline,
      jornada,
      rowStatus,
      openModal,
      vendas,
      titleQuestions,
      answers,
      openModalUpload,
      loading,
      agendamentos,
      formTeste,
      schedules,
      activeData,
      openModalPolitica,
      userData,
      idTesteNoPay,
      activeLaudo,
      tokenDevice,
      email,
      verifyB2B,
      openModalPayment,
      refresh,
      isEspecialista,
      rowFatura,
      cnpj,
      loadingPage,
      loadingDash,
      loadingGuias,
      nameCred,
      notifications,
      handleNotifications,
      errorModalState,
      handleErrorModalState,
      errorData,
      handleErrorData,
      completedOnboarding,
      handleCompletedOnboarding,
      isAffiliate,
      getAffiliate
    ]
  );

  return (
    <UsersContext.Provider value={memoizedContext}>
      {children}
    </UsersContext.Provider>
  );
}

export const useUsersContext = () => {
  return useContext(UsersContext);
};
