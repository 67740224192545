import { useState, useEffect } from "react";

// @mui components
import { DatePicker } from "@mui/x-date-pickers";
import {
  Box,
  InputAdornment,
  TextField,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import { Search } from "@mui/icons-material";

// Application Users context
import { useUsersContext } from "../../context/UserContext";

// Generic Components
import { DashboardLayout, DataTable, Footer, Loading } from "../../components";

// Specific components
import GlosedTableData from "./components/GlosedTableData";

// utils
import { getGuides } from "../../utils/axios";

function GlosedGuides() {
  const {
    active,
    loadingGuias,
    getLoadingGuias,
    handleErrorData,
    handleErrorModalState,
  } = useUsersContext();

  const [pendingGuides, setPendingGuides] = useState([]);
  const [search, setSearch] = useState("");
  const [statusSearch, setStatusSearch] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);

  const cnpj = localStorage.getItem("cnpj");

  const { columns, rows } = GlosedTableData({
    data: pendingGuides,
    search: search,
    statusSearch: statusSearch,
    selectedDate: selectedDate?.$d,
  });

  async function handleGetGuides() {
    getLoadingGuias(true);

    const { data, status } = await getGuides(cnpj, 'glosadas');

    if (status !== 200) {
      handleErrorData({
        title: "Erro ao carregar os dados",
        message: data?.message,
      });

      handleErrorModalState(true);
      getLoadingGuias(false);
      return;
    }

    setPendingGuides(data);
    getLoadingGuias(false);
  }

  useEffect(() => {
    handleGetGuides();
  }, [active]);

  return (
    <DashboardLayout>
      <Box>
        <Box paddingBottom={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Box
                display="flex"
                flexDirection="column"
                sx={{ backgroundColor: "#FFFFFF" }}
                marginX={2}
                paddingX={6}
                paddingY={6}
                borderRadius="20px"
                gap={6}
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="title" color="primary">
                    Guias glosadas
                  </Typography>
                </Box>

                <Grid container columnSpacing={8}>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-search"
                      label="Pesquise pela guia"
                      type="search"
                      value={search}
                      onChange={(event) => {
                        setSearch(event.target.value);
                      }}
                      sx={{ width: "100%" }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Search color="label" fontSize="medium" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <DatePicker
                      sx={{ width: "100%" }}
                      value={selectedDate}
                      onChange={(date) => setSelectedDate(date)}
                      label="Filtrar por data"
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <Button
                      fullWidth
                      variant="contained"
                      onClick={() => {
                        setSearch("");
                        setSelectedDate(null);
                        setStatusSearch("");
                      }}
                    >
                      Limpar
                    </Button>
                  </Grid>
                </Grid>

                <Box>
                  {loadingGuias ? (
                    <Loading />
                  ) : (
                    <DataTable
                      table={{ columns, rows }}
                      isSorted={true}
                      entriesPerPage={false}
                      showTotalEntries={true}
                      noEndBorder
                      isNone
                    />
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Footer />
    </DashboardLayout>
  );
}

export default GlosedGuides;
