import { useRef, useState } from 'react'
import { Button, Box, Input } from '@mui/material'
import { useUsersContext } from '../../../context/UserContext'
import AttachFile from '@mui/icons-material/AttachFile'
import axios from 'axios'

function InputFileButton ({ status, data }) {
  const isDisabled = status !== 'Pendente'
  const formattedData = formatData(data);
  const inputRef = useRef(null)
  const [inputFiles, setInputFiles] = useState([])
  const { changeLoadingDash } = useUsersContext()
  const cnpj = localStorage.getItem('cnpj')

  const onButtonClick = () => {
    inputRef.current.click()
  }

  function formatData (data) {
    return data.replace(/\//g, '-');
  };

  const uploadToS3 = async (presignedUrl, file) => {
    try {
      const response = await axios.put(presignedUrl, file, {
        headers: {
          'Content-Type': file.type,
          'x-amz-acl': 'public-read'
        }
      })
      console.log('Upload do arquivo para o S3 bem-sucedido:', response)
    } catch (error) {
      console.error(
        `Erro ao fazer upload do arquivo ${file.name} para o S3:`,
        error
      )
      throw error
    }
  }

  const handleSendToLambda = async file => {
    if (!file) {
      console.error('Nenhum arquivo fornecido.')
      return
    }

    changeLoadingDash(true)

    try {
      const formData = new FormData()
      const formattedData = formatData(data);
      formData.append('file_name', formattedData)
      formData.append('guia', cnpj)

      const response = await axios.post(
        'https://q7amrtd8t8.execute-api.us-east-1.amazonaws.com/dev/autorizador/generate_attached_url',
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        }
      )

      const presignedUrl = response.data.presigned_url

      await uploadToS3(presignedUrl, file)
      changeLoadingDash(false)
    } catch (error) {
      changeLoadingDash(false)
      alert('Algo deu errado no envio do arquivo')
      console.error(`Erro ao enviar arquivo ${file.name}:`, error)
    }

    changeLoadingDash(false)
    console.log('O arquivo foi enviado com sucesso!')
  }
  
const handleUploadFile = (event) => {
  const file = event.target.files[0];
  if (file) {
    handleSendToLambda(file);
  } else {
    console.error('Nenhum arquivo selecionado');
  }
};

  const handleFileInput = () => {
    // 👇️ open file input box on click of another element
    inputRef.current.click()
  }

  const handleFileChange = event => {
    const fileObj = event.target.files && event.target.files[0]
    if (!fileObj) {
      return
    }

    // 👇️ reset file input
    event.target.value = null

  }

  return (
    <>
      {/* <input
        id="contained-button-file"
        style={{ display: "none" }}
        ref={inputRef}
        type="file"
        onChange={handleFileChange}
        disabled={isDisabled}
      />
      <label htmlFor="contained-button-file">
        <Button
          color="secondary"
          variant="contained"
          component="span"
          disabled={isDisabled}
          onClick={handleUploadFile}
        >
          Anexo
        </Button>
      </label> */}
      <Box
        sx={{
          position: 'relative',
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          width: '196px',
          height: '40px',
          background: '#F5F7FB',
          boxShadow: '0px 1px 1px rgba(205, 205, 205, 0.25)',
          borderRadius: '7px',
          textAlign: 'right'
        }}
      >
        <Input
          id='upload-file'
          type='file'
          // multiple
          onChange={handleUploadFile}
          sx={{
            // width: '100%',
            // height: '100%',
            // zIndex: 1
            opacity: 0,
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            cursor: 'pointer'
          }}
          inputProps={{
            multiple: true,
            'aria-label': 'upload file',
            style: {
              width: '100%',
              height: '100%',
              opacity: 0
            }
          }}
        />

        <AttachFile color='primary' sx={{ position: 'absolute', zIndex: 0 }} />
        {/* </Box> */}
      </Box>
    </>
  )
}

export default InputFileButton
