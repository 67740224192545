// react hooks
import { useState, useEffect, useCallback } from 'react'

// react input mask component
import InputMask from 'react-input-mask'

// react routes dom hooks and components
import { useNavigate } from 'react-router-dom'

// @mui components
import {
  Box,
  Button,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography
} from '@mui/material'
import { Close } from '@mui/icons-material'
import { ContentCopy } from '@mui/icons-material'

// Application context
import { useUsersContext } from '../../context/UserContext'

// Generic components
import { DashboardLayout, DataTable, Loading } from '../../components'

// Specific components
import IndicacoesTableData from './components/IndicacoesTableData'
import IndicacoesTableDataInativos from './components/IndicacoesTableDataInativos'
import VeterinariansTableData from './components/VeterinariansTableData'
import FeesTableData from './components/FeesTableData'
import RegisterBankAccountModal from './components/RegisterBankAccountModal'
import RegisterVeterinariansModal from './components/RegisterVeterinariansModal'

// Utils functions and components
import { masks } from '../../utils/maskService'
import {
  getUserProByParams,
  getVeterinarians,
  updateInfosPro,
  getProcedures,
  getIndicationsExtractTable,
  getIndicationsTable
} from '../../utils/axios'

export default function Profile () {
  const navigate = useNavigate()

  const {
    loadingGuias,
    cnpj,
    getLoadingGuias,
    changeLoadingDash,
    refresh,
    changeRefresh,
    handleErrorData,
    handleErrorModalState
  } = useUsersContext()

  const [profileData, setProfileData] = useState({
    cnpj: '',
    agent: '',
    logradouro: '',
    numero: '',
    bairro: '',
    municipio: '',
    cep: '',
    uf: '',
    email: '',
    cellphone: '',
    conta_bancaria: {},
    especialidades: [],
    indication_code: '',
    is_affiliate: ''
  })
  const [veterinariansData, setVeterinariansData] = useState([])
  const [indicationsData, setIndicationsData] = useState([])
  const [bankAccountModalState, setBankAccountModalState] = useState(false)
  const [veterinarianModalState, setVeterinarianModalState] = useState(false)

  const [procedures, setProcedures] = useState([])
  const [selectedGroup, setSelectedGroup] = useState('')
  const [selectedGroupHon, setSelectedGroupHon] = useState('')

  const [editState, setEditState] = useState(false)

  const accreditedCNPJ = localStorage.getItem('cnpj') || cnpj
  const isAffiliate = localStorage.getItem('isAffiliate')

  const { columns, rows } = VeterinariansTableData({
    veterinariansData,
    handleDescativeVeterinarian
  })

  const {
    columns: indicacoesColumns,
    rows: indicacoesRows
  } = IndicacoesTableData({
    indicationsData,
    handleDescativeVeterinarian
  })

  const {
    columns: indicacoesColumnsInativos,
    rows: indicacoesRowsInativos
  } = IndicacoesTableDataInativos({
    indicationsData,
    handleDescativeVeterinarian
  })

  const { columns: pColumns, rows: pRows } = FeesTableData({
    procedures,
    selectedGroupHon
  })

  const uniqueProceduresKeys = new Set(
    procedures?.map(procedure => {
      if (
        procedure?.grupo === "Especialistas" &&
        !(procedure?.nome.includes("Retorno") || procedure?.nome.includes("Reconsulta"))
      ) {
        return procedure?.nome;
      } else {
        return procedure?.grupo;
      }
    }).filter(Boolean) 
  );
  
  // console.log('uniqueProceduresKeys', uniqueProceduresKeys);

  const uniqueProceduresValues = Array.from(uniqueProceduresKeys).sort()

  const handleProfileData = useCallback(
    (key, value) => {
      setProfileData(prevState => ({
        ...prevState,
        [key]: value
      }))
    },
    [setProfileData]
  )

  const handleBankAccountModal = state => {
    setBankAccountModalState(state)
  }

  const handleVeterinarianModal = state => {
    setVeterinarianModalState(state)
  }

  const handleDeleteGroup = specialty => {
    setProfileData(prevState => ({
      ...prevState,
      especialidades: prevState.especialidades.filter(
        item => item !== specialty
      )
    }))
  }

  const handleChangeGroupSelect = value => {
    setSelectedGroup(value)
  }

  const handleChangeHonSelect = value => {
    setSelectedGroupHon(value)
  }

  const handleAddGroup = () => {
    const newSpecialityGroup = [
      ...profileData.especialidades,
      selectedGroup
    ].sort((a, b) => a.localeCompare(b))

    setProfileData(prevState => ({
      ...prevState,
      especialidades: newSpecialityGroup
    }))

    handleChangeGroupSelect('')
  }

  async function handleGetProfile () {
    const { data, status } = await getUserProByParams('id', accreditedCNPJ)

    if (status !== 200) {
      handleErrorData({
        title: 'Erro ao carregar os dados',
        message: data?.message
      })

      handleErrorModalState(true)
      changeLoadingDash(false)
      return
    }

    setProfileData({
      cnpj: data?.cnpj,
      agent: data?.representanteEmpresa,
      logradouro: data?.endereco?.logradouro,
      numero: data?.endereco?.numero,
      bairro: data?.endereco?.bairro,
      municipio: data?.endereco?.municipio,
      cep: data?.endereco?.cep,
      uf: data?.endereco?.uf,
      email: data?.email,
      cellphone: data?.telefone,
      conta_bancaria: data?.conta_bancaria,
      especialidades: data?.especialidades,
      indication_code: data?.indication_code,
      is_affiliate: data?.is_affiliate
    })

    changeLoadingDash(false)
  }

  async function handleGetVeterinarians () {
    const { data, status } = await getVeterinarians(accreditedCNPJ)

    if (status === 201) {
      setVeterinariansData([])
      return
    }

    if (status !== 200) {
      handleErrorData({
        title: 'Erro ao carregar os dados',
        message: data?.message
      })

      handleErrorModalState(true)
      changeLoadingDash(false)
      return
    }

    setVeterinariansData(data)
    getLoadingGuias(false)
  }

  async function handleGetIndications () {
    const { data, status } = await getIndicationsTable(accreditedCNPJ)

    if (status === 201) {
      setIndicationsData([])
      return
    }

    if (status !== 200) {
      handleErrorData({
        title: 'Erro ao carregar os dados',
        message: data?.message
      })

      handleErrorModalState(true)
      changeLoadingDash(false)
      return
    }

    setIndicationsData(data)
    getLoadingGuias(false)
  }

  async function handleAcceptProcedures (item) {
    changeLoadingDash(true)

    const { data, status } = await updateInfosPro(
      item,
      'especialidades',
      accreditedCNPJ
    )

    if (status !== 200) {
      handleErrorData({
        title: 'Erro ao carregar os dados',
        message: data?.message
      })

      handleErrorModalState(true)
      changeLoadingDash(false)
      return
    }

    handleErrorData({
      title: 'Atualização concluída!',
      message: 'Planos aceitos atualizados com sucesso.',
      type: 'success'
    })
    handleErrorModalState(true)

    changeLoadingDash(false)
  }

  async function handleDescativeVeterinarian (vetCPF, vetActive) {
    changeLoadingDash(true)

    const body = { vet_ativo: !vetActive }

    const { data, status } = await updateInfosPro(body, 'vet_ativo', vetCPF)

    if (status !== 200) {
      handleErrorData({
        title: 'Erro ao carregar os dados',
        message: data?.message
      })

      handleErrorModalState(true)
      changeLoadingDash(false)
      return
    }

    const buttonMessage = data.active ? 'ativado' : 'desativado'

    veterinariansData.find(
      data => data.id.S === vetCPF
    ).vet_ativo.BOOL = !vetActive

    handleErrorData({
      title: 'Atualização concluída!',
      message: `Veterinário ${buttonMessage} com sucesso.`,
      type: 'success'
    })

    handleErrorModalState(true)

    changeLoadingDash(false)
  }

  async function handleGetProcedures () {
    const { data, status } = await getProcedures()

    if (status !== 200) {
      alert(data?.message)
      getLoadingGuias(false)
      return
    }

    setProcedures(data)
  }

  async function handleUpdateProfileInfo () {
    changeLoadingDash(true)

    const item = {
      agent: profileData?.agent.toUpperCase(),
      telefone: profileData.cellphone,
      logradouro: profileData?.logradouro.toUpperCase(),
      numero: profileData?.numero,
      bairro: profileData?.bairro.toUpperCase(),
      municipio: profileData?.municipio.toUpperCase(),
      cep: profileData?.cep,
      uf: profileData?.uf.toUpperCase()
    }

    const { data, status } = await updateInfosPro(
      item,
      'perfil_clinica_pro',
      cnpj
    )

    if (status !== 200) {
      handleErrorData({
        title: 'Erro ao carregar os dados',
        message: data?.message
      })

      handleErrorModalState(true)
      changeLoadingDash(false)
      return
    }

    handleErrorData({
      title: 'Atualização concluída!',
      message: 'Planos aceitos atualizados com sucesso.',
      type: 'success'
    })
    handleErrorModalState(true)

    setEditState(false)
    changeRefresh(!refresh)
    changeLoadingDash(false)
  }

  useEffect(() => {
    changeLoadingDash(true)
    getLoadingGuias(true)

    try {
      handleGetProfile().then(async () => {
        handleGetVeterinarians()
          .then(() => handleGetProcedures())
          .then(() => handleGetIndications())
          .then(() => {
            changeLoadingDash(false)
            getLoadingGuias(false)
          })
      })
    } catch (error) {
      changeLoadingDash(false)
      getLoadingGuias(false)
    }
  }, [refresh])

  const profileTextFields = [
    {
      value: profileData.cnpj,
      onChangeKey: 'cnpj',
      mask: masks.cnpj(profileData.cnpj),
      disabled: true,
      label: 'CNPJ',
      xs: 3
    },
    {
      value: profileData.agent,
      onChangeKey: 'agent',
      label: 'REPRESENTANTE',
      xs: 3
    },
    {
      value: profileData.email,
      onChangeKey: 'email',
      label: 'EMAIL',
      disabled: true,
      xs: 3
    },
    {
      value: profileData.cellphone,
      onChangeKey: 'cellphone',
      mask: masks.cellphone(profileData.cellphone),
      label: 'TELEFONE',
      xs: 3
    },
    {
      value: profileData.logradouro,
      onChangeKey: 'logradouro',
      label: 'LOGRADOURO',
      xs: 3
    },
    {
      value: profileData.numero,
      onChangeKey: 'numero',
      label: 'NÚMERO',
      xs: 3
    },
    {
      value: profileData.bairro,
      onChangeKey: 'bairro',
      label: 'BAIRRO',
      xs: 3
    },
    {
      value: profileData.municipio,
      onChangeKey: 'municipio',
      label: 'MUNICÍPIO',
      xs: 3
    },
    { value: profileData.uf, onChangeKey: 'uf', label: 'UF', xs: 3 },

    {
      value: profileData.cep,
      onChangeKey: 'cep',
      mask: profileData?.cep && masks.zipCode(profileData.cep),
      label: 'CEP',
      xs: 3
    }
  ]

  const bankTextFields = [
    {
      value: profileData.conta_bancaria?.recebedor || '',
      label: 'RECEBEDOR',
      xs: 3
    },
    {
      value: profileData.conta_bancaria?.conta || '',
      mask:
        profileData.conta_bancaria?.conta !== undefined
          ? masks.bankAccount(profileData.conta_bancaria?.conta)
          : null,
      label: 'CONTA',
      xs: 2
    },
    {
      value: profileData.conta_bancaria?.agencia || '',
      label: 'AGÊNCIA',
      xs: 2
    },
    {
      value: profileData.conta_bancaria?.banco || '',
      label: 'BANCO',
      xs: 3
    },
    {
      value: profileData.conta_bancaria?.tipo_conta || '',
      label: 'TIPO DE CONTA',
      xs: 2
    }
  ]

  function MenuHorizontal2 () {
    const [value, setValue] = useState(0)

    const handleChange = (event, newValue) => {
      setValue(newValue)
    }

    return (
      <Box sx={{ width: 'auto' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          centered
          TabIndicatorProps={{
            style: {
              background: '#67dcb6',
              boxShadow: '0px 1px 1px rgba(205, 205, 205, 0.25)',
              borderRadius: '12px'
            }
          }}
        >
          <Tab label='Resumo' />
          <Tab label='Ativos' />
          <Tab label='Inativos' />
        </Tabs>

        <Box sx={{ marginTop: '16px' }}>
          {/* {/* {value === 0 ? (
            <Box mb={1.5}>
              <ComplexStatisticsCard
                title={
                  subsRows?.filter(
                    (row) => row?.status?.props?.value === "ativo"
                  ).length > 1
                    ? "Assinaturas ativas"
                    : "Assinatura ativa"
                }
                count={
                  subsRows?.filter(
                    (row) => row?.status?.props?.value === "ativo"
                  ).length
                }
              />
            </Box>
          ) : null} */}

          {value === 1 ? (
            <DataTable
              table={{ columns: indicacoesColumns, rows: indicacoesRows }}
              isSorted={true}
              entriesPerPage={false}
              showTotalEntries={false}
              noEndBorder
              isNone
            />
          ) : null}

          {value === 2 ? (
            <DataTable
              table={{
                columns: indicacoesColumnsInativos,
                rows: indicacoesRowsInativos
              }}
              isSorted={true}
              entriesPerPage={false}
              showTotalEntries={false}
              noEndBorder
              isNone
            />
          ) : null}
        </Box>
      </Box>
    )
  }

  return (
    <DashboardLayout>
      <RegisterBankAccountModal
        bankAccountModalState={bankAccountModalState}
        handleBankAccountModal={handleBankAccountModal}
      />

      <RegisterVeterinariansModal
        veterinarianModalState={veterinarianModalState}
        handleVeterinarianModal={handleVeterinarianModal}
      />

      <Box>
        <Box paddingBottom={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Box
                display='flex'
                flexDirection='column'
                marginBottom={4}
                sx={{ backgroundColor: '#FFFFFF' }}
                marginX={2}
                paddingX={6}
                paddingY={6}
                borderRadius='20px'
                gap={2}
              >
                <Box display='flex' justifyContent='end' gap={2}>
                  <Button
                    variant='outlined'
                    onClick={() => handleVeterinarianModal(true)}
                  >
                    Incluir veterinário
                  </Button>
                  <Button
                    variant='outlined'
                    onClick={() => handleBankAccountModal(true)}
                  >
                    Incluir conta bancária
                  </Button>
                </Box>

                <Box display='flex' flexDirection='column'>
                  <Typography variant='title2' color='label.main'>
                    PERFIL
                  </Typography>
                  <Typography variant='title' color='primary'>
                    Dados da Clínica
                  </Typography>
                </Box>

                <Grid container spacing={3} marginTop={1}>
                  {profileTextFields.map(textField => {
                    return (
                      <Grid item xs={textField.xs} key={textField.label}>
                        {editState ? (
                          <TextField
                            fullWidth
                            disabled={textField.disabled}
                            label={textField.label}
                            value={textField.value}
                            onChange={event =>
                              handleProfileData(
                                textField.onChangeKey,
                                event.target.value
                              )
                            }
                          >
                            Sim
                          </TextField>
                        ) : (
                          <InputMask
                            mask={textField.mask}
                            value={textField.value}
                            disabled={true}
                          >
                            {() => (
                              <TextField
                                value={textField.value}
                                label={textField.label}
                                InputProps={{ disabled: true }}
                                fullWidth
                              />
                            )}
                          </InputMask>
                        )}
                      </Grid>
                    )
                  })}
                </Grid>

                <Grid>
                  <Box display='flex' justifyContent='end' marginTop={2}>
                    <Button
                      variant='outlined'
                      sx={{ marginRight: '10px' }}
                      onClick={() => setEditState(!editState)}
                    >
                      {editState ? 'Cancelar' : 'Editar'}
                    </Button>

                    {editState && (
                      <Button
                        variant='contained'
                        onClick={() => {
                          handleUpdateProfileInfo()
                        }}
                      >
                        Salvar
                      </Button>
                    )}
                  </Box>
                </Grid>
              </Box>

              {/* <Box
                display="flex"
                flexDirection="column"
                marginBottom={4}
                sx={{ backgroundColor: "#FFFFFF" }}
                marginX={2}
                paddingX={6}
                paddingY={6}
                borderRadius="20px"
                gap={4}
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Typography variant="title" color="primary">
                    Tabela de honorários
                  </Typography>
                </Box>

                <Grid
                  container
                  columnSpacing={3}
                  alignItems="end"
                  marginTop={1}
                >
                  <Grid item display="flex" flexDirection="column">
                    <Typography
                      variant="body1"
                      style={{
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "14px",
                        lineHeight: "20px",
                        letterSpacing: "-1px",
                        textTransform: "uppercase",
                        color: "#AFBCD2",
                      }}
                    >
                      grupo
                    </Typography>

                    <Select
                      value={selectedGroupHon}
                      onChange={(event) =>
                        handleChangeHonSelect(event.target.value)
                      }
                      disabled={uniqueProceduresValues.length === 0}
                      sx={{
                        width: "224px",
                        height: "40px",
                        background: "#F5F7FB",
                        boxShadow: "0px 1px 1px rgba(205, 205, 205, 0.25)",
                        borderRadius: "7px",
                      }}
                      InputLabelProps={{ style: { display: "none" } }}
                    >
                      {uniqueProceduresValues
                        ?.filter((unique) => unique !== "Consultas")
                        .map((uniqueValue) => {
                          return (
                            <MenuItem key={uniqueValue} value={uniqueValue}>
                              {uniqueValue}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </Grid>

                  {selectedGroupHon !== "" ? (
                    <DataTable
                      table={{ columns: pColumns, rows: pRows }}
                      isSorted={true}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                  ) : null}
                </Grid>
              </Box> */}

              <Box
                display='flex'
                flexDirection='column'
                marginBottom={4}
                sx={{ backgroundColor: '#FFFFFF' }}
                marginX={2}
                paddingX={6}
                paddingY={6}
                borderRadius='20px'
                gap={4}
              >
                <Box
                  display='flex'
                  flexDirection='row'
                  justifyContent='space-between'
                >
                  <Typography variant='title' color='primary'>
                    Especialidades
                  </Typography>

                  <Grid display='flex' justifyContent='end'>
                    <Button
                      variant='contained'
                      onClick={() =>
                        handleAcceptProcedures(profileData.especialidades)
                      }
                    >
                      SALVAR
                    </Button>
                  </Grid>
                </Box>

                <Grid
                  container
                  columnSpacing={3}
                  alignItems='end'
                  marginTop={1}
                >
                  <Grid item display='flex' flexDirection='column'>
                    <Typography
                      variant='body1'
                      style={{
                        fontStyle: 'normal',
                        fontWeight: 500,
                        fontSize: '14px',
                        lineHeight: '20px',
                        letterSpacing: '-1px',
                        textTransform: 'uppercase',
                        color: '#AFBCD2'
                      }}
                    >
                      Grupo de procedimento
                    </Typography>

                    <Select
                      value={selectedGroup}
                      onChange={event =>
                        handleChangeGroupSelect(event.target.value)
                      }
                      sx={{
                        width: '224px',
                        height: '40px',
                        background: '#F5F7FB',
                        boxShadow: '0px 1px 1px rgba(205, 205, 205, 0.25)',
                        borderRadius: '7px'
                      }}
                      InputLabelProps={{ style: { display: 'none' } }}
                    >
                      {uniqueProceduresValues?.map(uniqueValue => {
                        return (
                          <MenuItem
                            key={uniqueValue}
                            value={uniqueValue}
                            disabled={profileData?.especialidades?.includes(
                              uniqueValue
                            )}
                          >
                            {uniqueValue}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </Grid>

                  <Grid item display='flex' flexDirection='column'>
                    <Button
                      variant='outlined'
                      color='success'
                      disabled={!selectedGroup}
                      onClick={handleAddGroup}
                    >
                      incluir grupo
                    </Button>
                  </Grid>
                </Grid>

                <Grid container columnSpacing={3} rowSpacing={2} marginTop={1}>
                  {profileData?.especialidades?.map(especialidade => {
                    return (
                      <Grid item key={especialidade}>
                        <Box
                          display='flex'
                          justifyContent='space-between'
                          alignItems='center'
                          padding={1}
                          gap={1}
                          borderRadius='10px'
                          boxShadow={2}
                          sx={{
                            backgroundColor: '#32D1A0;'
                          }}
                        >
                          <Typography
                            color='#FFFFFF'
                            fontSize='16px'
                            fontWeight='bold'
                          >
                            {especialidade}
                          </Typography>
                          <IconButton
                            size='small'
                            onClick={() => handleDeleteGroup(especialidade)}
                          >
                            <Close sx={{ color: '#FFFFFF' }} />
                          </IconButton>
                        </Box>
                      </Grid>
                    )
                  })}
                </Grid>
              </Box>

              <Box
                display='flex'
                flexDirection='column'
                marginBottom={4}
                sx={{ backgroundColor: '#FFFFFF' }}
                marginX={2}
                paddingX={6}
                paddingY={6}
                borderRadius='20px'
                gap={2}
              >
                <Box display='flex' flexDirection='column'>
                  <Typography variant='title' color='primary'>
                    Conta Bancária
                  </Typography>
                </Box>

                <Grid container spacing={3}>
                  {bankTextFields?.map(textField => {
                    return (
                      <Grid item xs={textField.xs} key={textField.label}>
                        <InputMask
                          mask={textField.mask}
                          value={textField.value}
                          disabled={true}
                        >
                          {() => (
                            <TextField
                              value={textField.value}
                              label={textField.label}
                              InputProps={{ disabled: true }}
                              fullWidth
                            />
                          )}
                        </InputMask>
                      </Grid>
                    )
                  })}
                </Grid>
              </Box>

              <Box
                display='flex'
                flexDirection='column'
                sx={{ backgroundColor: '#FFFFFF' }}
                marginX={2}
                paddingX={6}
                paddingY={6}
                borderRadius='20px'
                marginBottom={4}
              >
                <Box display='flex' flexDirection='column' gap={2}>
                  <Typography variant='title' color='primary'>
                    Veterinários cadastrados
                  </Typography>
                  <Box>
                    {loadingGuias ? (
                      <Loading />
                    ) : (
                      <DataTable
                        table={{ columns, rows }}
                        isSorted={true}
                        showTotalEntries={true}
                        entriesPerPage={false}
                      />
                    )}
                  </Box>
                </Box>
              </Box>

              {isAffiliate === 'true' ? (
                <Box
                  display='flex'
                  flexDirection='column'
                  sx={{ backgroundColor: '#FFFFFF' }}
                  marginX={2}
                  paddingX={6}
                  paddingY={6}
                  borderRadius='20px'
                  marginBottom={4}
                >
                  <Box display='flex' flexDirection='column' gap={2}>
                    <Box
                      display='flex'
                      flexDirection='row'
                      justifyContent='space-between'
                    >
                      <Box display='flex' flexDirection='row' gap={4}>
                        <Typography
                          variant='title'
                          color='primary'
                          marginBottom={3}
                        >
                          Minhas indicações
                        </Typography>
                        <Box
                          display='flex'
                          justifyContent='center'
                          alignItems='center'
                          flexDirection='row'
                          gap={1}
                          marginBottom={3}
                          paddingX={2}
                          paddingY={2}
                          style={{
                            width: '320px',
                            height: '40px',
                            background: '#F5F7FB',
                            boxShadow: '0px 1px 1px rgba(205, 205, 205, 0.25)',
                            borderRadius: '7px'
                          }}
                        >
                          <Typography variant='title2' color='label.main'>
                            Meu código de indicação:
                          </Typography>
                          <Typography
                            variant='title2'
                            color='label.main'
                            display='flex'
                            justifyContent='center'
                            alignItems='center'
                            style={{
                              fontWeight: 500,
                              fontSize: '15px'
                            }}
                          >
                            {profileData?.indication_code}
                          </Typography>
                          <IconButton size='small'>
                            <ContentCopy color='primary' />
                          </IconButton>
                        </Box>
                      </Box>
                      <Button
                        variant='outlined'
                        //variant="contained"
                        onClick={() => navigate(`/pro/recebimentos/indicacoes`)}
                        style={{
                          height: '30px'
                        }}
                      >
                        Ir para extrato
                      </Button>
                    </Box>

                    <Box>
                      <MenuHorizontal2 />
                      {/* {loadingGuias ? (
                      <Loading />
                    ) : (
                      <DataTable
                        table={{ columns: indicacoesColumns, rows: indicacoesRows }}
                        isSorted={true}
                        entriesPerPage={false}
                        showTotalEntries={false}
                      />
                    )} */}
                    </Box>
                  </Box>
                </Box>
              ) : null}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </DashboardLayout>
  )
}
