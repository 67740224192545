import * as React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

import axios from 'axios';

export default function TransitionAlerts(props) {
  console.log('Props', props);
  const [open, setOpen] = React.useState(true);

  async function handleClose(data) {
    const api = `https://q7amrtd8t8.execute-api.us-east-1.amazonaws.com/dev/autorizador/get_user_pro/update_infos?params=read_alert&values=${'true'}`;

    await axios
      .post(
        api,
        { data },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        }
      )
      .then(response => {
        setOpen(false);
        console.log('res', response);
      })
      .catch(error => {
        setOpen(false);
        console.log('errrro', error);
      });
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Collapse in={open}>
        <Alert
          variant="filled"
          severity="error"
          justifyContent="center"
          alignItems="center"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                handleClose({ cpf_cnpj: props?.user });
              }}
              sx={{
                color: 'white' 
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          <Typography
            variant="body1"
            fontWeight="bold"
            color="white"
          >
            {props?.alert?.title} - {props?.alert?.message}
          </Typography>
        </Alert>
      </Collapse>
    </Box>
  );
}
