// Generic components
import { TableRowField } from "../../../components";

// Specific components
import DownloadCsvButton from "./DownloadCsvButton";
import InputFileButton from "./InputFileButton";
import StatusTypography from "./StatusTypography";

// Utils functions and components
import { formatPrice } from "../../../utils/formatPrice";

export default function AccreditedReceiptsDataTable({
  data,
  handleDownloadCsv,
}) {
  if (Object.keys(data).length === 0) return { columns: [], rows: [] };

  function createData(date, valueHon, nf, status) {
    return {
      date,
      valueHon,
      nf,
      status,
    };
  }

  const entries = Object.entries(data?.saldo);

  const rows = entries.map((receipt) => {
    const year = receipt[0];
    const otherEntries = Object.entries(receipt[1]);
    return otherEntries.map((item) => {
      const month = item[0];
      return createData(
        `${month}/${year}`,
        item[1].value_hon,
        item[1].nf,
        item[1].status
      );
    });
  });

  const unifiedRows = rows.reduce(
    (accumulator, currentArray) => accumulator.concat(currentArray),
    []
  );

  let rowsTeste = [];

  function convertDateFormat(dateTimeString) {
    if (dateTimeString) {
      const [month, year] = dateTimeString.split("/");
      return new Date(year, month);
    }

    return "";
  }

  // Ordenar os dados com base na propriedade 'data'
  const sortedRows = unifiedRows?.sort(
    (a, b) => convertDateFormat(b.date) - convertDateFormat(a.date)
  );

  sortedRows?.map((receipt, index) => {
    const cashFlow = formatPrice(receipt.valueHon);
    const isPendente = receipt.status === "pendente";
    return (rowsTeste[index] = {
      data: <TableRowField value={receipt.date} />,
      qtd_procedimentos: <TableRowField value={receipt.qtd_procedimentos} />,
      valor: <TableRowField value={cashFlow} />,
      nf: <InputFileButton status={receipt.status} data={receipt.date} />,
      csv: (
        <DownloadCsvButton
          onClick={() => handleDownloadCsv(receipt.date)}
          disabled={!isPendente}
        />
      ),
      status: <StatusTypography value={receipt.status} />,
    });
  });

  return {
    columns: [
      { Header: "Data", accessor: "data", align: "left" },
      { Header: "Valor", accessor: "valor", width: "10%", align: "justify" },
      { Header: "Nota Fiscal", accessor: "nf", width: "10%", align: "center" },
      { Header: "Baixar CSV", accessor: "csv", width: "10%", align: "center" },
      { Header: "Status", accessor: "status", width: "10%", align: "center" },
    ],

    rows: rowsTeste,
  };
}
