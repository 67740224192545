import { ActionButton, TableRowField } from "../../../components";

export default function LaboratoryGuidesTableData(props) {
  if (Object.keys(props?.data).length === 0) return { columns: [], rows: [] };

  const cnpj = localStorage.getItem("cnpj");

  function createData(
    numero,
    microchip,
    pet,
    status,
    data,
    procedimentos,
    cnpj_sol,
    customer_id,
    value_to_pay,
    id,
    tutor,
    url,
    id_fatura,
    vet_solicitante,
    observacoes,
    laudo,
    pet_sexo,
    pet_nascimento,
    pet_especie,
    pet_raca,
    cnpj_lab,
    plano,
    name_credenciado
  ) {
    return {
      numero,
      microchip,
      pet,
      status,
      data,
      procedimentos,
      cnpj_sol,
      customer_id,
      value_to_pay,
      id,
      tutor,
      url,
      id_fatura,
      vet_solicitante,
      observacoes,
      laudo,
      pet_sexo,
      pet_nascimento,
      pet_especie,
      pet_raca,
      cnpj_lab,
      plano,
      name_credenciado,
    };
  }

  const rows = props.data.map((result) => {
    return createData(
      result.numero_guia,
      result.microchip_number,
      result.pet_name,
      result.status,
      result.created_at,
      result.procedimentos,
      result.cnpj_solicitante,
      result?.customer_id,
      result?.value_to_pay,
      result?.id,
      result?.tutor,
      result?.url?.url,
      result?.url?.id,
      result?.vet_solicitante,
      result?.observacoes,
      result?.laudo,
      result?.pet_sexo,
      result?.pet_nascimento,
      result?.pet_especie,
      result?.pet_raca,
      result?.cnpj_lab,
      result?.plano,
      result?.name_credenciado
    );
  });

  let rowsTeste = [];

  function convertDateFormat(dateTimeString) {
    if (dateTimeString) {
      const [date, time] = dateTimeString.split(" ");

      if (time) {
        const [day, month, year] = date.split("/");
        const [hour, minute, second] = time.split(":");
        return `${month}/${day}/${year} ${hour}:${minute}:${second}`;
      } else {
        const [day, month, year] = date.split("/");
        return `${month}/${day}/${year}`;
      }
    }

    return "";
  }

  // Ordenar os dados
  const sortedRows = rows
    ?.filter((row) => row?.cnpj_lab !== cnpj)
    .filter((row) => row?.status !== "cancelada")
    .filter((row) => {
      if (props.search === "") return row;
      return row?.numero.includes(props.search);
    })
    .filter((row) => {
      if (props.statusSearch === "") return row;
      return row?.status.includes(props.statusSearch);
    })
    .filter((row) => {
      if (props?.selectedDate === undefined || props?.selectedDate === "")
        return row;
      const selectedDateString =
        props?.selectedDate?.toLocaleDateString("pt-BR");
      return row?.data?.split(" ")[0] === selectedDateString?.split(" ")[0];
    })
    .sort(
      (a, b) =>
        new Date(convertDateFormat(b?.data)) -
        new Date(convertDateFormat(a?.data))
    );

  sortedRows.map((guide, index) => {
    return (rowsTeste[index] = {
      guia: <TableRowField value={guide.numero} />,
      microchip: <TableRowField value={guide.microchip} />,
      pet: <TableRowField value={guide.pet} />,
      tutor: <TableRowField value={guide.tutor} />,
      status: <TableRowField value={guide.status} type="status" />,
      data: <TableRowField value={guide.data} date />,
      acoes: <ActionButton guide={guide} />,
    });
  });

  return {
    columns: [
      { Header: "guia", accessor: "guia", width: "15%", align: "left" },
      { Header: "microchip", accessor: "microchip", align: "center" },
      { Header: "pet", accessor: "pet", align: "center" },
      { Header: "tutor", accessor: "tutor", align: "center" },
      {
        Header: "data de solicitação",
        accessor: "data",
        width: "5%",
        align: "center",
      },
      { Header: "status", accessor: "status", width: "5%", align: "center" },
      { Header: "ações", accessor: "acoes", width: "5%", align: "center" },
    ],

    rows: rowsTeste,
  };
}
