// react hooks
import { useState, useEffect } from "react";

import axios from "axios";

// @mui components
import {
  Box,
  TextField,
  InputAdornment,
  Typography,
  Button,
  Grid,
  IconButton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

// Application Users context
import { useUsersContext } from "../../context/UserContext";

// Generic Components
import { DashboardLayout, DataTable, Footer, Loading } from "../../components";

// Specific components
import HistoryGuidesTableData from "./components/HistoryGuidesTableData";

// Date picker and configuration
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "./components/Calendar.css";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from "date-fns/locale/pt-BR";

registerLocale("pt-BR", ptBR);
setDefaultLocale("pt-BR");

function HistoryGuides() {
  const { refresh, loadingGuias, getLoadingGuias } = useUsersContext();

  const [search, setSearch] = useState("");
  const [searchByMicrochip, setSearchByMicrochip] = useState("");
  const [searchByCpf, setSearchByCpf] = useState("");
  const [agendamentos, getAgendamentos] = useState([]);

  const [typeSearch, setTypeSearch] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const { columns, rows } = HistoryGuidesTableData({
    data: agendamentos,
    search,
    searchByMicrochip,
    typeSearch,
    selectedDate: selectedDate?.$d,
  });

  const handleDateRangeChange = (dates) => {
    const [startDate, endDate] = dates;
    setStartDate(startDate);
    setEndDate(endDate);

    if (startDate && endDate) {
      getHistoricoDataForRange(startDate, endDate);
    }
  };

  const handleSearchGuia = (event) => {
    if (event.key === "Enter" || event.type === "click") {
      // Aqui você pode chamar sua função de pesquisa com o valor atual do TextField
      getHistoricoCnpj(searchByMicrochip);
    }
  };

  const handleSearchCpf = (event) => {
    if (event.key === "Enter" || event.type === "click") {
      // Aqui você pode chamar sua função de pesquisa com o valor atual do TextField
      getHistoricoCpf(searchByCpf);
    }
  };

  async function getHistoricoCnpj(guia) {
    getLoadingGuias(true);

    const api = `https://q7amrtd8t8.execute-api.us-east-1.amazonaws.com/dev/autorizador/get_guias_pro?params=${guia}&lab=${"micro"}&timestamp=${new Date().getTime()}`;

    try {
      const response = await axios.get(api, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      getAgendamentos(response?.data);
      getLoadingGuias(false);
    } catch (error) {
      console.error(
        "🚀 ~ file: index.js:68 ~ getLaboratorialGuides ~ error:",
        error
      );

      getLoadingGuias(false);
    }
  }

  async function getHistoricoCpf(guia) {
    getLoadingGuias(true);

    const api = `https://q7amrtd8t8.execute-api.us-east-1.amazonaws.com/dev/autorizador/get_guias_pro?params=${guia}&lab=${"cpf"}&timestamp=${new Date().getTime()}`;

    try {
      const response = await axios.get(api, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      getAgendamentos(response?.data);
      getLoadingGuias(false);
    } catch (error) {
      console.error(
        "🚀 ~ file: index.js:68 ~ getLaboratorialGuides ~ error:",
        error
      );

      getLoadingGuias(false);
    }
  }

  const getHistoricoDataForRange = async (startDate, endDate) => {
    const currentDate = new Date(startDate);
    const allAgendamentos = []; // Array para salvar todas as requisições

    while (currentDate <= endDate) {
      const agendamentos = await getHistoricoData(currentDate);
      allAgendamentos.push(...agendamentos); // Adiciona os agendamentos ao array

      currentDate.setDate(currentDate.getDate() + 1);
    }

    // Salva todos os agendamentos no estado
    getAgendamentos(allAgendamentos);
  };

  async function getHistoricoData(data) {
    getLoadingGuias(true);
    const originalDate = new Date(data);

    const day = String(originalDate.getDate()).padStart(2, "0");
    const month = String(originalDate.getMonth() + 1).padStart(2, "0");
    const year = originalDate.getFullYear();

    const formattedDate = `${day}/${month}/${year}`;

    const api = `https://q7amrtd8t8.execute-api.us-east-1.amazonaws.com/dev/autorizador/get_guias_pro?params=${formattedDate}&lab=${"data"}&timestamp=${new Date().getTime()}`;

    try {
      const response = await axios.get(api, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      getLoadingGuias(false);
      return response?.data;
    } catch (error) {
      console.error(
        "🚀 ~ file: index.js:68 ~ getLaboratorialGuides ~ error:",
        error
      );
      getLoadingGuias(false);

      return [];
    }
  }

  const handleSearch = (event) => {
    if (event.key === "Enter" || event.type === "click") {
      // Aqui você pode chamar sua função de pesquisa com o valor atual do TextField
      getHistoricoGuia(search);
    }
  };

  async function getHistoricoGuia(guia) {
    getLoadingGuias(true);

    const api = `https://q7amrtd8t8.execute-api.us-east-1.amazonaws.com/dev/autorizador/get_guias_pro?params=${guia}&lab=${"guia"}&timestamp=${new Date().getTime()}`;

    try {
      const response = await axios.get(api, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      getAgendamentos(response?.data);
      getLoadingGuias(false);
    } catch (error) {
      console.error(
        "🚀 ~ file: index.js:68 ~ getLaboratorialGuides ~ error:",
        error
      );

      getLoadingGuias(false);
    }
  }

  useEffect(() => {
    if (startDate && endDate) {
      getHistoricoDataForRange(startDate, endDate);
      return;
    }

    if (searchByCpf) {
      getHistoricoCpf(searchByCpf);
      return;
    }

    if (searchByMicrochip) {
      getHistoricoCnpj(searchByMicrochip);
      return;
    }

    if (search) {
      getHistoricoGuia(search);
      return;
    }
  }, [refresh]);

  return (
    <DashboardLayout>
      <Box>
        <Box paddingBottom={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Box
                display="flex"
                flexDirection="column"
                sx={{ backgroundColor: "#FFFFFF" }}
                marginX={2}
                paddingX={6}
                paddingY={6}
                borderRadius="20px"
                gap={6}
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="title" color="primary">
                    Histórico de Guias
                  </Typography>
                </Box>

                <Grid container columnSpacing={2}>
                  <Grid item>
                    <DatePicker
                      startDate={startDate}
                      endDate={endDate}
                      selectsRange={true}
                      onChange={handleDateRangeChange}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Filtrar por data"
                      wrapperClassName="date-picker-wrapper"
                      className="date-picker"
                      calendarClassName="date-picker-calendar"
                      popperClassName="date-picker-popper"
                      customInput={
                        <input
                          type="text"
                          style={{
                            textAlign: "left",
                            paddingTop: "10px",
                          }}
                        />
                      }
                    />
                  </Grid>

                  <Grid item xs={2.5}>
                    <TextField
                      id="outlined-search"
                      label="Pesquise pela guia"
                      type="search"
                      value={search}
                      onChange={(event) => {
                        setSearch(event.target.value);
                      }}
                      sx={{ width: "100%" }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleSearch}>
                              <SearchIcon
                                sx={{ fontSize: 15, color: "#9C9DA9" }}
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      onKeyDown={handleSearch}
                    />
                  </Grid>

                  <Grid item xs={2.5}>
                    <TextField
                      id="outlined-search"
                      label="Pesquise pelo microchip"
                      type="search"
                      value={searchByMicrochip}
                      onChange={(event) => {
                        setSearchByMicrochip(event.target.value);
                      }}
                      sx={{ width: "100%" }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleSearchGuia}>
                              <SearchIcon
                                sx={{ fontSize: 15, color: "#9C9DA9" }}
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      onKeyDown={handleSearchGuia}
                    />
                  </Grid>

                  <Grid item xs={2.5}>
                    <TextField
                      id="outlined-search"
                      label="Pesquise pelo cpf"
                      type="search"
                      value={searchByCpf}
                      onChange={(event) => {
                        setSearchByCpf(event.target.value);
                      }}
                      sx={{ width: "100%" }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleSearchCpf}>
                              <SearchIcon
                                sx={{ fontSize: 15, color: "#9C9DA9" }}
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      onKeyDown={handleSearchCpf}
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <Button
                      fullWidth
                      variant="contained"
                      onClick={() => {
                        setSearch("");
                        setSelectedDate("");
                        setTypeSearch("");
                        setStartDate("");
                        setEndDate("");
                        setSearchByMicrochip("");
                      }}
                    >
                      Limpar
                    </Button>
                  </Grid>
                </Grid>

                <Box>
                  {loadingGuias ? (
                    <Loading />
                  ) : (
                    <DataTable
                      table={{ columns, rows }}
                      isSorted={true}
                      entriesPerPage={false}
                      showTotalEntries={true}
                      noEndBorder
                    />
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Footer />
    </DashboardLayout>
  );
}

export default HistoryGuides;
