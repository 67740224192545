// react hooks
import { useState, useEffect } from 'react'

// axios instance
import axios from 'axios'

// moment library
import moment from 'moment'

// react router dom hooks and components
import { useNavigate } from 'react-router-dom'

import zlib from 'zlib'
import pako from 'pako'
// import pdfjsLib from 'pdfjs-dist'

// @mui components
import {
  Box,
  Button,
  TextField,
  Typography,
  Select,
  MenuItem,
  CircularProgress,
  InputBase,
  IconButton,
  Switch,
  FormControl,
  Modal,
  Grid,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Input
} from '@mui/material'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'
import Autocomplete from '@mui/material/Autocomplete'
import { ArrowBack, AttachFile, Delete } from '@mui/icons-material'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import icon1 from '../../assets/images/upload.png'

import 'moment-timezone'
import './Calendar.css'

// @mui styles
import { makeStyles } from '@mui/styles'
import { styled } from '@mui/material/styles'

// @mui icons
import { Delete as DeleteIcon } from '@mui/icons-material'

// Application context
import { useUsersContext } from '../../context/UserContext.js'

// Generic Components
import { DashboardLayout, Footer } from '../../components/index.js'

// Specific components
import FindPetModal from './components/FindPetModal'
import ChoosePaymentModal from './components/ChoosePaymentModal'
import AnamnesisModal from './components/AnamnesisModal'
import RegisterPetModal from './components/RegisterPetModal.jsx'
import TransitionAlerts from './components/TransitionAlerts.jsx'
import LockScreen from './components/LockScreen.jsx'
import { PDFDocument, PDFName, PDFHexString } from 'pdf-lib'
// utils
import { getAlerts } from '../../utils/axios'

require('dotenv').config({ path: require('find-config')('.env') })

const ResponsiveButton = styled(Button)(({ theme }) => ({
  width: '100%',
  marginBottom: '10px',
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
    marginBottom: 0
  }
}))

const defaultValues = {
  microchip: '',
  nome_procedimento: '',
  observacoes: '',
  laudo: '',
  vetSolicitante: '',
  vetCPF: '',
  especialista: 0,
  intervalo_usos: 0,
  valor_base: '',
  created_at: new Date(),
  updated_at: '',
  deleted_at: '',
  id_grupo: '',
  ativo: 0,
  liberacao_automatica: 0,
  dados_adicionais: '',
  contavel: 0,
  pre_cirurgico: 0,
  emergencial: 0,
  peso: ''
}

const useStyles = makeStyles(theme => ({
  inputFile: {
    display: 'none'
  },
  uploadWrapper: {
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '196px',
    height: '40px',
    background: '#F5F7FB',
    boxShadow: '0px 1px 1px rgba(205, 205, 205, 0.25)',
    borderRadius: '7px',
    textAlign: 'right'
  },
  uploadImage: {
    width: '24px',
    height: '24px',
    cursor: 'pointer',
    position: 'absolute',
    top: '50%',
    right: '10px',
    transform: 'translateY(-50%)'
  },
  fileName: {
    display: 'inline-block',
    width: 'calc(100% - 48px)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  input: {
    opacity: 0,
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    cursor: 'pointer'
  },
  button: {
    background: '#FFFFFF',
    boxShadow: '0px 1px 1px rgba(205, 205, 205, 0.25)',
    borderRadius: '15px',
    width: '45px',
    height: '45px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

function GenerateGuide () {
  const {
    idTesteNoPay,
    getIdNoPay,
    getCnpj,
    cnpj,
    handleModalUpload,
    openModalUpload,
    nameCred,
    getNameCred,
    changeLoadingDash,
    email
  } = useUsersContext()

  const navigate = useNavigate()
  const cnpjLocal = localStorage.getItem('cnpj')

  const [veterinariansData, setVeterinariansData] = useState([])
  const [procedimentos, setProcedimentos] = useState()
  const [text, setText] = useState('')
  const [dadosPet, setDadosPet] = useState()
  const [dadosTabela, setDadosTabela] = useState([])
  const [numeroGuia, setNumeroGuia] = useState()
  const [idGuia, setIdGuia] = useState()
  const [usersPro, setUsersPro] = useState()
  const [userProHon, setUserProHon] = useState('')
  const [userProState, setUserProState] = useState('')
  const [valorSelecionado, setValorSelecionado] = useState('')
  const [procSelecionado, setProcSelecionado] = useState('')
  const [formValues, setFormValues] = useState(defaultValues)
  const [loadingCreate, setLoadingCreate] = useState(false)
  const [horaDay, setHoraDay] = useState()
  const [day, setDay] = useState()
  const [loadingBuscar, setBuscar] = useState(false)
  const [fileName, setFileName] = useState('')
  const [tipoAtendimento, setTipo] = useState('')
  const [isChecked, setIsChecked] = useState(false)
  const [isLaboratorial, setIsLaboratorial] = useState(false)
  const [selectedDate, setSelectedDate] = useState('')
  const [selectedLab, setSelectedLab] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [findPetModal, setFindPetModal] = useState(false)
  const [findPetModalMessage, setFindPetModalMessage] = useState()
  const [activePets, setActivePets] = useState([])
  const [paymentData, setPaymentData] = useState({
    guia: '2023',
    customer_id: 56576778,
    value_to_pay: '30.0',
    status: 'assinatura'
  })
  const [choosePaymentModal, setChoosePaymentModal] = useState(false)
  const [anamnesisModalState, setAnamnesisModalState] = useState(false)
  const [findActive, setFindActive] = useState(false)
  const [openModalAuto, setOpenModalAuto] = useState(false)

  const [alertPro, setAlertPro] = useState()
  const [activeAlert, setActiveAlert] = useState()
  const [readAlert, setRead] = useState('false')
  const [readTable, setReadTable] = useState('false')
  const [inputFiles, setInputFiles] = useState([])

  const email_user = localStorage.getItem('email')

  function handleAnamnesisModal (state) {
    setAnamnesisModalState(state)
  }

  const chavesUnicas = new Set(procedimentos?.map(objeto => objeto?.grupo?.S))

  // Usar Array.from para criar um novo array com os valores das chaves únicas
  const valoresUnicos = Array.from(chavesUnicas)

  const handleDateChange = date => {
    setSelectedDate(date)
  }

  function cleanAll () {
    setFormValues(defaultValues)
    setDadosTabela([])
    setText('')
    setValorSelecionado('')
    setProcSelecionado('')
    setProcedimentos()
    setDadosPet()
    setInputFiles([])
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4
  }

  const handleSelectChange = event => {
    const novoValorSelecionado = event.target.value
    setValorSelecionado(novoValorSelecionado)
  }

  const handleSelectProcChange = event => {
    const novoValorSelecionado = event.target.value // Obter o valor selecionado do evento

    const selectedOption = procedimentos?.find(
      option => option?.nome?.S === novoValorSelecionado
    )

    if (isProcedimentoEmCarencia(selectedOption)) {
      alert(
        'Este procedimento está em período de carência e não pode ser selecionado.'
      )
      return
    }

    if (isProcedimentoEmBloqueio(selectedOption)) {
      alert('Este procedimento possui limite de uso.')
      return
    }

    const {
      id,
      nome,
      grupo,
      essencial,
      essencial_int,
      essencial_new,
      plus,
      plus_int,
      prime,
      prime_int,
      prime_new,
      black,
      black_int,
      life,
      life_int,
      gold,
      gold_int,
      gold_new,
      premium,
      premium_int,
      premium_new,
      todos_es,
      todos_sp,
      tipo,
      emergencial,
      honorarios_htb,
      honorarios_htr,
      honorarios_hpp
    } = selectedOption

    if (grupo?.S === 'Consultas') {
      setProcSelecionado({
        id: id.S,
        nome: nome.S,
        grupo: grupo.S,
        valor: essencial
          ? essencial.S
          : essencial_int
          ? essencial_int.S
          : essencial_new
          ? essencial_new.S
          : plus
          ? plus.S
          : plus_int
          ? plus_int.S
          : prime
          ? prime.S
          : prime_int
          ? prime_int.S
          : prime_new
          ? prime_new.S
          : black_int
          ? black_int.S
          : black
          ? black.S
          : life
          ? life.S
          : life_int
          ? life_int.S
          : gold_int
          ? gold_int.S
          : gold_new
          ? gold_new.S
          : gold
          ? gold.S
          : premium
          ? premium.S
          : premium_int
          ? premium_int.S
          : premium_new
          ? premium_new.S
          : todos_es
          ? todos_es.S
          : todos_sp
          ? todos_sp.S
          : null,
        tipo: tipo?.S,
        emergencial: emergencial?.S,
        honorarios_htb:
          essencial || essencial_int || essencial_new
            ? honorarios_htb?.M?.essencial?.S
            : plus || plus_int
            ? honorarios_htb?.M?.plus?.S
            : prime || prime_int || prime_new
            ? honorarios_htb?.M?.prime?.S
            : black || black_int
            ? honorarios_htb?.M?.black?.S
            : life || life_int
            ? honorarios_htb?.M?.life?.S
            : gold || gold_int || gold_new
            ? honorarios_htb?.M?.gold?.S
            : premium || premium_int || premium_new
            ? honorarios_htb?.M?.premium?.S
            : todos_es
            ? honorarios_htb?.M?.todos_es?.S
            : todos_sp
            ? honorarios_htb?.M?.todos_sp?.S
            : null,
        honorarios_htr:
          essencial || essencial_int || essencial_new
            ? honorarios_htr?.M?.essencial?.S
            : plus || plus_int
            ? honorarios_htr?.M?.plus?.S
            : prime || prime_int || prime_new
            ? honorarios_htr?.M?.prime?.S
            : black || black_int
            ? honorarios_htr?.M?.black?.S
            : life || life_int
            ? honorarios_htr?.M?.life?.S
            : gold || gold_int || gold_new
            ? honorarios_htr?.M?.gold?.S
            : premium || premium_int || premium_new
            ? honorarios_htr?.M?.premium?.S
            : todos_es
            ? honorarios_htr?.M?.todos_es?.S
            : todos_sp
            ? honorarios_htr?.M?.todos_sp?.S
            : null,
        honorarios_hpp:
          essencial || essencial_int || essencial_new
            ? honorarios_hpp?.M?.essencial?.S
            : plus || plus_int
            ? honorarios_hpp?.M?.plus?.S
            : prime || prime_int || prime_new
            ? honorarios_hpp?.M?.prime?.S
            : black || black_int
            ? honorarios_hpp?.M?.black?.S
            : life || life_int
            ? honorarios_hpp?.M?.life?.S
            : gold || gold_int || gold_new
            ? honorarios_hpp?.M?.gold?.S
            : premium || premium_int || premium_new
            ? honorarios_hpp?.M?.premium?.S
            : todos_es
            ? honorarios_hpp?.M?.todos_es?.S
            : todos_sp
            ? honorarios_hpp?.M?.todos_sp?.S
            : null
      })
    } else {
      setProcSelecionado({
        id: id.S,
        nome: nome.S,
        grupo: grupo.S,
        valor: essencial
          ? essencial.S
          : essencial_int
          ? essencial_int.S
          : essencial_new
          ? essencial_new.S
          : plus
          ? plus.S
          : plus_int
          ? plus_int.S
          : prime
          ? prime.S
          : prime_int
          ? prime_int.S
          : prime_new
          ? prime_new.S
          : black_int
          ? black_int.S
          : black
          ? black.S
          : life
          ? life.S
          : life_int
          ? life_int.S
          : gold
          ? gold.S
          : gold_int
          ? gold_int.S
          : gold_new
          ? gold_new.S
          : premium
          ? premium.S
          : premium_int
          ? premium_int.S
          : premium_new
          ? premium_new.S
          : todos_es
          ? todos_es.S
          : todos_sp
          ? todos_sp.S
          : null,
        tipo: tipo?.S,
        emergencial: emergencial?.S,
        honorarios_htb: honorarios_htb?.S,
        honorarios_htr: honorarios_htr?.S,
        honorarios_hpp: honorarios_hpp?.S
      })
    }
    // Atualizar o estado com o novo valor selecionado
  }

  function getBrazilianTime () {
    const now = moment()
    const brazilianTime = now.tz('America/Sao_Paulo')
    const dayOfWeek = now.day()

    setHoraDay(brazilianTime.hour())
    setDay(dayOfWeek)

    if (
      dayOfWeek === 0 ||
      brazilianTime.hour() >= 20 ||
      brazilianTime.hour() < 8
    ) {
      setTipo('emergencia')
    } else if (
      dayOfWeek === 6 &&
      (brazilianTime.hour() >= 18 || brazilianTime.hour() < 8)
    ) {
      setTipo('emergencia')
    }
  }

  const addFator = () => {
    if (valorSelecionado && procSelecionado.nome) {
      setDadosTabela([...dadosTabela, procSelecionado])
      setProcSelecionado('')
      setValorSelecionado('')
    } else {
      alert('Por favor, selecione o procedimento.')
    }
  }

  const removeFator = index => {
    const newDadosTabela = [...dadosTabela] // cria uma cópia do array original
    newDadosTabela.splice(index, 1) // remove a linha correspondente ao índice especificado
    setDadosTabela(newDadosTabela)
  }

  function handleAnamnesisProcedures () {
    for (let procedures of dadosTabela) {
      if (procedures.id === '101012354') {
        return true
      }
    }
    return false
  }

  async function handleEmitir (
    selected = dadosTabela,
    anamnesisForm = undefined
  ) {
    if (
      formValues?.peso === '' ||
      formValues?.vetSolicitante === '' ||
      text === ''
    ) {
      return alert(
        'Preencha os campos de peso, laudo e veterinário solicitante'
      )
    }

    if (handleAnamnesisProcedures() && !anamnesisForm) {
      alert(
        'Para o procedimento de microchipagem, favor preencher o formulário.'
      )

      handleAnamnesisModal(true)
      return
    }

    const cnpjsParaVerificar = [
      '1'
      // '01645764000105',
      // '21706378000185',
      // '42901675000151',
      // '42514423000170',
      // '51239915000195',
      // '13606727000169',
      // '04487162000157',
      // '08817182000108',
      // '02075670000100'
    ]

    const hasExamesSimples = selected.some(
      item => item.grupo === 'Exames Simples'
    )

    if (
      hasExamesSimples &&
      !isLaboratorial &&
      cnpjsParaVerificar.includes(cnpjLocal)
    ) {
      return alert(
        'Por favor, marque a opção de guia laboratorial para os exames simples antes de emitir a guia.'
      )
    }

    const res = await handleSendToLambda()

    // console.log('ress attach', res)

    if (tipoAtendimento === 'emergencia') {
      handleModalUpload(true)
    } else {
      setLoadingCreate(true)
      createTable(selected, anamnesisForm)
    }
  }

  async function handleEmitirEmergencial (
    selected = dadosTabela,
    anamnesisForm
  ) {
    if (formValues.peso === '' || formValues.vetSolicitante === '') {
      return alert('Preencha os campos de peso e de veterinário solicitante')
    }

    if (handleAnamnesisProcedures() && !anamnesisForm) {
      alert(
        'Para o procedimento de microchipagem, favor preencher o formulário.'
      )

      handleAnamnesisModal(true)
      return
    }
    const res = await handleSendToLambda()

    handleModalUpload(false)
    setLoadingCreate(true)
    createTable(selected, anamnesisForm)
  }

  const classes = useStyles()

  const handleUpload = event => {
    const file = event.target.files[0]
    setFileName(file.name)
    // Faça o upload do arquivo aqui
  }

  const handleUploadFile = event => {
    const files = event.target.files

    setInputFiles([...inputFiles, ...files])
  }

  const handleRemoveFile = index => {
    const newFiles = [...inputFiles]
    newFiles.splice(index, 1)
    setInputFiles(newFiles)
  }

  const compressPDF = async file => {
    try {
      const arrayBuffer = await file.arrayBuffer()
      const pdfDoc = await PDFDocument.load(arrayBuffer)

      const pdfBytes = await pdfDoc.save({ objectsPerTick: 50 })

      const compressedBytes = compressData(pdfBytes)

      const base64String = btoa(String.fromCharCode(...compressedBytes))

      return base64String
    } catch (error) {
      console.error('Erro ao comprimir o PDF:', error.message)
      throw new Error('Erro ao comprimir o PDF: ' + error.message)
    }
  }

  const compressData = data => {
    let compressedData = []
    const length = data.length
    let position = 0
    let limit = 0

    while (position < length) {
      limit = Math.min(position + 65535, length)
      compressedData.push(...pako.deflate(data.slice(position, limit)))
      position = limit
    }

    return compressedData
  }

  const handleSendToLambda = async () => {
    if (!inputFiles || inputFiles.length === 0) {
      console.error('Nenhum arquivo fornecido.')
      return
    }
    changeLoadingDash(true)

    const promises = inputFiles.map(async file => {
      try {
        const formData = new FormData()
        formData.append('file_name', file.name)
        formData.append('guia', numeroGuia)

        const response = await axios.post(
          'https://q7amrtd8t8.execute-api.us-east-1.amazonaws.com/dev/autorizador/generate_attached_url',
          formData,
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json'
            }
          }
        )

        const presignedUrl = response.data.presigned_url

        await uploadToS3(presignedUrl, file)
        changeLoadingDash(false)
        // console.log(`Arquivo ${file.name} enviado com sucesso!`);
      } catch (error) {
        changeLoadingDash(false)
        alert('Algo deu errado no envio dos arquivos')
        console.error(`Erro ao enviar arquivo ${file.name}:`, error)
      }
    })

    await Promise.all(promises)
    changeLoadingDash(false)
    console.log('Todos os arquivos foram enviados com sucesso!')
  }

  // const uploadToS3 = async (presignedUrl, file) => {
  //   console.log('pre', presignedUrl)
  //   console.log('file', file)
  //   try {
  //     await fetch(presignedUrl, {
  //       method: 'PUT',
  //       body: file
  //     });
  //   } catch (error) {
  //     console.error(`Erro ao fazer upload do arquivo ${file.name} para o S3:`, error);
  //     throw error; // Propaga o erro para o bloco catch mais externo, se necessário
  //   }
  // };

  const uploadToS3 = async (presignedUrl, file) => {
    try {
      const response = await axios.put(presignedUrl, file, {
        headers: {
          'Content-Type': file.type,
          'x-amz-acl': 'public-read'
        }
      })
      console.log('Upload do arquivo para o S3 bem-sucedido:', response)
    } catch (error) {
      console.error(
        `Erro ao fazer upload do arquivo ${file.name} para o S3:`,
        error
      )
      throw error
    }
  }
  const sendToLambda = async (base64String, fileName) => {
    const formData = new FormData()

    formData.append('guia', numeroGuia)
    // formData.append("data", guide);
    formData.append('pdf', base64String)
    formData.append('filename', fileName)

    try {
      const response = await axios.post(
        'https://q7amrtd8t8.execute-api.us-east-1.amazonaws.com/dev/autorizador/send_attachment',
        formData,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
    } catch (error) {
      console.error('Erro ao enviar para a Lambda', error)
    }
  }

  // const handleSendToLambda = async () => {
  //   const base64Promises = inputFiles?.map((file, index) => {
  //     return new Promise((resolve) => {
  //       const reader = new FileReader();
  //       reader.onload = (e) => {
  //         const base64String = e.target.result.split(",")[1];

  //         resolve({ base64String, index });
  //       };
  //       reader.readAsDataURL(file);
  //     });
  //   });

  //   try {
  //     const base64DataArray = await Promise.all(base64Promises);

  //     base64DataArray.forEach(({ base64String, index }) => {
  //       const fileName = `file_${index + 1}.pdf`;

  //       sendToLambda(base64String, fileName);
  //     });
  //   } catch (error) {
  //     console.error("Erro ao converter para base64", error);
  //   }
  // };

  function createTable (selected, anamnesisForm) {
    setOpenModal(false)
    const valorTotal = selected.reduce((total, item) => {
      // Converte o valor de string para número e soma ao total
      return total + parseFloat(item.valor)
    }, 0)

    const valorTotalHonHtr = selected.reduce((total, item) => {
      // Converte o valor de string para número e soma ao total
      return total + parseFloat(item.honorarios_htr)
    }, 0)

    const valorTotalHonHtb = selected.reduce((total, item) => {
      // Converte o valor de string para número e soma ao total
      return total + parseFloat(item.honorarios_htb)
    }, 0)

    const valorTotalHonHpp = selected.reduce((total, item) => {
      // Converte o valor de string para número e soma ao total
      return total + parseFloat(item.honorarios_hpp)
    }, 0)

    const type = selected.filter(sele => sele?.tipo?.includes('manual')).length

    let value_hon = ''

    switch (userProHon) {
      case 'htb':
        value_hon = valorTotalHonHtb
        break
      case 'htr':
        value_hon = valorTotalHonHtr
        break
      case 'hpp':
        value_hon = valorTotalHonHpp
        break
      default:
        value_hon = valorTotalHonHtr
        break
    }

    let tipo_guia = ''
    let status = ''

    if (type > 0 && tipoAtendimento !== 'emergencia') {
      tipo_guia = 'laranja'
      status = 'em auditoria'
    } else {
      tipo_guia = tipoAtendimento === 'emergencia' ? 'roxa' : 'roxa'
      status =
        dadosPet?.plan?.M?.name?.S.includes('Integral') ||
        dadosPet?.plan?.M?.product?.S.includes('Integral')
          ? 'assinatura'
          : 'pagamento'
    }

    setPaymentData({
      guia: numeroGuia,
      customer_id:
        dadosPet?.customer?.M?.id?.S !== undefined
          ? dadosPet?.customer?.M?.id?.S
          : dadosPet?.customer?.M?.id?.N,
      value_to_pay:
        dadosPet?.plan?.M?.name?.S.includes('Integral') ||
        dadosPet?.plan?.M?.product?.S.includes('Integral')
          ? null
          : valorTotal.toFixed(2),
      status: status,
      microchip_number:
        dadosPet?.microchip?.S !== undefined
          ? dadosPet?.microchip?.S
          : dadosPet?.microchip_number?.S
    })

    const api =
      'https://q7amrtd8t8.execute-api.us-east-1.amazonaws.com/dev/autorizador/generate'

    axios
      .post(
        api,
        {
          microchip_number:
            dadosPet?.microchip?.S !== undefined
              ? dadosPet?.microchip?.S
              : dadosPet?.microchip_number?.S,
          procedimentos: selected,
          plano_integral:
            dadosPet?.plan?.M?.name?.S.includes('Integral') ||
            dadosPet?.plan?.M?.product?.S.includes('Integral')
              ? true
              : false,
          id: numeroGuia,
          customer_id:
            dadosPet?.customer?.M?.id?.S !== undefined
              ? dadosPet?.customer?.M?.id?.S
              : dadosPet?.customer?.M?.id?.N,
          numero_guia: numeroGuia,
          value_to_pay:
            dadosPet?.plan?.M?.name?.S.includes('Integral') ||
            dadosPet?.plan?.M?.product?.S.includes('Integral')
              ? null
              : valorTotal.toFixed(2),
          observacoes: formValues?.observacoes,
          laudo: text,
          vet_solicitante: formValues.vetSolicitante,
          pet_id: dadosPet?.metadata?.M?.pet_id?.S,
          pet_name: dadosPet?.metadata?.M?.pet_name?.S,
          tipo_guia: tipo_guia,
          tipo_atendimento:
            tipoAtendimento === 'emergencia' ? 'emergencia' : 'normal',
          status: status,
          auditoria: 'false',
          liberacao: type > 0 ? 'false' : 'true',
          cnpj_solicitante: cnpj,
          cnpj_lab: selectedLab !== '' ? selectedLab : cnpj,
          tutor: dadosPet?.customer?.M?.name?.S,
          peso: formValues?.peso,
          dataRetro: selectedDate,
          pet_especie:
            dadosPet?.metadata?.M?.pet_especie?.S !== undefined
              ? dadosPet?.metadata?.M?.pet_especie?.S
              : '',
          pet_raca:
            dadosPet?.metadata?.M?.pet_raca?.S !== undefined
              ? dadosPet?.metadata?.M?.pet_raca?.S
              : '',
          pet_nascimento:
            dadosPet?.metadata?.M?.pet_nascimento?.S !== undefined
              ? dadosPet?.metadata?.M?.pet_nascimento?.S
              : '',
          pet_sexo:
            dadosPet?.metadata?.M?.pet_sexo?.S !== undefined
              ? dadosPet?.metadata?.M?.pet_sexo?.S
              : '',
          value_hon: value_hon.toString(),
          table_hon: userProHon,
          plano: handlePlanName(dadosPet?.plan?.M),
          name_credenciado: nameCred,
          anamnesis_form: anamnesisForm,
          created_by: email,
          email_customer:
            dadosPet?.customer?.M?.email?.S !== undefined
              ? dadosPet?.customer?.M?.email?.S
              : ''
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        }
      )
      .then(response => {
        setLoadingCreate(false)
        handleAnamnesisModal(false)
        handleChoosePaymentModal(true)
        cleanAll()
      })
      .catch(error => {
        console.error('🚀 ~ file: index.js:653 ~ createTable ~ error:', error)
      })
  }

  function getGuideNumber () {
    const api =
      'https://q7amrtd8t8.execute-api.us-east-1.amazonaws.com/dev/autorizador/get_number_guia'

    axios
      .get(api, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      })
      .then(response => {
        setNumeroGuia(response.data.toString())
      })
      .catch(error => {
        console.error('erro guide', error)
      })
  }

  async function handleGetAlerts () {
    const { data, status } = await getAlerts()

    if (status !== 200) {
      // handleErrorData({
      //   title: "Erro ao carregar os dados",
      //   message: data?.message,
      // });

      // handleErrorModalState(true);
      // getLoadingGuias(false);
      return
    }

    setAlertPro(data?.filter(data => data?.active === 'true'))
  }

  async function getTableUser (getIdNoPay) {
    const api = `https://q7amrtd8t8.execute-api.us-east-1.amazonaws.com/dev/autorizador/get_info_pro?params=${localStorage.getItem(
      'email'
    )}`

    axios
      .get(api, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      })
      .then(response => {
        getIdNoPay(response?.data[0]?.liberado?.S)
        getNameCred(response?.data[0]?.nomeEmpresa?.S)
        localStorage.setItem('nameCred', response.data[0]?.nomeEmpresa?.S)

        getCnpj(response?.data[0]?.cnpj?.S)
        setUserProHon(response?.data[0]?.table_hon?.S)
        setUserProState(response?.data[0]?.estado?.S)
        localStorage.setItem('cnpj', response?.data[0]?.cnpj?.S)
        setRead(response?.data[0]?.read_alert?.S)
        setReadTable(response?.data[0]?.read_table?.S)
        if (response?.data[0]?.liberado?.S === 'true') {
          getVeterinarians(response?.data[0]?.cnpj?.S)
        }
      })
      .catch(error => {
        console.error('🚀 ~ file: index.js:709 ~ getTableUser ~ error:', error)
      })
  }

  const handleInputChange = e => {
    const { name, value } = e.target
    setFormValues({
      ...formValues,
      [name]: value
    })
  }

  const handleVeterinarianChange = e => {
    const cpf = e.target.value
    const clickedOption = veterinariansData.find(
      data => data?.vet_cpf?.S === cpf
    )

    setFormValues(prevState => ({
      ...prevState,
      vetCPF: clickedOption?.vet_cpf?.S,
      vetSolicitante: clickedOption?.vet_name?.S
    }))
  }

  const handleRemove = () => {
    setFileName('')
  }

  const handleSubmit = event => {
    event.preventDefault()

    setBuscar(true)
    getTablePetsOld(formValues.microchip)
  }

  function handlePlanName (plan) {
    if (plan && plan.product) {
      const product = plan.product.S

      switch (product) {
        case 'Anuidade Black':
          return 'Black'
        case 'Anuidade Black Integral':
          return 'Black - Integral'
        case 'Anuidade Essencial':
          return 'Essencial'
        case 'Anuidade Essencial Integral':
          return 'Essencial - Integral'
        case 'Anuidade Plus':
          return 'Plus'
        case 'Plus Integral':
          return 'Plus Integral'
        case 'Anuidade Prime':
          return 'Prime'
        case 'Prime Integral':
          return 'Prime Integral'
        case 'Essencial Integral Exclusivo - Anual':
          return 'Essencial Exclusivo'
        case 'Essencial Integral Exclusivo - Mensal':
          return 'Essencial Exclusivo'
        case 'Integral - Essencial com Carência':
          return 'Essencial Integral'
        case 'Integral - Essencial com Carência Anual':
          return 'Essencial Integral'
        case 'Integral - Plus com Carência':
          return 'Plus Integral'
        case 'Integral - Plus com Carência Anual':
          return 'Plus Integral'
        case 'Integral - Prime com Carência':
          return 'Prime Integral'
        case 'Integral - Prime com Carência Anual':
          return 'Prime Integral'
        case 'Mensalidade Black':
          return 'Black'
        case 'Mensalidade Essencial':
          return 'Essencial'
        case 'Mensalidade Plus':
          return 'Plus'
        case 'Mensalidade Prime':
          return 'Prime'
        case 'Participativo - Essencial com carência':
          return 'Essencial'
        case 'Participativo - Essencial com carência ANUAL':
          return 'Essencial'
        case 'Participativo - Plus com carência':
          return 'Plus'
        case 'Participativo - Plus com carência ANUAL':
          return 'Plus'
        case 'Participativo - Prime com carência':
          return 'Prime'
        case 'Participativo - Prime com carência ANUAL':
          return 'Prime'
        case 'Plus Integral Exclusivo - Anual':
          return 'Plus Integral Exclusivo'
        case 'Plus Integral Exclusivo - Mensal':
          return 'Plus Integral Exclusivo'
        case 'Prime Integral Exclusivo - Anual':
          return 'Prime Integral Exclusivo'
        case 'Prime Integral Exclusivo - Mensal':
          return 'Prime Integral Exclusivo'
        case 'Plano Life Participativo - Anual':
          return 'Life'
        case 'Plano Life Integral - Anual':
          return 'Life'
        case 'Plano Life Participativo - Mensal':
          return 'Life'
        case 'Plano Life Integral - Mensal':
          return 'Life'
        case 'Plano Premium Participativo - Anual':
          return 'Premium'
        case 'Plano Premium Integral - Mensal':
          return 'Premium'
        case 'Plano Premium Participativo - Mensal':
          return 'Premium'
        case 'Plano Premium Integral - Anual':
          return 'Premium'
        case 'Plano Gold Integral - Anual':
          return 'Gold'
        case 'Plano Gold Participativo - Anual':
          return 'Gold'
        case 'Plano Gold Integral - Mensal':
          return 'Gold'
        case 'Plano Gold Participativo - Mensal':
          return 'Gold'
        case 'Plano Essencial Integral - Mensal':
          return 'Essencial'
        case 'Anuidade Gold Integral':
          return 'Gold'
        case 'Anuidade Gold Participativo':
          return 'Gold'
        case 'Anuidade Premium Integral':
          return 'Premium'
        case 'Anuidade Premium Participativo':
          return 'Premium'
        case 'Plano Essencial Integral - Anual':
          return 'Essencial'
        case 'Plano Essencial Participativo - Anual':
          return 'Essencial'
        case 'Plano Essencial Integral - Mensal':
          return 'Essencial'
        case 'Plano Essencial Participativo - Mensal':
          return 'Essencial'
        case 'Mensalidade Gold Integral':
          return 'Gold'
        case 'Mensalidade Gold Participativo':
          return 'Gold'
        case 'Mensalidade Premium Integral':
          return 'Premium'
        case 'Mensalidade Premium Participativo':
          return 'Premium'
        case 'Plano Para Todos Participativo ES - Anual':
          return 'Para Todos'
        case 'Plano Para Todos Participativo ES - Mensal':
          return 'Para Todos'
        case 'Plano Para Todos Participativo SP - Anual':
          return 'Para Todos'
        case 'Plano Para Todos Participativo SP - Mensal':
          return 'Para Todos'
        default:
          return ''
      }
    } else {
      return plan?.name?.S
    }
  }

  function generateIds () {
    getGuideNumber()
    const randomNum = Math.floor(Math.random() * 1000000)
      .toString()
      .padStart(6, '0')
    setIdGuia(randomNum)
  }

  function getUsersPro () {
    const api = `https://q7amrtd8t8.execute-api.us-east-1.amazonaws.com/dev/autorizador/get_user_pro`

    axios
      .get(api, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      })
      .then(response => {
        setUsersPro(response.data)
      })
      .catch(error => {})
  }

  const isProcedimentoEmCarencia = procedimento => {
    return procedimento?.is_carencia
  }

  const isProcedimentoEmBloqueio = procedimento => {
    return procedimento?.is_bloqueio
  }

  async function getTablePetsOld (microchip) {
    const microUpper = microchip.toUpperCase()

    const api = `https://q7amrtd8t8.execute-api.us-east-1.amazonaws.com/dev/autorizador/gey_by_microchip?microchip_number=${microUpper}&params=${cnpjLocal}&hon_table=${userProHon}&pro_state=${userProState}`

    await axios
      .get(api, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      })
      .then(response => {
        if (response?.status === 201) {
          setFindPetModalMessage(response?.data?.message)
          handleFindPetModal(true)
          setBuscar(false)
          return
        }

        if (response?.status === 202) {
          setFindPetModalMessage(response?.data?.message)
          setFindActive(true)
          handleFindPetModal(true)
          setBuscar(false)
          return
        }

        setProcedimentos(response?.data?.procedimentos)
        setDadosPet(response?.data?.pet_data)

        if (
          response?.data?.pet_data?.metadata?.M?.pet_especie?.S === undefined ||
          response?.data?.pet_data?.metadata?.M?.pet_raca?.S === undefined ||
          response?.data?.pet_data?.metadata?.M?.pet_especie?.S === '' ||
          response?.data?.pet_data?.metadata?.M?.pet_raca?.S === ''
        ) {
          setOpenModal(true) // Chama a função para abrir o modal automaticamente
        }
        generateIds()
        setBuscar(false)
      })
      .catch(error => {
        setBuscar(false)
      })
  }

  async function getVeterinarians (cnpj) {
    changeLoadingDash(true)
    const api = `https://q7amrtd8t8.execute-api.us-east-1.amazonaws.com/dev/autorizador/get_veterinarians?params=clinica_cnpj&values=${cnpj}`

    await axios
      .get(api, {
        headers: {
          'Content-type': 'application/json'
        }
      })
      .then(response => {
        changeLoadingDash(false)

        if (response?.status === 201) {
          alert('Por favor cadastre um veterinário antes de continuar.')
          setVeterinariansData([])
          return navigate('/pro/perfil')
        }

        const activeVeterinarians = response?.data.filter(
          data => data.vet_ativo?.BOOL !== false
        )

        if (activeVeterinarians.length === 0) {
          alert('Por favor ative um veterinário antes de continuar.')
          setVeterinariansData(activeVeterinarians)
          return navigate('/pro/perfil')
        }

        setVeterinariansData(activeVeterinarians)
      })
      .catch(error => {
        changeLoadingDash(false)
      })
  }

  async function getTutorByCPF (cpf) {
    changeLoadingDash(true)

    const api = `https://q7amrtd8t8.execute-api.us-east-1.amazonaws.com/dev/customer/tutor/list_by_params?params=id&values=${cpf}`

    await axios
      .get(api, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      })
      .then(response => {
        if (response?.status !== 200) {
          changeLoadingDash(false)
          return
        }

        changeLoadingDash(false)
        return getTutorPets(response?.data?.customer_id?.S)
      })
      .catch(error => {
        changeLoadingDash(false)
      })
  }

  async function getTutorPets (id) {
    changeLoadingDash(true)
    const api = `https://q7amrtd8t8.execute-api.us-east-1.amazonaws.com/dev/vindi/subscription?id=${id}`

    await axios
      .get(api, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      })
      .then(response => {
        setActivePets(
          response?.data?.subscriptions?.filter(
            subscription => subscription?.status === 'active'
          )
        )

        changeLoadingDash(false)
      })
      .catch(error => {
        console.error('🚀 ~ file: index.js:1037 ~ getTutorPets ~ error:', error)
        changeLoadingDash(false)
      })
  }

  async function getMicrochipByPetId (pet_id) {
    changeLoadingDash(true)
    const api = `https://q7amrtd8t8.execute-api.us-east-1.amazonaws.com/dev/customer/pets/list_by_params?params=id&values=${pet_id}`

    await axios
      .get(api, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      })
      .then(response => {
        if (response?.status !== 200) {
          return
        }

        setFormValues(prevState => ({
          ...prevState,
          microchip: response?.data[0]?.microchip
        }))
        setActivePets([])
        changeLoadingDash(false)
        handleFindPetModal(false)
      })
      .catch(error => {
        console.error(
          '🚀 ~ file: index.js:1065 ~ getMicrochipByPetId ~ error:',
          error
        )
        changeLoadingDash(false)
      })
  }

  function handleFindPetModal (state) {
    setFindPetModal(state)
  }

  function handleChoosePaymentModal (state) {
    setChoosePaymentModal(state)
  }

  const handleLabSelect = event => {
    setSelectedLab(event.target.value)
  }

  const handleCloseModalAuto = () => {
    setOpenModalAuto(false)
  }

  async function handleAcceptTable (data) {
    changeLoadingDash(true)
    const api = `https://q7amrtd8t8.execute-api.us-east-1.amazonaws.com/dev/autorizador/get_user_pro/update_infos?params=read_table&values=${'true'}`

    await axios
      .post(
        api,
        { data },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        }
      )
      .then(response => {
        changeLoadingDash(false)
      })
      .catch(error => {
        changeLoadingDash(false)
      })
    handleCloseModalAuto()
  }

  useEffect(() => {
    if (readTable === 'false' && userProHon === 'htr') {
      setOpenModalAuto(true)
    }
  }, [readTable, userProHon])

  useEffect(() => {
    changeLoadingDash(true)
    try {
      handleGetAlerts()
      getTableUser(getIdNoPay)
      getUsersPro()
      getBrazilianTime()
    } catch (error) {
      changeLoadingDash(false)
    }
  }, [])

  return idTesteNoPay === 'false' || idTesteNoPay === undefined ? (
    <LockScreen />
  ) : (
    <DashboardLayout>
      <FindPetModal
        findPetModalMessage={findPetModalMessage}
        findPetModalState={findPetModal}
        handleFindPetModal={handleFindPetModal}
        getTutorByCPF={getTutorByCPF}
        activePets={activePets}
        getMicrochipByPetId={getMicrochipByPetId}
        findActive={findActive}
      />

      <ChoosePaymentModal
        choosePaymentModalState={choosePaymentModal}
        handleChoosePaymentModal={handleChoosePaymentModal}
        paymentData={paymentData}
      />

      <Modal open={openModalAuto} onClose={handleCloseModalAuto}>
        <Box
          sx={{
            position: 'absolute',
            width: '80%',
            maxWidth: '90vw',
            maxHeight: '90vh',
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        >
          <Typography variant='h6' color='primary' gutterBottom>
            Informações Importantes
          </Typography>

          <p>
            Foi cadastrada uma nova tabela de honorários para sua conta. Clique
            no botão abaixo para aceitar.
          </p>

          <iframe
            title='PDF Viewer'
            src='https://appdocuments-prd.s3.amazonaws.com/Tabelas/Tabela_A.pdf'
            width='100%'
            height='500px'
          ></iframe>

          <Button
            variant='contained'
            onClick={() => handleAcceptTable({ cpf_cnpj: cnpj })}
          >
            Aceitar Tabela
          </Button>
        </Box>
      </Modal>

      <AnamnesisModal
        anamnesisModalState={anamnesisModalState}
        handleAnamnesisModal={handleAnamnesisModal}
        handleEmitir={
          tipoAtendimento === 'emergencia'
            ? handleEmitirEmergencial
            : handleEmitir
        }
        petGender={dadosPet?.metadata?.M?.pet_sexo?.S.split(
          ''
        )[0]?.toLowerCase()}
      />

      <Box>
        <Stack
          sx={{ width: '97%', marginLeft: '22px', marginBottom: '10px' }}
          spacing={2}
        >
          {/* <Alert variant="filled" severity="info" justifyContent="center" alignItems="center">
          This is an info alert — check it out!
        </Alert> */}
          {readAlert === 'false' && alertPro?.length > 0 && (
            <TransitionAlerts alert={alertPro[0]} user={cnpjLocal} />
          )}
        </Stack>
        <Box pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Box
                display='flex'
                flexDirection='column'
                height='100%'
                sx={{ backgroundColor: '#FFFFFF' }}
                marginX={2}
                paddingX={4}
                paddingY={4}
                borderRadius='20px'
                gap={2}
              >
                <Typography
                  variant='body1'
                  style={{
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: '16px',
                    lineHeight: '14px',
                    letterSpacing: '-0.8px',
                    textTransform: 'uppercase',
                    color: '#9c9da9',
                    marginTop: '54px',
                    marginLeft: '51px'
                  }}
                >
                  guias
                </Typography>
                <Typography
                  variant='body1'
                  style={{
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: '22px',
                    lineHeight: '14px',
                    letterSpacing: '-1px',
                    textTransform: 'uppercase',
                    color: '#32D1A0',
                    marginTop: '18px',
                    marginLeft: '51px'
                  }}
                >
                  dados gerais
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    marginLeft: '51px',
                    marginTop: '20px'
                  }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography
                      variant='body1'
                      style={{
                        fontStyle: 'normal',
                        fontWeight: 500,
                        fontSize: '14px',
                        lineHeight: '20px',
                        letterSpacing: '-1px',
                        textTransform: 'uppercase',
                        color: '#AFBCD2'
                      }}
                    >
                      tipo de atendimento
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                      {day === 0 || horaDay >= 20 || horaDay < 8 ? (
                        <ResponsiveButton
                          variant='contained'
                          color='primary'
                          disableElevation
                          disabled
                          style={{
                            height: '40px',
                            background: '#FFFFFF',
                            border: '1px solid red',
                            boxShadow: '0px 1px 1px rgba(205, 205, 205, 0.25)',
                            borderRadius: '8px',
                            color: 'red'
                            // marginLeft: 15
                          }}
                        >
                          Emergência
                        </ResponsiveButton>
                      ) : day === 6 && (horaDay >= 18 || horaDay < 8) ? (
                        <ResponsiveButton
                          variant='contained'
                          color='primary'
                          disableElevation
                          disabled
                          style={{
                            height: '40px',
                            background: '#FFFFFF',
                            border: '1px solid red',
                            boxShadow: '0px 1px 1px rgba(205, 205, 205, 0.25)',
                            borderRadius: '8px',
                            color: 'red'
                            // marginLeft: 15
                          }}
                        >
                          Emergência
                        </ResponsiveButton>
                      ) : (
                        <ResponsiveButton
                          variant='contained'
                          color='primary'
                          disableElevation
                          disabled
                          style={{
                            height: '40px',
                            background: '#C5F1F4',
                            // border: '1px solid #32D1A0',
                            boxShadow: '0px 1px 1px rgba(205, 205, 205, 0.25)',
                            borderRadius: '5px',
                            color: '#3FC3E0'
                            // marginLeft: 15
                          }}
                        >
                          Normal
                        </ResponsiveButton>
                      )}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginLeft: '23px'
                    }}
                  >
                    <Typography
                      variant='body1'
                      style={{
                        fontStyle: 'normal',
                        fontWeight: 500,
                        fontSize: '14px',
                        lineHeight: '20px',
                        letterSpacing: '-1px',
                        textTransform: 'uppercase',
                        color: '#AFBCD2'
                      }}
                    >
                      Guia laboratorial?
                    </Typography>

                    <Switch
                      checked={isLaboratorial}
                      onChange={() => setIsLaboratorial(!isLaboratorial)}
                      inputProps={{ 'aria-label': 'guia laboratorial' }}
                      color='primary'
                    />
                  </Box>
                  {isLaboratorial ? (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginLeft: '23px'
                      }}
                    >
                      <Typography
                        variant='body1'
                        style={{
                          fontStyle: 'normal',
                          fontWeight: 500,
                          fontSize: '14px',
                          lineHeight: '20px',
                          letterSpacing: '-1px',
                          textTransform: 'uppercase',
                          color: '#AFBCD2'
                        }}
                      >
                        laboratório
                      </Typography>

                      <FormControl variant='filled'>
                        <Select
                          labelId='select-label'
                          value={selectedLab}
                          onChange={handleLabSelect}
                          inputProps={{
                            style: {
                              textAlign: 'left',
                              paddingTop: '10px'
                            }
                          }}
                          style={{
                            width: '196px',
                            height: '40px',
                            backgroundColor: '#F5F7FB',
                            boxShadow: '0px 1px 1px rgba(205, 205, 205, 0.25)',
                            borderRadius: '7px',
                            fontSize: '14px'
                          }}
                          disableUnderline
                        >
                          {usersPro
                            ?.filter(data => data?.laboratorio?.S === 'true')
                            .map(data => (
                              <MenuItem
                                key={data?.cnpj?.S}
                                value={data?.cnpj?.S}
                              >
                                {data?.nomeEmpresa?.S}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Box>
                  ) : null}
                  <form onSubmit={handleSubmit}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginLeft: '82px'
                      }}
                    >
                      <Typography
                        variant='body1'
                        style={{
                          fontStyle: 'normal',
                          fontWeight: 500,
                          fontSize: '14px',
                          lineHeight: '20px',
                          letterSpacing: '-1px',
                          textTransform: 'uppercase',
                          color: '#AFBCD2'
                        }}
                      >
                        microchip
                      </Typography>
                      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <TextField
                          variant='filled'
                          name='microchip'
                          placeholder='Digite algo aqui'
                          value={formValues.microchip}
                          onChange={handleInputChange}
                          inputProps={{
                            style: {
                              textAlign: 'left',
                              paddingTop: '10px' // Ajuste o valor do padding top para centralizar verticalmente o texto
                            }
                          }}
                          InputProps={{
                            disableUnderline: true,
                            style: {
                              width: '196px',
                              height: '40px',
                              backgroundColor: '#F5F7FB',
                              boxShadow:
                                '0px 1px 1px rgba(205, 205, 205, 0.25)',
                              borderRadius: '7px',
                              fontSize: '14px'
                              // alignItems: 'center'
                            }
                          }}
                        />
                        <ResponsiveButton
                          variant='contained'
                          color='primary'
                          disableElevation
                          type='submit'
                          style={{
                            height: '40px',
                            background: '#FFFFFF',
                            border: '1px solid #32D1A0',
                            boxShadow: '0px 1px 1px rgba(205, 205, 205, 0.25)',
                            borderRadius: '8px',
                            color: '#32D1A0',
                            marginLeft: 15
                          }}
                          disabled={loadingBuscar}
                        >
                          {loadingBuscar && <CircularProgress />}
                          {!loadingBuscar && 'Buscar pet'}
                        </ResponsiveButton>
                      </Box>
                    </Box>
                  </form>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    marginTop: '30px',
                    marginLeft: '51px'
                  }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography
                      variant='body1'
                      style={{
                        fontStyle: 'normal',
                        fontWeight: 500,
                        fontSize: '14px',
                        lineHeight: '20px',
                        letterSpacing: '-1px',
                        textTransform: 'uppercase',
                        color: '#AFBCD2'
                      }}
                    >
                      plano
                    </Typography>

                    <TextField
                      variant='filled'
                      disabled
                      value={handlePlanName(dadosPet?.plan?.M)}
                      inputProps={{
                        style: {
                          textAlign: 'left',
                          paddingTop: '10px' // Ajuste o valor do padding top para centralizar verticalmente o texto
                        }
                      }}
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          width: '196px',
                          height: '40px',
                          backgroundColor: '#F5F7FB',
                          boxShadow: '0px 1px 1px rgba(205, 205, 205, 0.25)',
                          borderRadius: '7px',
                          fontSize: '14px'
                        }
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginLeft: '23px'
                    }}
                  >
                    <Typography
                      variant='body1'
                      style={{
                        fontStyle: 'normal',
                        fontWeight: 500,
                        fontSize: '14px',
                        lineHeight: '20px',
                        letterSpacing: '-1px',
                        textTransform: 'uppercase',
                        color: '#AFBCD2'
                      }}
                    >
                      nome do pet
                    </Typography>

                    <TextField
                      variant='filled'
                      disabled
                      value={dadosPet?.metadata?.M?.pet_name?.S}
                      inputProps={{
                        style: {
                          textAlign: 'left',
                          paddingTop: '10px' // Ajuste o valor do padding top para centralizar verticalmente o texto
                        }
                      }}
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          width: '196px',
                          height: '40px',
                          backgroundColor: '#F5F7FB',
                          boxShadow: '0px 1px 1px rgba(205, 205, 205, 0.25)',
                          borderRadius: '7px',
                          fontSize: '14px'
                        }
                      }}
                    />
                  </Box>

                  <RegisterPetModal
                    open={openModal}
                    onClose={() => setOpenModal(false)}
                    dados={dadosPet}
                  />

                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginLeft: '23px'
                    }}
                  >
                    <Typography
                      variant='body1'
                      style={{
                        fontStyle: 'normal',
                        fontWeight: 500,
                        fontSize: '14px',
                        lineHeight: '20px',
                        letterSpacing: '-1px',
                        textTransform: 'uppercase',
                        color: '#AFBCD2'
                      }}
                    >
                      nome do cliente
                    </Typography>

                    <TextField
                      variant='filled'
                      disabled
                      value={dadosPet?.customer?.M?.name?.S?.split(' ')
                        .map((parteDoNome, index, partesDoNome) =>
                          index === 0 || index === partesDoNome.length - 1
                            ? parteDoNome
                            : ''
                        )
                        .filter(Boolean)
                        .join(' ')}
                      inputProps={{
                        style: {
                          textAlign: 'left',
                          paddingTop: '10px' // Ajuste o valor do padding top para centralizar verticalmente o texto
                        }
                      }}
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          width: '196px',
                          height: '40px',
                          backgroundColor: '#F5F7FB',
                          boxShadow: '0px 1px 1px rgba(205, 205, 205, 0.25)',
                          borderRadius: '7px',
                          fontSize: '14px'
                        }
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginLeft: '23px'
                    }}
                  >
                    <Typography
                      variant='body1'
                      style={{
                        fontStyle: 'normal',
                        fontWeight: 500,
                        fontSize: '14px',
                        lineHeight: '20px',
                        letterSpacing: '-1px',
                        textTransform: 'uppercase',
                        color: '#AFBCD2'
                      }}
                    >
                      veterinário solicitante
                      <Typography
                        style={{
                          fontWeight: 500,
                          fontSize: '16px',
                          color: '#FF0000'
                        }}
                        sx={{
                          display: 'inline',
                          marginLeft: '4px'
                        }}
                      >
                        *
                      </Typography>
                    </Typography>

                    <FormControl variant='filled'>
                      <Select
                        labelId='select-label'
                        value={formValues.vetCPF}
                        required
                        onChange={e => handleVeterinarianChange(e)}
                        inputProps={{
                          style: {
                            textAlign: 'left',
                            paddingTop: '10px'
                          }
                        }}
                        style={{
                          width: '196px',
                          height: '40px',
                          backgroundColor: '#F5F7FB',
                          boxShadow: '0px 1px 1px rgba(205, 205, 205, 0.25)',
                          borderRadius: '7px',
                          fontSize: '14px'
                        }}
                        disableUnderline
                      >
                        {veterinariansData.map(data => {
                          return (
                            <MenuItem
                              key={data?.vet_cpf?.S}
                              value={data?.vet_cpf?.S}
                            >
                              {data?.vet_name?.S}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    marginLeft: '51px',
                    marginTop: '20px',
                    marginBottom: '55px'
                  }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography
                      variant='body1'
                      style={{
                        fontStyle: 'normal',
                        fontWeight: 500,
                        fontSize: '14px',
                        lineHeight: '20px',
                        letterSpacing: '-1px',
                        textTransform: 'uppercase',
                        color: '#AFBCD2'
                      }}
                    >
                      Espécie
                    </Typography>

                    <TextField
                      variant='filled'
                      disabled
                      value={dadosPet?.metadata?.M?.pet_especie?.S}
                      inputProps={{
                        style: {
                          textAlign: 'left',
                          paddingTop: '10px' // Ajuste o valor do padding top para centralizar verticalmente o texto
                        }
                      }}
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          width: '196px',
                          height: '40px',
                          backgroundColor: '#F5F7FB',
                          boxShadow: '0px 1px 1px rgba(205, 205, 205, 0.25)',
                          borderRadius: '7px',
                          fontSize: '14px'
                        }
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginLeft: '23px'
                    }}
                  >
                    <Typography
                      variant='body1'
                      style={{
                        fontStyle: 'normal',
                        fontWeight: 500,
                        fontSize: '14px',
                        lineHeight: '20px',
                        letterSpacing: '-1px',
                        textTransform: 'uppercase',
                        color: '#AFBCD2'
                      }}
                    >
                      Raça
                    </Typography>

                    <TextField
                      variant='filled'
                      disabled
                      value={dadosPet?.metadata?.M?.pet_raca?.S}
                      inputProps={{
                        style: {
                          textAlign: 'left',
                          paddingTop: '10px' // Ajuste o valor do padding top para centralizar verticalmente o texto
                        }
                      }}
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          width: '196px',
                          height: '40px',
                          backgroundColor: '#F5F7FB',
                          boxShadow: '0px 1px 1px rgba(205, 205, 205, 0.25)',
                          borderRadius: '7px',
                          fontSize: '14px'
                        }
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginLeft: '23px'
                    }}
                  >
                    <Typography
                      variant='body1'
                      style={{
                        fontStyle: 'normal',
                        fontWeight: 500,
                        fontSize: '14px',
                        lineHeight: '20px',
                        letterSpacing: '-1px',
                        textTransform: 'uppercase',
                        color: '#AFBCD2'
                      }}
                    >
                      Data Nascimento
                    </Typography>

                    <TextField
                      variant='filled'
                      disabled
                      value={dadosPet?.metadata?.M?.pet_nascimento?.S}
                      inputProps={{
                        style: {
                          textAlign: 'left',
                          paddingTop: '10px' // Ajuste o valor do padding top para centralizar verticalmente o texto
                        }
                      }}
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          width: '196px',
                          height: '40px',
                          backgroundColor: '#F5F7FB',
                          boxShadow: '0px 1px 1px rgba(205, 205, 205, 0.25)',
                          borderRadius: '7px',
                          fontSize: '14px'
                        }
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginLeft: '23px'
                    }}
                  >
                    <Typography
                      variant='body1'
                      style={{
                        fontStyle: 'normal',
                        fontWeight: 500,
                        fontSize: '14px',
                        lineHeight: '20px',
                        letterSpacing: '-1px',
                        textTransform: 'uppercase',
                        color: '#AFBCD2'
                      }}
                    >
                      Sexo
                    </Typography>

                    <TextField
                      variant='filled'
                      disabled
                      value={dadosPet?.metadata?.M?.pet_sexo?.S}
                      inputProps={{
                        style: {
                          textAlign: 'left',
                          paddingTop: '10px' // Ajuste o valor do padding top para centralizar verticalmente o texto
                        }
                      }}
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          width: '196px',
                          height: '40px',
                          backgroundColor: '#F5F7FB',
                          boxShadow: '0px 1px 1px rgba(205, 205, 205, 0.25)',
                          borderRadius: '7px',
                          fontSize: '14px'
                        }
                      }}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    marginLeft: '51px',
                    marginTop: '20px',
                    marginBottom: '55px'
                  }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography
                      variant='body1'
                      style={{
                        fontStyle: 'normal',
                        fontWeight: 500,
                        fontSize: '14px',
                        lineHeight: '20px',
                        letterSpacing: '-1px',
                        textTransform: 'uppercase',
                        color: '#AFBCD2'
                      }}
                    >
                      peso do pet (kg)
                      <Typography
                        style={{
                          fontWeight: 500,
                          fontSize: '16px',
                          color: '#FF0000'
                        }}
                        sx={{
                          display: 'inline',
                          marginLeft: '4px'
                        }}
                      >
                        *
                      </Typography>
                    </Typography>

                    <TextField
                      variant='filled'
                      name='peso'
                      type='number'
                      value={formValues.peso}
                      onChange={handleInputChange}
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          width: '196px',
                          height: '40px',
                          backgroundColor: '#F5F7FB',
                          boxShadow: '0px 1px 1px rgba(205, 205, 205, 0.25)',
                          borderRadius: '7px',
                          fontSize: '14px'
                        },
                        inputProps: { min: 0 },
                        pattern: '[0-9]*'
                      }}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginLeft: '23px'
                    }}
                  >
                    <Typography
                      variant='body1'
                      style={{
                        fontStyle: 'normal',
                        fontWeight: 500,
                        fontSize: '14px',
                        lineHeight: '20px',
                        letterSpacing: '-1px',
                        textTransform: 'uppercase',
                        color: '#AFBCD2'
                      }}
                    >
                      Guia retroativa?
                    </Typography>

                    <Switch
                      checked={isChecked}
                      onChange={() => setIsChecked(!isChecked)}
                      inputProps={{ 'aria-label': 'guia retroativa' }}
                      color='primary'
                    />
                  </Box>

                  {isChecked ? (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginLeft: '23px'
                      }}
                    >
                      <Typography
                        variant='body1'
                        style={{
                          fontStyle: 'normal',
                          fontWeight: 500,
                          fontSize: '14px',
                          lineHeight: '20px',
                          letterSpacing: '-1px',
                          textTransform: 'uppercase',
                          color: '#AFBCD2'
                        }}
                      >
                        Data
                      </Typography>
                      <DatePicker
                        selected={selectedDate}
                        onChange={handleDateChange}
                        dateFormat='dd/MM/yyyy'
                        placeholderText='Selecione uma data'
                        wrapperClassName='date-picker-wrapper'
                        className='date-picker'
                        calendarClassName='date-picker-calendar'
                        popperClassName='date-picker-popper'
                        customInput={
                          <input
                            type='text'
                            style={{
                              textAlign: 'left',
                              paddingTop: '10px'
                            }}
                          />
                        }
                      />
                    </Box>
                  ) : null}
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    marginLeft: '51px',
                    marginTop: '20px',
                    marginBottom: '55px'
                  }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography
                      variant='body1'
                      style={{
                        fontStyle: 'normal',
                        fontWeight: 500,
                        fontSize: '14px',
                        lineHeight: '20px',
                        letterSpacing: '-1px',
                        textTransform: 'uppercase',
                        color: '#AFBCD2'
                      }}
                    >
                      laudo
                      <Typography
                        style={{
                          fontWeight: 500,
                          fontSize: '16px',
                          color: '#FF0000'
                        }}
                        sx={{
                          display: 'inline',
                          marginLeft: '4px'
                        }}
                      >
                        *
                      </Typography>
                    </Typography>

                    <TextField
                      variant='filled'
                      required
                      multiline
                      rows={5}
                      value={text}
                      onChange={e => setText(e.target.value)}
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          width: '196px',
                          backgroundColor: '#F5F7FB',
                          boxShadow: '0px 1px 1px rgba(205, 205, 205, 0.25)',
                          borderRadius: '7px',
                          fontSize: '14px'
                        }
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginLeft: '23px'
                    }}
                  >
                    <Typography
                      variant='body1'
                      style={{
                        fontStyle: 'normal',
                        fontWeight: 500,
                        fontSize: '14px',
                        lineHeight: '20px',
                        letterSpacing: '-1px',
                        textTransform: 'uppercase',
                        color: '#AFBCD2'
                      }}
                    >
                      observações
                    </Typography>

                    <TextField
                      variant='filled'
                      name='observacoes'
                      multiline
                      rows={5}
                      value={formValues.observacoes}
                      onChange={handleInputChange}
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          width: '196px',
                          backgroundColor: '#F5F7FB',
                          boxShadow: '0px 1px 1px rgba(205, 205, 205, 0.25)',
                          borderRadius: '7px',
                          fontSize: '14px'
                        }
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginLeft: '23px'
                    }}
                  >
                    {/* <Box display='flex' alignItems='center'> */}
                    <Typography
                      variant='body1'
                      style={{
                        fontStyle: 'normal',
                        fontWeight: 500,
                        fontSize: '14px',
                        lineHeight: '20px',
                        letterSpacing: '-1px',
                        textTransform: 'uppercase',
                        color: '#AFBCD2'
                      }}
                    >
                      Anexos (se houver):
                    </Typography>

                    <Box
                      sx={{
                        position: 'relative',
                        overflow: 'hidden',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        width: '196px',
                        height: '40px',
                        background: '#F5F7FB',
                        boxShadow: '0px 1px 1px rgba(205, 205, 205, 0.25)',
                        borderRadius: '7px',
                        textAlign: 'right'
                      }}
                    >
                      <Input
                        id='upload-file'
                        type='file'
                        // multiple
                        onChange={handleUploadFile}
                        sx={{
                          // width: '100%',
                          // height: '100%',
                          // zIndex: 1
                          opacity: 0,
                          width: '100%',
                          height: '100%',
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          cursor: 'pointer'
                        }}
                        inputProps={{
                          multiple: true,
                          'aria-label': 'upload file',
                          style: {
                            width: '100%',
                            height: '100%',
                            opacity: 0
                          }
                        }}
                      />

                      <AttachFile
                        color='primary'
                        sx={{ position: 'absolute', zIndex: 0 }}
                      />
                      {/* </Box> */}
                    </Box>

                    {inputFiles?.length > 0 && (
                      <Box>
                        {inputFiles?.map((file, index) => (
                          <Box key={index} display='flex' alignItems='center'>
                            <Typography
                              style={{ color: '#9FA9C4', marginLeft: '10px' }}
                            >
                              {file.name}
                            </Typography>
                            <IconButton
                              aria-label={`Remover arquivo ${index + 1}`}
                              onClick={() => handleRemoveFile(index)}
                              sx={{ marginLeft: '10px' }}
                            >
                              <Delete fontSize='small' />
                            </IconButton>
                          </Box>
                        ))}
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box
                display='flex'
                flexDirection='column'
                height='100%'
                sx={{ backgroundColor: '#FFFFFF' }}
                marginX={2}
                paddingX={4}
                paddingY={4}
                borderRadius='20px'
                gap={2}
              >
                <Typography
                  variant='body1'
                  style={{
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: '22px',
                    lineHeight: '14px',
                    letterSpacing: '-1px',
                    textTransform: 'uppercase',
                    color: '#32D1A0',
                    marginTop: '18px',
                    marginLeft: '51px'
                  }}
                >
                  procedimentos
                </Typography>

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    marginLeft: '51px',
                    marginTop: '20px'
                  }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography
                      variant='body1'
                      style={{
                        fontStyle: 'normal',
                        fontWeight: 500,
                        fontSize: '14px',
                        lineHeight: '20px',
                        letterSpacing: '-1px',
                        textTransform: 'uppercase',
                        color: '#AFBCD2'
                      }}
                    >
                      grupo
                    </Typography>

                    <Select
                      value={valorSelecionado}
                      onChange={handleSelectChange}
                      sx={{
                        width: '224px',
                        height: '40px',
                        background: '#F5F7FB',
                        boxShadow: '0px 1px 1px rgba(205, 205, 205, 0.25)',
                        borderRadius: '7px'
                      }}
                      InputLabelProps={{ style: { display: 'none' } }}
                    >
                      {valoresUnicos
                        ?.sort((a, b) => a.localeCompare(b))
                        .map(valor => (
                          <MenuItem key={valor} value={valor}>
                            {valor}
                          </MenuItem>
                        ))}
                    </Select>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginLeft: '23px'
                    }}
                  >
                    <Typography
                      variant='body1'
                      style={{
                        fontStyle: 'normal',
                        fontWeight: 500,
                        fontSize: '14px',
                        lineHeight: '20px',
                        letterSpacing: '-1px',
                        textTransform: 'uppercase',
                        color: '#AFBCD2'
                      }}
                    >
                      procedimento
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                      <Select
                        sx={{
                          width: '224px',
                          height: '40px',
                          background: '#F5F7FB',
                          boxShadow: '0px 1px 1px rgba(205, 205, 205, 0.25)',
                          borderRadius: '7px'
                        }}
                        InputLabelProps={{ style: { display: 'none' } }}
                        value={procSelecionado.nome || ''}
                        onChange={handleSelectProcChange}
                      >
                        {tipoAtendimento === 'emergencia'
                          ? procedimentos
                              ?.filter(
                                proc => proc?.grupo?.S === valorSelecionado
                              )
                              .filter(proc => proc?.emergencial?.S === 'true')
                              .sort((a, b) => a.nome.S.localeCompare(b.nome.S)) // Ordenar em ordem alfabética
                              .map(valor => (
                                <MenuItem
                                  key={valor?.id?.S}
                                  value={valor?.nome?.S}
                                >
                                  {valor?.nome?.S}
                                </MenuItem>
                              ))
                          : procedimentos
                              ?.filter(
                                proc => proc?.grupo?.S === valorSelecionado
                              )
                              .sort((a, b) => a.nome.S.localeCompare(b.nome.S)) // Ordenar em ordem alfabética
                              .map(valor => (
                                <MenuItem
                                  key={valor?.id?.S}
                                  value={valor?.nome?.S}
                                >
                                  {valor?.nome?.S}
                                </MenuItem>
                              ))}
                      </Select>
                      <ResponsiveButton
                        variant='contained'
                        color='primary'
                        disableElevation
                        style={{
                          height: '40px',
                          background: '#FFFFFF',
                          border: '1px solid #32D1A0',
                          boxShadow: '0px 1px 1px rgba(205, 205, 205, 0.25)',
                          borderRadius: '8px',
                          color: '#32D1A0',
                          marginLeft: 15
                        }}
                        onClick={addFator}
                      >
                        incluir procedimento
                      </ResponsiveButton>
                    </Box>
                  </Box>
                </Box>

                <TableContainer>
                  <Table style={{ width: '100%', marginTop: '12px' }}>
                    <TableHead style={{ display: 'table-header-group' }}>
                      <TableRow>
                        <TableCell
                          align='center'
                          style={{ verticalAlign: 'middle' }}
                        >
                          <Typography
                            variant='body1'
                            style={{
                              fontStyle: 'normal',
                              fontWeight: 500,
                              fontSize: '14px',
                              lineHeight: '20px',
                              letterSpacing: '-1px',
                              textTransform: 'uppercase',
                              color: '#AFBCD2'
                            }}
                          >
                            ID
                          </Typography>
                        </TableCell>
                        <TableCell
                          align='center'
                          style={{ verticalAlign: 'middle' }}
                        >
                          <Typography
                            variant='body1'
                            style={{
                              fontStyle: 'normal',
                              fontWeight: 500,
                              fontSize: '14px',
                              lineHeight: '20px',
                              letterSpacing: '-1px',
                              textTransform: 'uppercase',
                              color: '#AFBCD2'
                            }}
                          >
                            nome
                          </Typography>
                        </TableCell>
                        <TableCell
                          align='center'
                          style={{ verticalAlign: 'middle' }}
                        ></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dadosTabela.map((dados, index) => (
                        <TableRow key={`${dados.id}-${index}`}>
                          <TableCell align='center'>{dados.id}</TableCell>
                          <TableCell align='center'>{dados.nome}</TableCell>
                          <TableCell align='center'>
                            {/* Adicione o botão de "retirar" com a função de remoção correspondente */}
                            <ResponsiveButton
                              variant='contained'
                              color='primary'
                              disableElevation
                              style={{
                                height: '40px',
                                background: '#FFFFFF',
                                border: '1px solid #FF0000',
                                boxShadow:
                                  '0px 1px 1px rgba(205, 205, 205, 0.25)',
                                borderRadius: '8px',
                                color: '#FF0000',
                                marginLeft: 15
                              }}
                              onClick={() => removeFator(index)} // Substitua "removeFator" pela sua função de remoção
                            >
                              Retirar
                            </ResponsiveButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Modal
                  open={openModalUpload}
                  onClose={() => handleModalUpload(false)}
                  aria-labelledby='modal-modal-title'
                  aria-describedby='modal-modal-description'
                >
                  <Box sx={style}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center'
                      }}
                    >
                      <Typography variant='h4' component='h2'>
                        Guia emergencial
                      </Typography>
                      <Typography variant='subtitle1' component='h3'>
                        Essa guia será analisada pela equipe de auditoria e pode
                        ser glosada
                      </Typography>
                      <ResponsiveButton
                        variant='contained'
                        color='primary'
                        disableElevation
                        disabled={loadingCreate}
                        style={{
                          height: '40px',
                          background: '#32D1A0',
                          border: '1px solid #32D1A0',
                          boxShadow: '0px 1px 1px rgba(205, 205, 205, 0.25)',
                          borderRadius: '8px',
                          color: '#FFFFFF',
                          marginTop: '2%'
                        }}
                        onClick={() => handleEmitirEmergencial(dadosTabela)}
                      >
                        {loadingCreate && <CircularProgress size={20} />}
                        {!loadingCreate && 'Emitir nova guia'}
                      </ResponsiveButton>
                    </Box>
                  </Box>
                </Modal>
                {dadosTabela.length > 0 ? (
                  <ResponsiveButton
                    variant='contained'
                    color='primary'
                    disableElevation
                    disabled={loadingCreate}
                    style={{
                      height: '40px',
                      background: '#32D1A0',
                      border: '1px solid #32D1A0',
                      boxShadow: '0px 1px 1px rgba(205, 205, 205, 0.25)',
                      borderRadius: '8px',
                      color: '#FFFFFF',
                      marginTop: '2%'
                    }}
                    onClick={() => handleEmitir(dadosTabela)}
                  >
                    {loadingCreate && <CircularProgress size={20} />}
                    {!loadingCreate && 'Emitir nova guia'}
                  </ResponsiveButton>
                ) : null}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Footer />
    </DashboardLayout>
  )
}

export default GenerateGuide
