import { BrowserRouter as Router } from "react-router-dom";

//import { useMediaQuery } from "react-responsive";

import { CssBaseline } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { ListUsersProvider } from "./context/UserContext";
import { MaterialUIControllerProvider } from "./context/MUIContext";

import ApplicationRoutes from "./routes/routes";

import "dayjs/locale/pt-br";
import "./App.css";

/* const isMobileDevice = useMediaQuery({
  query: "(min-device-width: 480px)",
});

const isTabletDevice = useMediaQuery({
  query: "(min-device-width: 768px)",
});

const isLaptop = useMediaQuery({
  query: "(min-device-width: 1024px)",
});

const isDesktop = useMediaQuery({
  query: "(min-device-width: 1200px)",
});

const isBigScreen = useMediaQuery({
  query: "(min-device-width: 1201px )",
}); */

function App() {
  return (
    <MaterialUIControllerProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
        <Router>
          <ListUsersProvider>
            <CssBaseline />
            <ApplicationRoutes />
          </ListUsersProvider>
        </Router>
      </LocalizationProvider>
    </MaterialUIControllerProvider>
  );
}

export default App;
