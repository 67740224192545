// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui components
import { Box, Grid } from "@mui/material";

import PageLayout from "../PageLayout/PageLayout";

function BasicLayout({ children, xs = 11, sm = 9, md = 5, lg = 4, xl = 3 }) {
  return (
    <PageLayout>
      <Box
        position="absolute"
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundColor: "#32D1A0",
        }}
      />

      <Box px={1} width="100%" height="100vh" mx="auto">
        <Grid
          container
          spacing={1}
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
            {children}
          </Grid>
        </Grid>
      </Box>
    </PageLayout>
  );
}

// Typechecking props for the BasicLayout
BasicLayout.propTypes = {
  image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default BasicLayout;
