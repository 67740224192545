import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Menu, MenuItem, Button, Box, Typography } from "@mui/material";

import { useUsersContext } from "../../context/UserContext";
import { updateGuideInfo } from "../../utils/axios";

const ActionButton = ({ guide }) => {
  const {
    getRow,
    refresh,
    changeRefresh,
    handleErrorData,
    handleErrorModalState,
  } = useUsersContext();

  const navigation = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElCancel, setAnchorElCancel] = useState(null);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOpenMenuCancel = (event) => {
    setAnchorElCancel(event.currentTarget);
  };

  const handleOpenGuia = (guide) => {
    getRow(guide);
    navigation(`/pro/guia/${guide.id}`);
    setAnchorEl(null);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleCloseMenuCancel = () => {
    setAnchorElCancel(null);
  };

  async function handleUpdateGuideInfo(guide, reason) {
    const { data, status } = await updateGuideInfo(
      guide?.id,
      guide?.microchip_number,
      { motivo_canc: reason },
      "status",
      "cancelada"
    );

    if (status !== 200) {
      handleErrorData({
        title: "Erro ao carregar os dados",
        message: data?.message,
      });
      handleErrorModalState(true);

      return;
    }

    changeRefresh(!refresh);

    handleErrorData({
      title: "Atualização concluída!",
      message: "Guia cancelada com sucesso.",
      type: "success",
    });
    handleErrorModalState(true);
  }

  return (
    <Box>
      <Button
        onClick={handleOpenMenu}
        fullWidth
        variant="contained"
        color="secondary"
        sx={{
          width: "80px",
          height: "34px",
          borderRadius: "5px",
        }}
      >
        <Typography
          variant="action"
          textTransform="uppercase"
          color="secondary"
        >
          ações
        </Typography>
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={() => handleOpenGuia(guide)}>Ver Guia</MenuItem>
        <MenuItem
          onClick={(e) => {
            handleOpenMenuCancel(e);
            handleCloseMenu();
          }}
        >
          Cancelar
        </MenuItem>
      </Menu>

      <Menu
        anchorEl={anchorElCancel}
        open={Boolean(anchorElCancel)}
        onClose={handleCloseMenuCancel}
      >
        <MenuItem
          onClick={() => handleUpdateGuideInfo(guide, "Requisição Tutor")}
        >
          Requisição Tutor
        </MenuItem>
        <MenuItem
          onClick={() => handleUpdateGuideInfo(guide, "Erro na emissão")}
        >
          Erro na emissão
        </MenuItem>
        <MenuItem
          onClick={() => handleUpdateGuideInfo(guide, "Problema na Coleta")}
        >
          Problema na Coleta
        </MenuItem>
        <MenuItem
          onClick={() =>
            handleUpdateGuideInfo(guide, "Procedimento não realizado")
          }
        >
          Procedimento não realizado
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default ActionButton;
