// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui components
import { Box, Typography, Icon } from "@mui/material";

// @mui contexts
import { useMaterialUIController } from "../../context/MUIContext";

function DataTableHeadCell({
  width,
  children,
  sorted,
  align,
  header,
  ...rest
}) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <Box
      component="th"
      width={width}
      py={1.5}
      px={3}
      sx={({ palette: { gray }, borders: { borderWidth } }) => ({
        borderBottom: `${borderWidth[1]} solid ${gray.main}`,
      })}
    >
      <Box
        {...rest}
        position="relative"
        display="flex"
        justifyContent="space-around"
        textAlign={align}
        color={darkMode ? "white" : "secondary"}
        sx={{
          cursor: sorted && "pointer",
          userSelect: sorted && "none",
        }}
      >
        <Typography
          display="flex"
          justifyContent="flex-start"
          width="100%"
          alignItems="center"
          textTransform="uppercase"
          variant="tableHeader"
          color="primary.light"
          sx={{
            cursor: sorted && "pointer",
            userSelect: sorted && "none",
          }}
        >
          {children}
        </Typography>
        {sorted && header !== "ações" && (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            sx={({ typography: { size } }) => ({
              fontSize: size.lg,
            })}
          >
            <Box display="flex" flexDirection="column" justifyContent="center">
              <Icon
                sx={{
                  color: sorted === "asce" ? "text" : "white",
                  opacity: sorted === "asce" ? 1 : 0.5,
                }}
              >
                arrow_drop_up
              </Icon>
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="center">
              <Icon
                sx={{
                  color: sorted === "desc" ? "text" : "white",
                  opacity: sorted === "desc" ? 1 : 0.5,
                }}
              >
                arrow_drop_down
              </Icon>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

// Setting default values for the props of DataTableHeadCell
DataTableHeadCell.defaultProps = {
  width: "auto",
  sorted: "none",
  align: "left",
};

// Typechecking props for the DataTableHeadCell
DataTableHeadCell.propTypes = {
  align: PropTypes.oneOf(["left", "right", "center"]),
  children: PropTypes.node.isRequired,
  sorted: PropTypes.oneOf([false, "none", "asce", "desc"]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default DataTableHeadCell;
