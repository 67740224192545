import axios from "axios";
require("dotenv").config({ path: require("find-config")(".env") });

const awsResponseError = {
  status: 500,
  data: { message: "Erro na requisição. Tente novamente" },
}; // This is a custom error message

export async function validateCNPJ(cnpj) {
  const api = `http://ws.hubdodesenvolvedor.com.br/v2/cnpj/?cnpj=${cnpj}&token=${process.env.REACT_APP_HUB_WS}`;

  try {
    const response = await axios.get(api, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    return response?.data;
  } catch (error) {
    console.error("🚀 ~ file: axios.js:334 ~ getNetworkData ~ error:", error);
    return error;
  }
}

export async function validateCPF(cpf) {
  const api = `https://ws.hubdodesenvolvedor.com.br/v2/cpf/?cpf=${cpf}&token=${process.env.REACT_APP_HUB_WS}`;

  try {
    const response = await axios.get(api, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    return response?.data;
  } catch (error) {
    console.error("🚀 ~ file: axios.js:36 ~ validateCPF ~ error:", error);
    return error;
  }
}

export async function createApplicationsLog(body) {
  const api = `https://q7amrtd8t8.execute-api.us-east-1.amazonaws.com/dev/logs/applications_logs`;

  try {
    const response = await axios.post(api, body, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    return response;
  } catch (error) {
    console.error("🚀 ~ file: axios.js:334 ~ getNetworkData ~ error:", error);
    return error;
  }
}

export async function getNotifications(cnpj) {
  const api = `https://q7amrtd8t8.execute-api.us-east-1.amazonaws.com/dev/notifications_web/get_by_params?cnpj=${cnpj}&viewed=false`;

  try {
    const response = await axios.get(api, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return response;
  } catch (error) {
    if (!error.response) {
      return awsResponseError;
    }

    return error.response;
  }
}

export async function updateNotificationsViewed(notifications) {
  const api = `https://q7amrtd8t8.execute-api.us-east-1.amazonaws.com/dev/notifications_web/update_viewed`;

  try {
    const response = await axios.put(api, notifications, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    return response;
  } catch (error) {
    if (!error.response) {
      return awsResponseError;
    }

    return error.response;
  }
}

export async function getSaldoTable(cnpj) {

  const api = `https://q7amrtd8t8.execute-api.us-east-1.amazonaws.com/dev/autorizador/auditoria?params=id&values=${cnpj}`;

  try {
    const response = await axios.get(api, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    return response;
  } catch (error) {
    if (!error.response) {
      return awsResponseError;
    }

    return error.response;
  }
}

export async function getIndicationsExtractTable(cnpj) {
  const api = `https://q7amrtd8t8.execute-api.us-east-1.amazonaws.com/dev/autorizador/auditoria/indications?params=id&values=${cnpj}`;

  try {
    const response = await axios.get(api, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return response;
  } catch (error) {
    if (!error.response) {
      return awsResponseError;
    }

    return error.response;
  }
}

export async function getIndicationsTable(cnpj) {
  const api = `https://q7amrtd8t8.execute-api.us-east-1.amazonaws.com/dev/autorizador/auditoria/indications?params=indications&values=${cnpj}`;

  try {
    const response = await axios.get(api, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return response;
  } catch (error) {
    if (!error.response) {
      return awsResponseError;
    }

    return error.response;
  }
}

export async function downloadCsvRecebimentos(cnpj, date) {
  const api = `https://q7amrtd8t8.execute-api.us-east-1.amazonaws.com/dev/autorizador/generate_csv?cnpj=${cnpj}&month_year=${date}`;

  try {
    const response = await axios.get(api, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    return response;
  } catch (error) {
    if (!error.response) {
      return awsResponseError;
    }

    return error.response;
  }
}

export async function downloadAnnexDefense(cnpj) {
  // const api = `https://q7amrtd8t8.execute-api.us-east-1.amazonaws.com/dev/autorizador/generate_csv?params=${cnpj}`;
  // try {
  //   const response = await axios.get(api, {
  //     headers: {
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //     },
  //   });
  //   return response;
  // } catch (error) {
  //   if (!error.response) {
  //     return awsResponseError;
  //   }
  //   return error.response;
  // }
}

// lambda trigger_get_guias_params
export async function getGuides(cnpj, lab) {
  const todayDateTime = new Date().getTime();

  const api = `https://q7amrtd8t8.execute-api.us-east-1.amazonaws.com/dev/autorizador/get_guias_pro?params=${cnpj}&lab=${lab}&timestamp=${todayDateTime}`;

  try {
    const response = await axios.get(api, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return response;
  } catch (error) {
    if (!error.response) {
      return awsResponseError;
    }

    return error.response;
  }
}

export async function downloadPdfGuide(guia, microchip) {
  const api = `https://q7amrtd8t8.execute-api.us-east-1.amazonaws.com/dev/autorizador/guias/generate_infos?params=${guia}&classificacao=${microchip}`;

  try {
    const response = await axios.get(api, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    return response;
  } catch (error) {
    if (!error.response) {
      return awsResponseError;
    }

    return error.response;
  }
}

// lambda trigger_update_infos_pro
export async function updateInfosPro(body, params, cnpj = "") {
  if (!body) {
    return {
      status: 500,
      data: { message: "Requisição sem 'body', tente novamente" },
    };
  }

  if (!params) {
    return {
      status: 500,
      data: { message: "Requisição sem 'parâmetros', tente novamente" },
    };
  }

  const values = cnpj ? `&values=${cnpj}` : "";
  const api = `https://q7amrtd8t8.execute-api.us-east-1.amazonaws.com/dev/autorizador/get_user_pro/update_infos?params=${params}${values}`;
  const data = body;

  try {
    const response = await axios.post(
      api,
      { data },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );

    return response;
  } catch (error) {
    if (!error.response) {
      return awsResponseError;
    }

    return error.response;
  }
}

export async function getUserProByParams(params, values) {
  const api = `https://q7amrtd8t8.execute-api.us-east-1.amazonaws.com/dev/autorizador/get_user_pro/by_params?params=${params}&values=${values}`;

  try {
    const response = await axios.get(api, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    return response;
  } catch (error) {
    if (!error.response) {
      return awsResponseError;
    }

    return error.response;
  }
}

export async function getVeterinarians(cnpj) {
  const api = `https://q7amrtd8t8.execute-api.us-east-1.amazonaws.com/dev/autorizador/get_veterinarians?params=clinica_cnpj&values=${cnpj}`;

  try {
    const response = await axios.get(api, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    return response;
  } catch (error) {
    if (!error.response) {
      return awsResponseError;
    }

    return error.response;
  }
}

export async function createUserWithRandomPassword(email, password, cnpj) {
  const api =
    "https://q7amrtd8t8.execute-api.us-east-1.amazonaws.com/dev/autorizador/create_veterinarians";

  try {
    const response = await axios.post(
      api,
      { email, password, cnpj },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );

    return response;
  } catch (error) {
    if (!error.response) {
      return awsResponseError;
    }

    return error.response;
  }
}

export async function updateDefense(body) {
  const api = `https://q7amrtd8t8.execute-api.us-east-1.amazonaws.com/dev/autorizador/auditoria/update_defense`;

  try {
    const response = await axios.put(
      api,
      { ...body },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );

    return response;
  } catch (error) {
    if (!error.response) {
      return awsResponseError;
    }

    return error.response;
  }
}

export async function getProcedures() {
  const api =
    "https://q7amrtd8t8.execute-api.us-east-1.amazonaws.com/dev/autorizador/procedures";

  try {
    const response = await axios.get(api, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    return response;
  } catch (error) {
    return error;
  }
}

export async function getVindiBillByParams(billID) {
  const api = `https://q7amrtd8t8.execute-api.us-east-1.amazonaws.com/dev/autorizador/bills?id=${billID}`;

  try {
    const response = await axios.get(api, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    return response;
  } catch (error) {
    if (!error.response) {
      return awsResponseError;
    }

    return error.response;
  }
}

// lambda trigger_update_guias_operational
export async function updateGuideInfo(
  guideNumber,
  microchipNumber,
  body,
  params,
  values
) {
  const api = `https://q7amrtd8t8.execute-api.us-east-1.amazonaws.com/dev/autorizador/guias/update_info?params=${params}&values=${values}`;

  try {
    const response = await axios.post(
      api,
      { numeroGuia: guideNumber, microchipNumber, ...body },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    

    return response;
  } catch (error) {
    
    return error;
  }
}

export async function deleteBillByParams(billID) {
  const api = `https://q7amrtd8t8.execute-api.us-east-1.amazonaws.com/dev/vindi/bills?id=${billID}`;

  try {
    const response = await axios.delete(api, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    return response;
  } catch (error) {
    return error;
  }
}

export async function getAlerts() {
  const api = `https://q7amrtd8t8.execute-api.us-east-1.amazonaws.com/dev/notifications/alerts?table=${'table_alerts'}`;

  try {
    const response = await axios.get(api, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    return response;
  } catch (error) {
    console.error("🚀 ~ file: axios.js:440 ~ getAlerts ~ error:", error);
    return error;
  }
}