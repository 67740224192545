// @mui components
import { Button } from "@mui/material";

// Generic Components
import { TableRowField } from "../../../components"

export default function VeterinariansTableData({
  veterinariansData,
  handleDescativeVeterinarian,
}) {
  if (veterinariansData.length === 0) return { columns: [], rows: [] };

  function createData(name, CPF, CRMV, state, active, createdDate) {
    return {
      name,
      CPF,
      CRMV,
      state,
      active,
      createdDate,
    };
  }

  const rows = veterinariansData?.map((result) => {
    return (
      result &&
      createData(
        result.vet_name?.S,
        result.vet_cpf?.S,
        result.vet_crmv?.S,
        result.vet_state?.S,
        result.vet_ativo?.BOOL,
        result.created_at?.S
      )
    );
  });

  let formattedRows = [];

  rows.map((user, index) => {
    return (formattedRows[index] = {
      name: <TableRowField value={user?.name} />,
      CPF: <TableRowField value={user?.CPF} />,
      CRMV: <TableRowField value={user?.CRMV} />,
      state: <TableRowField value={user?.state} />,
      created_at: <TableRowField value={user?.created_at} date={true} />,
      acoes: (
        <Button
          variant="contained"
          color={user?.active ? "error" : "success"}
          onClick={() => handleDescativeVeterinarian(user?.CPF, user?.active)}
        >
          {user?.active ? "Desativar" : "Ativar"}
        </Button>
      ),
    });
  });

  return {
    columns: [
      { Header: "Nome", accessor: "name", width: "25%", align: "left" },
      { Header: "CPF", accessor: "CPF", align: "left" },
      { Header: "CRMV", accessor: "CRMV", align: "left" },
      { Header: "Estado", accessor: "state", width: "10%", align: "center" },
      { Header: "ações", accessor: "acoes", width: "5%", align: "center" },
    ],

    rows: formattedRows,
  };
}
