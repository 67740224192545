import { NotificationsNone } from "@mui/icons-material";
import { Badge, IconButton, Tooltip } from "@mui/material";
import NotificationsMenu from "./NotificationsMenu";
import { useState } from "react";

function NotificationsIcon({
  notifications,
  asyncGetNotifications,
}) {
  const [openMenuNotifications, setOpenMenuNotifications] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  function handleOpenNotificationsMenu(event) {
    setAnchorEl(event.currentTarget);
    setOpenMenuNotifications(true);
  }

  function handleCloseNotificationsMenu() {
    setOpenMenuNotifications(false);
  }

  const newNotifications = `Você possui ${notifications.length} novas notificações`;
  const noNotifications = "Você não possui novas notificações";

  return (
    <>
      <Tooltip
        title={notifications.length > 0 ? newNotifications : noNotifications}
        placement="bottom"
      >
        <IconButton
          onClick={
            notifications.length > 0 ? handleOpenNotificationsMenu : null
          }
          anchorEl={anchorEl}
          sx={{
            background: "#FFFFFF",
            boxShadow: "0px 1px 1px 0px rgba(205, 205, 205, 0.25);",
            borderRadius: "15px",
            width: "42px",
            height: "42px",
          }}
        >
          <Badge
            badgeContent={notifications.length}
            max={99}
            sx={{
              "& .MuiBadge-badge": {
                color: "#FFFFFF",
                backgroundColor: "#ff0000",
              },
            }}
          >
            <NotificationsNone fontSize="medium" color="primary" />
          </Badge>
        </IconButton>
      </Tooltip>
      <NotificationsMenu
        anchorEl={anchorEl}
        handleClose={handleCloseNotificationsMenu}
        open={openMenuNotifications}
        notifications={notifications}
        asyncGetNotifications={asyncGetNotifications}
      />
    </>
  );
}

export default NotificationsIcon;
