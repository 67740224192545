export function formatPrice(price = 0, lang = "pt-BR", currency = "BRL") {
  const numberPrice = Number(price);

  const cashFlowNowFormatted = numberPrice.toLocaleString(lang, {
    style: "currency",
    currency: currency,
  });

  return cashFlowNowFormatted;
}
