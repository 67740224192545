// react hooks
import { useState } from "react";

// react-router-dom hooks and components
import { Link, useNavigate } from "react-router-dom";

// formik hooks and components
import { useFormik } from "formik";

// yup for form validation
import * as Yup from "yup";

// AWS SDK
import * as AWS from "aws-sdk";

// @mui components
import {
  InputAdornment,
  Typography,
  Card,
  Box,
  TextField,
  Button,
} from "@mui/material";

// @mui icons
import { Mail } from "@mui/icons-material";

// @mui styles

import { useUsersContext } from "../../context/UserContext";

// Application Users context
import { BasicLayout } from "../../components";

// Specific components
import { ResetPassword, ConfirmPassword } from "./Cognito";

// utils
import FormLoadingComponent from "../../utils/FormLoading";

// Images
import LifepetLogo from "../../assets/images/lifepet-pro.png";
// import icon from '../Empresas/assets/images/lifepetPro.png'

require("dotenv").config({ path: require("find-config")(".env") });

const defaultValues = {
  nomeEmpresa: "",
  cnpj: "",
  endereco: "",
  nomeRepresentante: "",
  cpf: "",
  telefone: "",
  email: "",
  password: "",
};

const defaultCodigo = {
  codigo: '',
  senha: ''
}

export default function RecoverPassword() {
  const { changeLoadingPage, verifyB2B,
    getVerifyB2B, } = useUsersContext();


  const formSchema = Yup.object().shape({
    email: Yup.string().email("E-mail inválido").required("Obrigatório"),
  });
  const [formCodigo, setFormCodigo] = useState(defaultCodigo)

  let navigate = useNavigate()

  const handleSubmit = event => {
    changeLoadingPage(true)
    event.preventDefault()
    console.log(formCodigo)
    ConfirmPassword(formik.values.email, formCodigo.codigo, formCodigo.senha)
    getVerifyB2B(false)
    changeLoadingPage(false)
    navigate('/')
  }

  const handleInputCodigo = e => {
    const { name, value } = e.target
    setFormCodigo({
      ...formCodigo,
      [name]: value
    })
  }

  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema: formSchema,
    onSubmit: (values) => {
      changeLoadingPage(true);
      setTimeout(() => {
        ResetPassword(values.email);
        formik.setSubmitting(false);
        changeLoadingPage(false);
        getVerifyB2B(true);
      }, 3000);
    },
  });

  return (
    <BasicLayout >
      {verifyB2B ? (
        <form onSubmitCapture={handleSubmit}>
          <Card sx={{ marginBottom: '10%' }}>
            <Box
              variant='gradient'
              bgColor='#fff'
              borderRadius='lg'
              coloredShadow='success'
              mx={2}
              mt={-3}
              p={3}
              mb={1}
              textAlign='center'
            >
              <img alt="Logo Lifepet Pro" width="40%" src={LifepetLogo} />
            </Box>
            <Box pt={4} pb={3} px={3}>
              <Box component='form' role='form'>
                <Box mb={2}>
                  <TextField
                    id='codigo'
                    name='codigo'
                    label='Código recebido por e-mail'
                    type='text'
                    value={formCodigo.codigo}
                    onChange={handleInputCodigo}
                    required
                    fullWidth
                  />
                </Box>

                <Box mb={2}>
                  <Typography
                    style={{
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "#7e899c",
                    }}
                  >
                    Para redefinir sua senha, atente-se aos seguintes requisitos:
                    <Typography
                      style={{
                      fontWeight: 500,
                      fontSize: "12px",
                      color: "#7e899c",
                    }}
                    >
                        - Sua nova senha deve conter pelo menos 8 caratcteres.</Typography>
                    <Typography
                      style={{
                      fontWeight: 500,
                      fontSize: "12px",
                      color: "#7e899c",
                    }}
                    >- Sua nova senha deve conter número, letra e caratcterer especial.</Typography>
                  </Typography>
                </Box>

                <Box mb={2}>
                  <TextField
                    id='senha'
                    name='senha'
                    label='Nova senha'
                    type='text'
                    value={formCodigo.senha}
                    onChange={handleInputCodigo}
                    required
                    fullWidth
                  />
                </Box>

                <Box mt={4} mb={1}>
                  <Button
                    variant='gradient'
                    color='info'
                    type='submit'
                    fullWidth
                  >
                    confirmar
                  </Button>
                </Box>
              </Box>
            </Box>
          </Card>
        </form>
      ) : (
        <form onSubmitCapture={formik.handleSubmit}>
        {formik.isSubmitting ? (
          <FormLoadingComponent />
        ) : (
          <Card sx={{ marginBottom: "10%" }}>
            <Box textAlign="center" mt={2}>
              <img src={LifepetLogo} alt="Logo Lifepet Pro" width="40%" />
            </Box>

            <Box pt={4} pb={3} px={3}>
              <Box component="form" role="form">
                <Box mb={2}>
                  <TextField
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Email"
                    fullWidth
                    autoComplete="email"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Mail />
                        </InputAdornment>
                      ),
                    }}
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </Box>

                <Box mt={4} mb={1} >
                  <Button variant="contained" type='submit' fullWidth>
                    enviar
                  </Button>
                </Box>

                <Box mt={3} mb={1} textAlign="center">
                  <Typography variant="button" color="text">
                    Quer retornar?
                    <Typography
                      component={Link}
                      to="/"
                      variant="button"
                      color="primary"
                      fontWeight="medium"
                      marginLeft={1}
                    >
                      Faça o login
                    </Typography>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Card>
        )}
      </form>
      )}
    </BasicLayout>
    // <BasicLayout>
    //   <form onSubmitCapture={formik.handleSubmit}>
    //     {formik.isSubmitting ? (
    //       <FormLoadingComponent />
    //     ) : (
    //       <Card sx={{ marginBottom: "10%" }}>
    //         <Box textAlign="center" mt={2}>
    //           <img src={LifepetLogo} alt="Logo Lifepet Pro" width="40%" />
    //         </Box>

    //         <Box pt={4} pb={3} px={3}>
    //           <Box component="form" role="form">
    //             <Box mb={2}>
    //               <TextField
    //                 id="email"
    //                 name="email"
    //                 type="email"
    //                 placeholder="Email"
    //                 fullWidth
    //                 autoComplete="email"
    //                 InputProps={{
    //                   startAdornment: (
    //                     <InputAdornment position="start">
    //                       <Mail />
    //                     </InputAdornment>
    //                   ),
    //                 }}
    //                 onChange={formik.handleChange}
    //                 value={formik.values.email}
    //                 error={formik.touched.email && Boolean(formik.errors.email)}
    //                 helperText={formik.touched.email && formik.errors.email}
    //               />
    //             </Box>

    //             <Box mt={4} mb={1} >
    //               <Button variant="contained" type='submit' fullWidth>
    //                 enviar
    //               </Button>
    //             </Box>

    //             <Box mt={3} mb={1} textAlign="center">
    //               <Typography variant="button" color="text">
    //                 Quer retornar?
    //                 <Typography
    //                   component={Link}
    //                   to="/"
    //                   variant="button"
    //                   color="primary"
    //                   fontWeight="medium"
    //                   marginLeft={1}
    //                 >
    //                   Faça o login
    //                 </Typography>
    //               </Typography>
    //             </Box>
    //           </Box>
    //         </Box>
    //       </Card>
    //     )}
    //   </form>
    // </BasicLayout>
  );
}
