import { Box, Button, Modal, Typography } from "@mui/material";
import { CheckCircle, Warning } from "@mui/icons-material";

export default function ErrorModal({
  errorData: { title, message, type = "error" },
  errorModalState,
  handleErrorModalState,
}) {
  return (
    <Modal open={errorModalState} onClose={() => handleErrorModalState(false)}>
      <Box
        position="absolute"
        display="flex"
        flexDirection="column"
        padding={{ xs: 4, md: 8 }}
        gap={4}
        top="50%"
        left="50%"
        width={{ xs: "90%", lg: "80%", xl: "70%" }}
        boxShadow={1}
        sx={{
          backgroundColor: "#FFFFFF",
          borderRadius: "7px",
          transform: "translate(-50%, -50%)",
        }}
      >
        {title && (
          <Box display="flex" alignItems="center" gap={2}>
            {type === "error" && (
              <Warning data-cy="error-icon" fontSize="large" color="warning" />
            )}
            {type === "success" && (
              <CheckCircle
                data-cy="success-icon"
                fontSize="large"
                color="success"
              />
            )}
            <Typography variant="title" color="black" fontWeight={600}>
              {title}
            </Typography>
          </Box>
        )}
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography
            variant="title"
            fontSize="18px"
            fontWeight="500"
            color="text.light"
          >
            {message}
          </Typography>
        </Box>

        <Box display="flex" justifyContent="end">
          <Button
            data-cy="close-error-modal"
            variant="contained"
            color="primary"
            onClick={() => handleErrorModalState(false)}
          >
            Fechar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
