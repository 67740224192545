/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React Base Styles
import colors from "../../base/colors";
import typography from "../../base/typography";

const { label, primary } = colors;
const { size } = typography;

const inputLabel = {
  styleOverrides: {
    root: {
      fontSize: size.sm,
      color: label.main,

      "&.Mui-focused": {
        color: primary.main,
      },

      "&.MuiInputLabel-shrink": {
        fontSize: size.md,
        color: primary.main,

        "~ .MuiInputBase-root .MuiOutlinedInput-notchedOutline legend": {
          fontSize: size.sm,
        },
      },
    },

    sizeSmall: {
      fontSize: size.xs,
      color: label.main,

      "&.Mui-focused": {
        color: primary.main,
      },

      "&.MuiInputLabel-shrink": {
        fontSize: size.xs,

        "~ .MuiInputBase-root .MuiOutlinedInput-notchedOutline legend": {
          fontSize: size.xs,
        },
      },
    },
  },
};

export default inputLabel;
