// react hooks
import { useEffect } from "react";

// react-router-dom components
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui components
import { Box } from "@mui/material";

// @mui contexts
import { setLayout, useMaterialUIController } from "../../context/MUIContext";

// Application context
import { useUsersContext } from "../../context/UserContext";

// Generic components
import Loading from "../Loading/Loading";
import ErrorModal from "../ErrorModal/ErrorModal";

function PageLayout({ background, children }) {
  const { loadingPage, errorData, errorModalState, handleErrorModalState } =
    useUsersContext();
  const { pathname } = useLocation();
  const [, dispatch] = useMaterialUIController();

  useEffect(() => {
    setLayout(dispatch, "page");
  }, [pathname]);

  return (
    <>
      <Box
        width="100vw"
        height="100vh"
        minHeight="100vh"
        bgColor={background}
        sx={{ overflowX: "hidden" }}
      >
        <ErrorModal
          errorData={errorData}
          errorModalState={errorModalState}
          handleErrorModalState={handleErrorModalState}
        />

        {loadingPage && <Loading fullscreen />}
        
        {children}
      </Box>
    </>
  );
}

// Setting default values for the props for PageLayout
PageLayout.defaultProps = {
  background: "default",
};

// Typechecking props for the PageLayout
PageLayout.propTypes = {
  background: PropTypes.oneOf(["white", "light", "default"]),
  children: PropTypes.node.isRequired,
};

export default PageLayout;
