/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import Grid from '@mui/material/Grid'
import React, { useState, useEffect } from 'react'

import * as AWS from 'aws-sdk'


import axios from 'axios'

import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Paper from '@mui/material/Paper'
import moment from 'moment'
import 'moment-timezone'
import { makeStyles } from '@mui/styles'
import {
  Card,
  Box,
  Button,
  TextField,
  Typography,
  Select,
  MenuItem,
  Backdrop,
  CircularProgress,
  Input,
  Icon,
  InputBase
} from '@mui/material'

import { styled } from '@mui/material/styles'

import { AssignmentInd } from '@mui/icons-material'
import { Link } from 'react-router-dom'
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    margin: '0 auto',
    textAlign: 'center'
  },
  icon: {
    fontSize: 120,
    marginBottom: theme.spacing(2)
  }
}))

const ResponsiveButton = styled(Button)(({ theme }) => ({
  width: '100%',
  marginBottom: '10px',
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
    marginBottom: 0
  }
}))


function LockScreen () {
  const classes = useStyles()
  

  return (
    <>
      <Box className={classes.root}>
        <AssignmentInd className={classes.icon} />
        <Typography variant='h5' component='h2' align='center'>
          O seu cadastro está aguardando aprovação
        </Typography>
        <Box mt={3} mb={1} textAlign='center'>
              <Typography variant='button' color='text'>
                Deseja sair?{' '}
                <Typography
                  component={Link}
                  to='/'
                  variant='button'
                  color='info'
                  fontWeight='medium'
                  textGradient
                >
                  Sair
                </Typography>
              </Typography>
            </Box>
      </Box>
    </>
  )
}

export default LockScreen