import { TableRowField } from "../../../components";

export default function FeesTableData({ procedures, selectedGroupHon }) {
  if (procedures.length === 0) return { columns: [], rows: [] };

  function createData(nome, valor) {
    return {
      nome,
      valor,
    };
  }

  const rows = procedures
    ?.filter((data) => data.grupo === selectedGroupHon)
    ?.map((result) => {
      return result && createData(result.nome, result.htr);
    });

  let formattedRows = [];

  rows.map((fee, index) => {
    return (formattedRows[index] = {
      nome: <TableRowField value={fee?.nome} />,
      htr: <TableRowField value={fee?.valor} />,
    });
  });

  formattedRows.sort((a, b) => {
    const nomeA = a.nome.props.value;
    const nomeB = b.nome.props.value;

    return nomeA.localeCompare(nomeB);
  });

  return {
    columns: [
      { Header: "Nome", accessor: "nome", width: "25%", align: "left" },
      { Header: "Valor", accessor: "htr", align: "left" },
    ],

    rows: formattedRows,
  };
}
