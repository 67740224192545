// react hooks
import { useState } from "react";

// react-router-dom components and hooks
import { useNavigate } from "react-router-dom";

import axios from "axios";

// @mui components
import {
  Modal,
  TextField,
  MenuItem,
  Typography,
  Box,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const PetSubscriptionBox = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400px",
  backgroundColor: "white",
  border: " 2px solid #B8BBFF",
  paddingTop: "18px",
  paddingBottom: "18px",
  borderRadius: "12px",
}));

export default function ModalClinica({ open, onClose, cnpjs }) {
  const navigate = useNavigate();

  const [selectedCnpj, setSelectedCnpj] = useState("");

  const handleCnpjChange = (event) => {
    setSelectedCnpj(event.target.value);
  };

  const awsResponseError = {
    status: 500,
    data: { message: "Erro na requisição. Tente novamente" },
  };

  async function getUserProByParams(params, values) {
    const api = `https://q7amrtd8t8.execute-api.us-east-1.amazonaws.com/dev/autorizador/get_user_pro/by_params?params=${params}&values=${values}`;

    try {
      const response = await axios.get(api, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      localStorage.setItem("email", response?.data?.email);
      navigate("/pro/guias");
    } catch (error) {
      if (!error.response) {
        return awsResponseError;
      }

      return error.response;
    }
  }

  const handleClose = () => {
    setSelectedCnpj("");
    onClose(selectedCnpj);
  };

  function handleSelectClinica() {
    // console.log("selecteddd", selectedCnpj);
    localStorage.setItem("cnpj", selectedCnpj);
    getUserProByParams("id", selectedCnpj);
    // navigate('/pro/guias')
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <PetSubscriptionBox component="form" noValidate sx={{ boxShadow: 3 }}>
        <Box
          sx={{
            padding: 2,
            display: "flex",
            flexDirection: "column",
            gap: "18px",
            width: "90%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <Typography variant="h5" sx={{ marginBottom: 2 }}>
            Selecione a Clínica:
          </Typography>

          <TextField
            select
            label="CNPJ"
            value={selectedCnpj}
            onChange={handleCnpjChange}
            fullWidth
          >
            {cnpjs.map((cnpj) => (
              <MenuItem key={cnpj} value={cnpj}>
                {cnpj}
              </MenuItem>
            ))}
          </TextField>

          <Button onClick={() => handleSelectClinica()}>Confirmar</Button>
        </Box>
      </PetSubscriptionBox>
    </Modal>
  );
}
