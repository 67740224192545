// @mui components
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export default function ConfirmDialog({
  openConfirmDialog,
  handleConfirmDialog,
  handleDefendGlosa,
}) {
  return (
    <div>
      <Dialog
        open={openConfirmDialog}
        onClose={() => handleConfirmDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Defender essa guia?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Você tem certeza que gostaria de defender essa guia? Essa opção não
            terá como ser desfeita.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={() => handleConfirmDialog(false)}>
            Cancelar
          </Button>
          <Button color="primary" onClick={() => handleDefendGlosa()}>
            Defender
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
