import { Button } from "@mui/material";

function DownloadCsvButton({ onClick, disabled }) {
  return (
    <Button color="download" variant="contained" onClick={onClick} disabled={disabled}>
      Baixar
    </Button>
  );
}

export default DownloadCsvButton;
