// react hooks
import { useState, useCallback } from "react";

// @mui components
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";

export default function AnamnesisModal({
  anamnesisModalState,
  handleAnamnesisModal,
  handleEmitir,
  petGender = "f",
}) {
  const [anamnesisForm, setAnamnesisForm] = useState({
    microchip: "",

    acquired: "",

    vaccination_up_to_date: false,
    vaccines: "",

    castrated: false,
    had_sex: false,

    pregnant: false,
    node_mass_breast_chain: false,
    node_mass_breast_chain_description: "",
    secretion_vulvar_region: false,
    node_mass_wound_vulvar_region: false,
    node_mass_wound_region_description: "",
    node_mass_wound_hernia_perineal_region: false,
    node_mass_wound_hernia_perineal_region_description: "",
    pseudociesis: false,

    cryptorchid: false,
    node_mass_wound_changes_penis_region: false,
    node_mass_wound_changes_penis_region_description: "",
    secretion_penis_region: false,
    secretion_penis_region_description: "",
    node_mass_wound_testicle_region: false,
    node_mass_wound_testicle_region_description: "",

    atopic: false,
    allergic: false,
    allergic_description: "",
    node_mass_skin: false,
    node_mass_skin_description: "",
    intergumentary_changes_significant: false,
    intergumentary_changes_significant_description: "",

    feeding_defecating_urinating_normally: false,
    gastroenteritis: false,
    digestive_surgery: false,
    digestive_surgery_description: "",
    umbilical_inguinal_hernia: false,
    umbilical_inguinal_hernia_description: "",

    cardiac_pathology: false,
    intermittent_recurring_cough: false,
    drug_usage: "false",
    drug_usage_description: "",
    exercise_intolerant: false,
    trachea_collapse: false,
    respiratory_changes_significant: false,
    respiratory_changes_significant_description: "",

    hematuria_stones_obstructions: false,
    urinary_changes_significant: false,
    urinary_changes_significant_description: "",

    claudication_change_gear: false,
    claudication_change_gear_description: "",
    orthopedic_surgery: false,
    orthopedic_surgery_description: "",
    locomotor_pathology: false,
    locomotor_pathology_description: "",
    spine_pain: false,
    seizure_syncope: false,
    locomotor_changes_significant: false,
    locomotor_changes_significant_description: "",

    eye_secretion: false,
    nasolacrimal_duct_obstruction: false,
    node_mass_eyelid: false,
    node_mass_eyelid_description: "",
    third_eyelid_prolaps: false,
    ulcer_blindness: false,
    ophthalmic_changes_significant: false,
    ophthalmic_changes_significant_description: "",

    prior_pet_visit_description: "",

    pre_existence: "",
  });

  const [acceptTerms, setAcceptTerms] = useState(false);

  const handleAnamnesisForm = useCallback((value, key) => {
    let newValue = "";

    if (value === "true") newValue = true;
    else if (value === "false") newValue = false;
    else newValue = value;

    setAnamnesisForm((prevState) => ({
      ...prevState,
      [key]: newValue,
    }));
  }, []);

  const handleAcceptTerms = useCallback(() => {
    setAcceptTerms((prevState) => !prevState);
  }, []);

  const handleDisableEmitir = useCallback(() => {
    return !(
      anamnesisForm.microchip &&
      anamnesisForm.acquired &&
      anamnesisForm.prior_pet_visit_description &&
      anamnesisForm.pre_existence &&
      acceptTerms
    );
  }, [
    anamnesisForm.microchip,
    anamnesisForm.acquired,
    anamnesisForm.prior_pet_visit_description,
    anamnesisForm.pre_existence,
    acceptTerms,
  ]);

  const handleCleanAll = useCallback(() => {
    setAnamnesisForm({
      microchip: "",
      acquired: "",
      prior_pet_visit_description: "",
      pre_existence: "",
    });

    setAcceptTerms(false);
  }, []);

  const formFields = [
    {
      name: "microchip",
      label: "Microchip",
      type: "text",
      value: anamnesisForm.microchip,
      gender: ["f", "m"],
      onChange: (e) => handleAnamnesisForm(e.target.value, "microchip"),
    },

    {
      name: "acquired",
      label: "Como adquiriu o pet?",
      type: "text",
      value: anamnesisForm.acquired,
      gender: ["f", "m"],
      onChange: (e) => handleAnamnesisForm(e.target.value, "acquired"),
    },

    {
      name: "vaccination_up_to_date",
      label: "Vacinação em dia?",
      type: "bool",
      gender: ["f", "m"],
      value: anamnesisForm.vaccination_up_to_date,
      onChange: (e) =>
        handleAnamnesisForm(e.target.value, "vaccination_up_to_date"),
      justify_checkbox: {
        name: "vaccines",
        label: "Quais vacinas?",
        type: "text",
        value: anamnesisForm.vaccines,
        onChange: (e) => handleAnamnesisForm(e.target.value, "vaccines"),
      },
    },

    {
      name: "reproductive_system",
      title: "3. SISTEMA REPRODUTIVO",
      gender: ["f", "m"],
    },
    {
      name: "castrated",
      label: "Paciente é castrado?",
      type: "bool",
      gender: ["f", "m"],
      value: anamnesisForm.castrated,
      onChange: (e) => handleAnamnesisForm(e.target.value, "castrated"),
    },
    {
      name: "had_sex",
      label: "Paciente já cruzou?",
      type: "bool",
      gender: ["f", "m"],
      value: anamnesisForm.had_sex,
      onChange: (e) => handleAnamnesisForm(e.target.value, "had_sex"),
    },

    {
      name: "female_pet",
      title: "3.1 FÊMEAS",
      gender: ["f"],
    },
    {
      name: "pregnant",
      label: "Há chances de estar prenha?",
      type: "bool",
      gender: ["f"],
      value: anamnesisForm.pregnant,
      onChange: (e) => handleAnamnesisForm(e.target.value, "pregnant"),
    },
    {
      name: "node_mass_breast_chain",
      label: "Apresenta algum nódulo ou massa em cadeia mamária?",
      type: "bool",
      gender: ["f"],
      value: anamnesisForm.node_mass_breast_chain,
      onChange: (e) =>
        handleAnamnesisForm(e.target.value, "node_mass_breast_chain"),
      justify_checkbox: {
        name: "node_mass_breast_chain_description",
        label: "Qual o tamanho do nódulo ou massa?",
        type: "text",
        value: anamnesisForm.node_mass_breast_chain_description,
        onChange: (e) =>
          handleAnamnesisForm(
            e.target.value,
            "node_mass_breast_chain_description"
          ),
      },
    },
    {
      name: "secretion_vulvar_region",
      label: "Apresenta alguma secreção em região vulvar?",
      type: "bool",
      gender: ["f"],
      value: anamnesisForm.secretion_vulvar_region,
      onChange: (e) =>
        handleAnamnesisForm(e.target.value, "secretion_vulvar_region"),
    },
    {
      name: "node_mass_wound_vulvar_region",
      label: "Apresenta algum nódulo, massa ou lesão em região vulvar?",
      type: "bool",
      gender: ["f"],
      value: anamnesisForm.node_mass_wound_vulvar_region,
      onChange: (e) =>
        handleAnamnesisForm(e.target.value, "node_mass_wound_vulvar_region"),
      justify_checkbox: {
        name: "node_mass_wound_region_description",
        label: "Qual o tamanho do nódulo ou massa?",
        type: "text",
        value: anamnesisForm.node_mass_wound_region_description,
        onChange: (e) =>
          handleAnamnesisForm(
            e.target.value,
            "node_mass_wound_region_description"
          ),
      },
    },
    {
      name: "node_mass_wound_hernia_perineal_region",
      label:
        "Apresenta algum nódulo, massa, hérnia ou lesão em região perineal?",
      type: "bool",
      gender: ["f"],
      value: anamnesisForm.node_mass_wound_hernia_perineal_region,
      onChange: (e) =>
        handleAnamnesisForm(
          e.target.value,
          "node_mass_wound_hernia_perineal_region"
        ),
      justify_checkbox: {
        name: "node_mass_wound_hernia_perineal_region_description",
        label: "Qual o tamanho do nódulo ou massa?",
        type: "text",
        value: anamnesisForm.node_mass_wound_hernia_perineal_region_description,
        onChange: (e) =>
          handleAnamnesisForm(
            e.target.value,
            "node_mass_wound_hernia_perineal_region_description"
          ),
      },
    },
    {
      name: "pseudociesis",
      label: "Apresenta sinais de pseudociese?",
      type: "bool",
      gender: ["f"],
      value: anamnesisForm.pseudociesis,
      onChange: (e) => handleAnamnesisForm(e.target.value, "pseudociesis"),
    },

    {
      name: "male_pet",
      title: "3.1 MACHOS",
      gender: ["m"],
    },
    {
      name: "cryptorchid",
      label: "Apresenta sinais de criptorquida?",
      type: "bool",
      gender: ["m"],
      value: anamnesisForm.cryptorchid,
      onChange: (e) => handleAnamnesisForm(e.target.value, "cryptorchid"),
    },
    {
      name: "node_mass_wound_changes_penis_region",
      label:
        "Apresenta algum nódulo, massa lesão ou alteração em região de pênis?",
      type: "bool",
      gender: ["m"],
      value: anamnesisForm.node_mass_wound_changes_penis_region,
      onChange: (e) =>
        handleAnamnesisForm(
          e.target.value,
          "node_mass_wound_changes_penis_region"
        ),
      justify_checkbox: {
        name: "node_mass_wound_changes_penis_region_description",
        label: "Qual o tamanho do nódulo ou massa? Que tipo de alteração?",
        type: "text",
        value: anamnesisForm.node_mass_wound_changes_penis_region_description,
        onChange: (e) =>
          handleAnamnesisForm(
            e.target.value,
            "node_mass_wound_changes_penis_region_description"
          ),
      },
    },
    {
      name: "secretion_penis_region",
      label: "Apresenta alguma secreção em região de pênis?",
      type: "bool",
      gender: ["m"],
      value: anamnesisForm.secretion_penis_region,
      onChange: (e) =>
        handleAnamnesisForm(e.target.value, "secretion_penis_region"),
      justify_checkbox: {
        name: "secretion_penis_region_description",
        label: "Qual o aspecto da secreção?",
        type: "text",
        value: anamnesisForm.secretion_penis_region_description,
        onChange: (e) =>
          handleAnamnesisForm(
            e.target.value,
            "secretion_penis_region_description"
          ),
      },
    },
    {
      name: "node_mass_wound_testicle_region",
      label: "Apresenta algum nódulo, massa ou lesão em região de testículo?",
      type: "bool",
      gender: ["m"],
      value: anamnesisForm.node_mass_wound_testicle_region,
      onChange: (e) =>
        handleAnamnesisForm(e.target.value, "node_mass_wound_testicle_region"),
      justify_checkbox: {
        name: "node_mass_wound_testicle_region_description",
        label: "Qual o tamanho do nódulo ou massa?",
        type: "text",
        value: anamnesisForm.node_mass_wound_testicle_region_description,
        onChange: (e) =>
          handleAnamnesisForm(
            e.target.value,
            "node_mass_wound_testicle_region_description"
          ),
      },
    },
    {
      name: "node_mass_wound_hernia_perineal_region",
      label:
        "Apresenta algum nódulo, massa, hérnia ou lesão em região perineal?",
      type: "bool",
      gender: ["m"],
      value: anamnesisForm.node_mass_wound_hernia_perineal_region,
      onChange: (e) =>
        handleAnamnesisForm(
          e.target.value,
          "node_mass_wound_hernia_perineal_region"
        ),
      justify_checkbox: {
        name: "node_mass_wound_hernia_perineal_region_description",
        label: "Qual o tamanho do nódulo ou massa?",
        type: "text",
        value: anamnesisForm.node_mass_wound_hernia_perineal_region_description,
        onChange: (e) =>
          handleAnamnesisForm(
            e.target.value,
            "node_mass_wound_hernia_perineal_region_description"
          ),
      },
    },

    {
      name: "intergumentary_system",
      title: "4. SISTEMA TEGUMENTAR",
      gender: ["f", "m"],
    },
    {
      name: "atopic",
      label: "Paciente atópico?",
      type: "bool",
      gender: ["f", "m"],
      value: anamnesisForm.atopic,
      onChange: (e) => handleAnamnesisForm(e.target.value, "atopic"),
    },
    {
      name: "allergic",
      label: "Paciente alérgico?",
      type: "bool",
      gender: ["f", "m"],
      value: anamnesisForm.allergic,
      onChange: (e) => handleAnamnesisForm(e.target.value, "allergic"),
      justify_checkbox: {
        name: "allergic_description",
        label: "Qual o tipo?",
        type: "text",
        value: anamnesisForm.allergic_description,
        onChange: (e) =>
          handleAnamnesisForm(e.target.value, "allergic_description"),
      },
    },
    {
      name: "node_mass_skin",
      label: "Apresenta algum nódulo ou massa em pele?",
      type: "bool",
      gender: ["f", "m"],
      value: anamnesisForm.node_mass_skin,
      onChange: (e) => handleAnamnesisForm(e.target.value, "node_mass_skin"),
      justify_checkbox: {
        name: "node_mass_skin_description",
        label: "Qual o tamanho do nódulo ou massa?",
        type: "text",
        value: anamnesisForm.node_mass_skin_description,
        onChange: (e) =>
          handleAnamnesisForm(e.target.value, "node_mass_skin_description"),
      },
    },
    {
      name: "intergumentary_changes_significant",
      label: "Alguma alteração tegumentar digna de nota?",
      type: "bool",
      gender: ["f", "m"],
      value: anamnesisForm.intergumentary_changes_significant,
      onChange: (e) =>
        handleAnamnesisForm(
          e.target.value,
          "intergumentary_changes_significant"
        ),
      justify_checkbox: {
        name: "intergumentary_changes_significant_description",
        label: "O que?",
        type: "text",
        value: anamnesisForm.intergumentary_changes_significant_description,
        onChange: (e) =>
          handleAnamnesisForm(
            e.target.value,
            "intergumentary_changes_significant_description"
          ),
      },
    },

    {
      name: "digestive_system",
      title: "5. SISTEMA DIGESTIVO",
      gender: ["f", "m"],
    },
    {
      name: "feeding_defecating_urinating_normally",
      label: "Se alimentando, defecando e urinando normalmente?",
      type: "bool",
      gender: ["f", "m"],
      value: anamnesisForm.feeding_defecating_urinating_normally,
      onChange: (e) =>
        handleAnamnesisForm(
          e.target.value,
          "feeding_defecating_urinating_normally"
        ),
    },
    {
      name: "gastroenteritis",
      label: "Histórico de gastroentrite?",
      type: "bool",
      gender: ["f", "m"],
      value: anamnesisForm.gastroenteritis,
      onChange: (e) => handleAnamnesisForm(e.target.value, "gastroenteritis"),
    },
    {
      name: "digestive_surgery",
      label: "Já realizou alguma cirurgia digestiva?",
      type: "bool",
      gender: ["f", "m"],
      value: anamnesisForm.digestive_surgery,
      onChange: (e) => handleAnamnesisForm(e.target.value, "digestive_surgery"),
      justify_checkbox: {
        name: "digestive_surgery_description",
        label: "Qual?",
        type: "text",
        value: anamnesisForm.digestive_surgery_description,
        onChange: (e) =>
          handleAnamnesisForm(e.target.value, "digestive_surgery_description"),
      },
    },
    {
      name: "umbilical_inguinal_hernia",
      label: "Presença de hérnia (umbilical ou inguinal)?",
      type: "bool",
      gender: ["f", "m"],
      value: anamnesisForm.umbilical_inguinal_hernia,
      onChange: (e) =>
        handleAnamnesisForm(e.target.value, "umbilical_inguinal_hernia"),
      justify_checkbox: {
        name: "umbilical_inguinal_hernia_description",
        label: "Qual?",
        type: "text",
        value: anamnesisForm.umbilical_inguinal_hernia_description,
        onChange: (e) =>
          handleAnamnesisForm(
            e.target.value,
            "umbilical_inguinal_hernia_description"
          ),
      },
    },

    {
      name: "cardiac_respiratory_system",
      title: "6. SISTEMA CARDIO-RESPIRATÓRIO",
      gender: ["f", "m"],
    },
    {
      name: "cardiac_pathology",
      label: "Possui alguma patologia? Cardíaca, sopro, arritmia?",
      type: "bool",
      gender: ["f", "m"],
      value: anamnesisForm.cardiac_pathology,
      onChange: (e) => handleAnamnesisForm(e.target.value, "cardiac_pathology"),
    },
    {
      name: "intermittent_recurring_cough",
      label: "Apresenta sinais de tosse intermitente ou recorrente?",
      type: "bool",
      gender: ["f", "m"],
      value: anamnesisForm.intermittent_recurring_cough,
      onChange: (e) =>
        handleAnamnesisForm(e.target.value, "intermittent_recurring_cough"),
    },
    {
      name: "drug_usage",
      label: "Faz uso de algum fármaco?",
      type: "bool",
      gender: ["f", "m"],
      value: anamnesisForm.drug_usage,
      onChange: (e) => handleAnamnesisForm(e.target.value, "drug_usage"),
      justify_checkbox: {
        name: "drug_usage_description",
        label: "Qual?",
        type: "text",
        value: anamnesisForm.drug_usage_description,
        onChange: (e) =>
          handleAnamnesisForm(e.target.value, "drug_usage_description"),
      },
    },
    {
      name: "exercise_intolerant",
      label: "Intolerância a exercícios?",
      type: "bool",
      gender: ["f", "m"],
      value: anamnesisForm.exercise_intolerant,
      onChange: (e) =>
        handleAnamnesisForm(e.target.value, "exercise_intolerant"),
    },
    {
      name: "trachea_collapse",
      label: "Há diagnóstico de colapso de traqueia?",
      type: "bool",
      gender: ["f", "m"],
      value: anamnesisForm.trachea_collapse,
      onChange: (e) => handleAnamnesisForm(e.target.value, "trachea_collapse"),
    },
    {
      name: "respiratory_changes_significant",
      label: "Alguma alteração digna de nota?",
      type: "bool",
      gender: ["f", "m"],
      value: anamnesisForm.respiratory_changes_significant,
      onChange: (e) =>
        handleAnamnesisForm(e.target.value, "respiratory_changes_significant"),
      justify_checkbox: {
        name: "respiratory_changes_significant_description",
        label: "Qual?",
        type: "text",
        value: anamnesisForm.respiratory_changes_significant_description,
        onChange: (e) =>
          handleAnamnesisForm(
            e.target.value,
            "respiratory_changes_significant_description"
          ),
      },
    },

    {
      name: "genito_urinary_system",
      title: "7. SISTEMA GÊNITO-URINÁRIO",
      gender: ["f", "m"],
    },
    {
      name: "hematuria_stones_obstructions",
      label: "Sinais de hematúria, cálculos, obstruções?",
      type: "bool",
      gender: ["f", "m"],
      value: anamnesisForm.hematuria_stones_obstructions,
      onChange: (e) =>
        handleAnamnesisForm(e.target.value, "hematuria_stones_obstructions"),
    },
    {
      name: "urinary_changes_significant",
      label: "Alguma alteração digna de nota?",
      type: "bool",
      gender: ["f", "m"],
      value: anamnesisForm.urinary_changes_significant,
      onChange: (e) =>
        handleAnamnesisForm(e.target.value, "urinary_changes_significant"),
      justify_checkbox: {
        name: "urinary_changes_significant_description",
        label: "Qual?",
        type: "text",
        value: anamnesisForm.urinary_changes_significant_description,
        onChange: (e) =>
          handleAnamnesisForm(
            e.target.value,
            "urinary_changes_significant_description"
          ),
      },
    },

    {
      name: "locomotor_nervous_system",
      title: "8. SISTEMA LOCOMOTOR-NERVOSO",
      gender: ["f", "m"],
    },
    {
      name: "claudication_change_gear",
      label: "Sinais de claudicação? Alteração em marcha?",
      type: "bool",
      gender: ["f", "m"],
      value: anamnesisForm.claudication_change_gear,
      onChange: (e) =>
        handleAnamnesisForm(e.target.value, "claudication_change_gear"),
      justify_checkbox: {
        name: "claudication_change_gear_description",
        label: "Qual região?",
        type: "text",
        value: anamnesisForm.claudication_change_gear_description,
        onChange: (e) =>
          handleAnamnesisForm(
            e.target.value,
            "claudication_change_gear_description"
          ),
      },
    },
    {
      name: "orthopedic_surgery",
      label: "Já realizou alguma cirurgia ortopédica?",
      type: "bool",
      gender: ["f", "m"],
      value: anamnesisForm.orthopedic_surgery,
      onChange: (e) =>
        handleAnamnesisForm(e.target.value, "orthopedic_surgery"),
      justify_checkbox: {
        name: "orthopedic_surgery_description",
        label: "Qual?",
        type: "text",
        value: anamnesisForm.orthopedic_surgery_description,
        onChange: (e) =>
          handleAnamnesisForm(e.target.value, "orthopedic_surgery_description"),
      },
    },
    {
      name: "locomotor_pathology",
      label: "Alguma patologia em locomotor já diagnosticada?",
      type: "bool",
      gender: ["f", "m"],
      value: anamnesisForm.locomotor_pathology,
      onChange: (e) =>
        handleAnamnesisForm(e.target.value, "locomotor_pathology"),
      justify_checkbox: {
        name: "locomotor_pathology_description",
        label: "Qual?",
        type: "text",
        value: anamnesisForm.locomotor_pathology_description,
        onChange: (e) =>
          handleAnamnesisForm(
            e.target.value,
            "locomotor_pathology_description"
          ),
      },
    },
    {
      name: "spine_pain",
      label: "Sinais de dor em coluna?",
      type: "bool",
      gender: ["f", "m"],
      value: anamnesisForm.spine_pain,
      onChange: (e) => handleAnamnesisForm(e.target.value, "spine_pain"),
    },
    {
      name: "seizure_syncope",
      label: "Sinais de convulsão ou síncope?",
      type: "bool",
      gender: ["f", "m"],
      value: anamnesisForm.seizure_syncope,
      onChange: (e) => handleAnamnesisForm(e.target.value, "seizure_syncope"),
    },
    {
      name: "locomotor_changes_significant",
      label: "Alguma alteração digna de nota?",
      type: "bool",
      gender: ["f", "m"],
      value: anamnesisForm.locomotor_changes_significant,
      onChange: (e) =>
        handleAnamnesisForm(e.target.value, "locomotor_changes_significant"),
      justify_checkbox: {
        name: "locomotor_changes_significant_description",
        label: "Qual?",
        type: "text",
        value: anamnesisForm.locomotor_changes_significant_description,
        onChange: (e) =>
          handleAnamnesisForm(
            e.target.value,
            "locomotor_changes_significant_description"
          ),
      },
    },

    {
      name: "ophthalmic_system",
      title: "9. SISTEMA OFTÁLMICO",
      gender: ["f", "m"],
    },
    {
      name: "eye_secretion",
      label: "Secreção ocular?",
      type: "bool",
      gender: ["f", "m"],
      value: anamnesisForm.eye_secretion,
      onChange: (e) => handleAnamnesisForm(e.target.value, "eye_secretion"),
    },
    {
      name: "nasolacrimal_duct_obstruction",
      label: "Sinais de obstrução de ducto nasolacrimal?",
      type: "bool",
      gender: ["f", "m"],
      value: anamnesisForm.nasolacrimal_duct_obstruction,
      onChange: (e) =>
        handleAnamnesisForm(e.target.value, "nasolacrimal_duct_obstruction"),
    },
    {
      name: "node_mass_eyelid",
      label: "Apresenta algum nódulo ou massa em pálpebra?",
      type: "bool",
      gender: ["f", "m"],
      value: anamnesisForm.node_mass_eyelid,
      onChange: (e) => handleAnamnesisForm(e.target.value, "node_mass_eyelid"),
      justify_checkbox: {
        name: "node_mass_eyelid_description",
        label: "Qual?",
        type: "text",
        value: anamnesisForm.node_mass_eyelid_description,
        onChange: (e) =>
          handleAnamnesisForm(e.target.value, "node_mass_eyelid_description"),
      },
    },
    {
      name: "third_eyelid_prolaps",
      label: "Apresenta prolapso de terceira pálpebra?",
      type: "bool",
      gender: ["f", "m"],
      value: anamnesisForm.third_eyelid_prolaps,
      onChange: (e) =>
        handleAnamnesisForm(e.target.value, "third_eyelid_prolaps"),
    },
    {
      name: "ulcer_blindness",
      label: "Apresenta úlcera ou cegueira?",
      type: "bool",
      gender: ["f", "m"],
      value: anamnesisForm.ulcer_blindness,
      onChange: (e) => handleAnamnesisForm(e.target.value, "ulcer_blindness"),
    },
    {
      name: "ophthalmic_changes_significant",
      label: "Alguma alteração digna de nota?",
      type: "bool",
      gender: ["f", "m"],
      value: anamnesisForm.ophthalmic_changes_significant,
      onChange: (e) =>
        handleAnamnesisForm(e.target.value, "ophthalmic_changes_significant"),
      justify_checkbox: {
        name: "ophthalmic_changes_significant_description",
        label: "Qual?",
        type: "text",
        value: anamnesisForm.ophthalmic_changes_significant_description,
        onChange: (e) =>
          handleAnamnesisForm(
            e.target.value,
            "ophthalmic_changes_significant_description"
          ),
      },
    },

    {
      name: "prior_pet_visit_description",
      label: "Já levou seu pet no veterinário por quais motivos?",
      type: "text",
      gender: ["f", "m"],
      value: anamnesisForm.prior_pet_visit_description,
      onChange: (e) =>
        handleAnamnesisForm(e.target.value, "prior_pet_visit_description"),
    },

    {
      name: "pre_existence",
      label: "Há pré-existência?",
      type: "text",
      gender: ["f", "m"],
      value: anamnesisForm.pre_existence,
      onChange: (e) => handleAnamnesisForm(e.target.value, "pre_existence"),
    },
  ];

  return (
    <Modal
      open={anamnesisModalState}
      onClose={() => handleAnamnesisModal(false)}
    >
      <Box
        position="absolute"
        display="flex"
        flexDirection="column"
        padding={{ xs: 4, md: 6 }}
        gap={4}
        top="50%"
        left="50%"
        width={{ xs: "70%", lg: "60%", xl: "50%" }}
        height="90%"
        boxShadow={1}
        sx={{
          backgroundColor: "#FFFFFF",
          borderRadius: "7px",
          transform: "translate(-50%, -50%)",
          overflowY: "scroll",
        }}
      >
        <Box display="flex" alignItems="center" gap={2}>
          <Typography variant="title" color="black" fontWeight={500}>
            Formulário de microchipagem
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" gap={2}>
          {formFields
            .filter((field) => field.gender.includes(petGender))
            .map((field) => {
              if (field.title) {
                return (
                  <Typography key={field.name} fontWeight={700}>
                    {field.title}
                  </Typography>
                );
              }

              return (
                <>
                  {field.type === "bool" ? (
                    <>
                      <FormControl key={field.name}>
                        <FormLabel sx={{ fontSize: "16px" }}>
                          {field.label}
                        </FormLabel>
                        <RadioGroup
                          row
                          value={field.value}
                          onChange={field.onChange}
                        >
                          <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label="Sim"
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label="Não"
                          />
                        </RadioGroup>
                      </FormControl>
                      {field.value === true && field.justify_checkbox && (
                        <FormControl key={field.justify_checkbox.name}>
                          <FormLabel sx={{ fontSize: "16px" }}>
                            {field.justify_checkbox.label}
                          </FormLabel>
                          <TextField
                            required
                            name={field.justify_checkbox.name}
                            type={field.justify_checkbox.type}
                            value={field.justify_checkbox.value}
                            onChange={field.justify_checkbox.onChange}
                          />
                        </FormControl>
                      )}
                    </>
                  ) : (
                    <FormControl key={field.name}>
                      <FormLabel sx={{ fontSize: "16px" }}>
                        {field.label}
                      </FormLabel>
                      <TextField
                        required
                        name={field.name}
                        type={field.type}
                        value={field.value}
                        onChange={field.onChange}
                      />
                    </FormControl>
                  )}
                </>
              );
            })}

          <FormGroup>
            <FormControlLabel
              sx={{ display: "flex" }}
              required
              control={
                <Checkbox
                  fontSize="small"
                  checked={acceptTerms}
                  onChange={handleAcceptTerms}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={
                <Typography display="inline" fontSize="14px" marginRight={1}>
                  Declaro para devidos fins que as informações acima são
                  verdadeiras e condizem com o estado de saúde do meu animal.
                  Estando ciente da importância destas para a avaliação correta
                  do mesmo. E que caso seja constatado pré-existência, a
                  patologia encontrada terá um período de carência definido pelo
                  plano.
                </Typography>
              }
            />
          </FormGroup>
        </Box>

        <Box display="flex" justifyContent="space-between">
          <Button
            variant="contained"
            color="error"
            onClick={() => handleAnamnesisModal(false)}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={handleDisableEmitir()}
            onClick={() => {
              handleEmitir(undefined, anamnesisForm);
              handleCleanAll();
            }}
          >
            Continuar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
