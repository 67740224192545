import { SvgIcon } from "@mui/material";

function GreenDotSVG() {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="15"
        viewBox="0 0 9 9"
        fill="none"
      >
        <circle cx="4.5" cy="4.5" r="4.5" fill="#32D1A0" />
      </svg>
    </SvgIcon>
  );
}

export default GreenDotSVG;
