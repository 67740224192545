// react hooks
import { useEffect, useState } from "react";

// react-router-dom hooks and components
import { Link, useNavigate } from "react-router-dom";

// @mui components
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

// Application Users context
import { useUsersContext } from "../../context/UserContext";

// Generic Components
import { BasicLayout } from "../../components";

// Utils functions and components
import formattedBankCodes from "../../utils/BankCodes";
import { originalStateCodes } from "../../utils/StateCodes";
import { getProcedures, updateInfosPro } from "../../utils/axios";

// Images
import LifepetLogo from "../../assets/images/lifepet-pro.png";
import { Check, Clear } from "@mui/icons-material";

function OnBoarding() {
  const navigate = useNavigate();

  const {
    cnpj,
    handleErrorModalState,
    handleErrorData,
    completedOnboarding,
    handleCompletedOnboarding,
  } = useUsersContext();

  const [activeStep, setActiveStep] = useState(0);
  const [inputValue, setInputValue] = useState("");
  const [procedures, setProcedures] = useState([]);
  const [onBoardingData, setOnBoardingData] = useState({
    state: "",
    acceptTerms: false,
    banco_recebedor: "Conta Primária",
    banco_conta: "",
    banco_agencia: "",
    banco: "",
    banco_tipo: "Conta Corrente",
  });
  const [selectedGroup, setSelectedGroup] = useState([]);

  const uniqueKeys = new Set(procedures?.map((procedure) => procedure?.grupo));

  // Usar Array.from para criar um novo array com os valores das chaves únicas
  const uniqueValues = Array.from(uniqueKeys);

  const accreditedCNPJ = localStorage.getItem("cnpj") || cnpj;

  const handleDisabledButton = () => {
    if (activeStep === 0) {
      return !onBoardingData.state || !onBoardingData.acceptTerms;
    }

    if (activeStep === 1) {
      return (
        !onBoardingData.banco_conta ||
        !onBoardingData.banco_agencia ||
        !onBoardingData.banco ||
        inputValue === ""
      );
    }
  };

  const handleOnBoardingData = (name, value) => {
    setOnBoardingData({
      ...onBoardingData,
      [name]: value,
    });
  };

  const handleActiveStep = (step) => {
    setActiveStep(step);
  };

  const handleGetProcedures = async () => {
    const { data, status } = await getProcedures();

    if (status !== 200) {
      return;
    }

    setProcedures(data);
  };

  const bankAccountTextFields = [
    {
      label: "CONTA",
      stateName: "banco_conta",
      onChangeKey: "banco_conta",
    },
    {
      label: "AGÊNCIA",
      stateName: "banco_agencia",
      onChangeKey: "banco_agencia",
    },
    {
      label: "BANCO",
      stateName: "banco",
      onChangeKey: "banco",
      optionsArray: formattedBankCodes,
    },
  ];

  const handleFinishOnboarding = async () => {
    const formattedProceduresNames = [...new Set(selectedGroup)].sort((a, b) =>
      a.localeCompare(b)
    );

    const onBoardingDataToSend = {
      ...onBoardingData,
      bankAccount: {
        agencia: onBoardingData.banco_agencia,
        banco: onBoardingData.banco,
        conta: onBoardingData.banco_conta,
        recebedor: onBoardingData.banco_recebedor,
        tipo_conta: onBoardingData.banco_tipo,
      },
      procedures: formattedProceduresNames,
    };

    const { status } = await updateInfosPro(
      onBoardingDataToSend,
      "onboarding",
      accreditedCNPJ
    );

    if (status !== 200) {
      handleErrorModalState(true);
      handleErrorData({
        title: "Erro ao realizar login",
        message: "Verifique seu email e senha e tente novamente!",
      });

      return;
    }

    localStorage.setItem("onBoarding", true);
    handleCompletedOnboarding(true);
    navigate("/pro/perfil", { replace: true });
  };

  useEffect(() => {
    if (!localStorage.getItem("onBoarding") === "true" || completedOnboarding) {
      navigate("/pro/perfil", { replace: true });
      return;
    }

    handleGetProcedures();
  }, []);

  return (
    <BasicLayout lg={activeStep !== 2 ? 6 : 8} xl={activeStep !== 2 ? 6 : 8}>
      <Card sx={{ padding: 2 }}>
        <Box textAlign="center" mt={2}>
          <img src={LifepetLogo} alt="Logo Lifepet Pro" width="40%" />
        </Box>

        {activeStep === 0 && (
          <>
            <Box textAlign="center" marginBottom={4}>
              <Typography variant="title" fontWeight={500}>
                Bem vindo ao onboard do LifepetPRO, o seu aplicativo de gestão
                de petshops.
              </Typography>
            </Box>

            <Box px={3}>
              <Box marginBottom={2}>
                <Typography variant="title2" fontWeight={500}>
                  Para começar, precisamos de algumas informações e completar o
                  seu cadastro.
                </Typography>
              </Box>

              <Box
                display="flex"
                alignContent="center"
                alignItems="center"
                marginBottom={1}
              >
                <InputLabel id="tableHon" sx={{ marginRight: 2 }}>
                  Escolha seu estado:
                </InputLabel>

                <FormControl sx={{ width: 250 }}>
                  <Select
                    labelId="tableHon"
                    id="tableHon"
                    name="tableHon"
                    value={onBoardingData.state}
                    onChange={(e) =>
                      handleOnBoardingData("state", e.target.value)
                    }
                  >
                    {originalStateCodes.map((state) => (
                      <MenuItem key={state.sigla} value={state.sigla}>
                        {state.nome}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <Box>
                <FormGroup>
                  <FormControlLabel
                    sx={{ display: "flex" }}
                    control={
                      <Checkbox
                        fontSize="small"
                        checked={onBoardingData.acceptTerms}
                        onChange={(e) =>
                          handleOnBoardingData("acceptTerms", e.target.checked)
                        }
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label={
                      <>
                        <Typography
                          variant="title2"
                          fontWeight={500}
                          display="inline"
                          marginRight={1}
                        >
                          Eu declaro que li e aceito a Política de Privacidade
                          Lifepet.
                        </Typography>

                        <Link
                          to="https://lifepet.com.br/politicas-de-privacidade/"
                          display="inline"
                          component="button"
                          type="button"
                          underline="hover"
                          color="primary"
                          href="https://lifepet.com.br/politicas-de-privacidade/"
                          rel="noopener"
                          target="_blank"
                        >
                          Política de Privacidade.
                        </Link>
                      </>
                    }
                  />
                </FormGroup>
              </Box>
            </Box>
          </>
        )}

        {activeStep === 1 && (
          <Box px={3}>
            <Box marginBottom={2}>
              <Typography variant="title2" fontWeight={500}>
                Por favor preencha os dados de sua conta bancária para nos
                auxiliar no seu pagamento.
              </Typography>
            </Box>

            {bankAccountTextFields.map((textField) => (
              <Box key={textField.onChangeKey}>
                {!textField.optionsArray ? (
                  <TextField
                    fullWidth
                    label={textField.label}
                    placeholder={textField.label}
                    variant="outlined"
                    size="small"
                    value={onBoardingData[textField.stateName]}
                    onChange={(e) =>
                      handleOnBoardingData(
                        textField.onChangeKey,
                        e.target.value
                      )
                    }
                    select={textField.optionsArray !== undefined}
                    SelectProps={{
                      native: true,
                    }}
                    sx={{ marginBottom: 2 }}
                  />
                ) : (
                  <Autocomplete
                    required
                    key={textField.onChangeKey}
                    value={textField.value}
                    onChange={(event, value) => {
                      handleOnBoardingData(textField.onChangeKey, value);
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, value) => {
                      setInputValue(value);
                    }}
                    // getOptionDisabled={false}
                    options={textField.optionsArray}
                    renderInput={(params) => (
                      <TextField {...params} label="Banco" />
                    )}
                  />
                )}
              </Box>
            ))}
          </Box>
        )}

        {activeStep === 2 && (
          <Box px={3}>
            <Box marginBottom={2}>
              <Typography variant="title2" fontWeight={500}>
                Para finalizar, selecione os grupos de procedimentos que você
                realiza em seu petshop.
              </Typography>
            </Box>

            <Box marginBottom={2}>
              <Grid container spacing={2}>
                {uniqueValues
                  .sort((a, b) => a.localeCompare(b))
                  .map((group) => {
                    return (
                      <Grid item key={group}>
                        <Box
                          display="flex"
                          borderRadius="15px"
                          onClick={() => {
                            if (selectedGroup.includes(group)) {
                              setSelectedGroup(
                                selectedGroup.filter((item) => item !== group)
                              );
                            } else {
                              setSelectedGroup([...selectedGroup, group]);
                            }
                          }}
                          sx={{
                            background: "#F9F9F9",
                            boxShadow:
                              "0px 1px 1px 0px rgba(205, 205, 205, 0.25)",
                            cursor: "pointer",
                          }}
                        >
                          <Box padding={1}>
                            {selectedGroup.includes(group) ? (
                              <Box
                                display="flex"
                                alignItems="center"
                                padding={1}
                                sx={{
                                  backgroundColor: "#dbefdc",
                                  borderRadius: "9999px",
                                }}
                              >
                                <Check color="success" fontSize="small" />
                              </Box>
                            ) : (
                              <Box
                                display="flex"
                                alignItems="center"
                                padding={1}
                                sx={{
                                  backgroundColor: "#F9E1E1",
                                  borderRadius: "9999px",
                                }}
                              >
                                <Clear color="error" fontSize="small" />
                              </Box>
                            )}
                          </Box>
                          <Box display="flex" alignItems="center" padding={1}>
                            <Typography variant="title2" color="label.main">
                              {group}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    );
                  })}
              </Grid>
            </Box>
          </Box>
        )}

        {activeStep === 3 && (
          <Box px={3}>
            <Box marginBottom={2} textAlign="center">
              <Typography variant="title" fontWeight={500}>
                Tudo está preenchido, aperte em FINALIZAR para poder começar a
                usar nosso serviço.
              </Typography>
            </Box>
          </Box>
        )}

        <Box display="flex" justifyContent="space-between" marginTop={5}>
          <Button
            variant="contained"
            disabled={activeStep === 0}
            onClick={() => handleActiveStep(activeStep - 1)}
          >
            Voltar
          </Button>

          {activeStep !== 3 ? (
            <Button
              variant="contained"
              disabled={handleDisabledButton()}
              onClick={() => handleActiveStep(activeStep + 1)}
            >
              Continuar
            </Button>
          ) : (
            <Button variant="contained" onClick={handleFinishOnboarding}>
              Finalizar
            </Button>
          )}
        </Box>
      </Card>
    </BasicLayout>
  );
}

export default OnBoarding;
