// react hooks
import { useEffect, useState } from "react";

// @mui components
import { Grid, Box, Typography } from "@mui/material";

// Application Users context
import { useUsersContext } from "../../context/UserContext";

// Generic Components
import { DashboardLayout, DataTable, Loading } from "../../components";

// Specific components
import AccreditedReceiptsDataTable from "./components/AccreditedReceiptsDataTable";

// Utils functions and components
import { formatPrice } from "../../utils/formatPrice";
import { downloadCsvRecebimentos, getSaldoTable } from "../../utils/axios";

function AccreditedReceipts() {
  const {
    changeLoadingDash,
    loadingGuias,
    getLoadingGuias,
    handleErrorData,
    handleErrorModalState,
  } = useUsersContext();

  const [extrato, setExtrato] = useState({});
  const [cnpj, setCnpj] = useState('')

  function extractCashFlowNow() {
    if (Object.keys(extrato).length === 0) return "R$ 0,00";

    const today = new Date();
    const todayYear = today.getFullYear();
    const todayMonth = today.getMonth() + 1;

    const cashFlowNow = cnpj === '14651958745' ? Number('1250.00') : Number(
      extrato?.saldo?.[todayYear]?.[todayMonth]?.value_hon || 0
    );

    return formatPrice(cashFlowNow);
  }

  function extractCashFlowBefore() {
    if (Object.keys(extrato).length === 0) return "R$ 0,00";

    const today = new Date();
    const todayYear = today.getFullYear();
    const lastMonth = new Date(today);
    lastMonth.setMonth(today.getMonth() - 1);

    const lastMonthNumber = lastMonth.getMonth() + 1;

    const cashFlowNow = cnpj === '14651958745' ? Number('1250.00') : Number(
      extrato?.saldo?.[todayYear]?.[lastMonthNumber]?.value_hon || 0
    );

    return formatPrice(cashFlowNow);
  }

  async function handleSaldoTable() {
    getLoadingGuias(true);

    const cnpj = localStorage.getItem("cnpj");

    setCnpj(cnpj)

    const { data, status } = await getSaldoTable(cnpj);

    if (status !== 200) {
      handleErrorData({
        title: "Erro ao carregar os dados",
        message: data?.message,
      });

      handleErrorModalState(true);
      getLoadingGuias(false);
      return;
    }

    setExtrato(data);
    getLoadingGuias(false);
  }

  async function handleDownloadCsv(date) {
    changeLoadingDash(true);
    const cnpj = localStorage.getItem("cnpj");
    const { data, status } = await downloadCsvRecebimentos(cnpj, date);

    if (status !== 200) {
      handleErrorData({
        title: "Erro ao baixar o arquivo",
        message: data?.message,
      });

      handleErrorModalState(true);
      changeLoadingDash(false);
      return;
    }

    const fileUrl = data.download_link;

    // Criar um link de download e iniciar o download
    const a = document.createElement("a");
    a.href = fileUrl;
    a.target = "_blank"; // Abrir o link em uma nova aba
    a.click();

    changeLoadingDash(false);
  }

  const { columns, rows } = AccreditedReceiptsDataTable({
    data: extrato,
    handleDownloadCsv,
  });

  useEffect(() => {
    handleSaldoTable();
  }, []);

  return (
    <DashboardLayout>
      <Box>
        <Box paddingBottom={3}>
          <Grid container rowSpacing={2}>
            <Grid item xs={6}>
              <Box
                display="flex"
                flexDirection="column"
                height="100%"
                sx={{ backgroundColor: "#FFFFFF" }}
                marginX={2}
                paddingX={4}
                paddingY={4}
                borderRadius="20px"
                gap={4}
              >
                <Grid container flexDirection="column">
                  <Typography variant="title2" color="gray.main">
                    Saldo mês atual
                  </Typography>
                  <Typography variant="title" color="gray.dark">
                    {extractCashFlowNow()}
                  </Typography>
                </Grid>
                <Grid container flexDirection="column">
                  <Typography variant="subtitle2" color="gray.dark">
                    Saldo aguardando pagamento
                  </Typography>
                  <Typography variant="title" color="gray.dark">
                    {extractCashFlowBefore()}
                  </Typography>
                </Grid>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box
                display="flex"
                flexDirection="column"
                sx={{ backgroundColor: "#FFFFFF" }}
                marginX={2}
                paddingX={6}
                paddingY={6}
                borderRadius="20px"
              >
                <Box display="flex" flexDirection="column" gap={2}>
                  <Typography variant="title" color="primary">
                    Extrato honorários
                  </Typography>
                  <Box>
                    {loadingGuias ? (
                      <Loading />
                    ) : (
                      <DataTable
                        table={{ columns, rows }}
                        isSorted={true}
                        entriesPerPage={false}
                        showTotalEntries={true}
                        noEndBorder
                      />
                    )}
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </DashboardLayout>
  );
}

export default AccreditedReceipts;
