// react hooks
import { useState } from 'react'

// react-router-dom hooks and components
import { Link, useNavigate } from 'react-router-dom'



// axios
import axios from 'axios'

// formik hooks and components
import { useFormik } from 'formik'

// yup for form validation
import * as Yup from 'yup'

// @mui components
import {
  Card,
  FormControl,
  InputAdornment,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Typography,
  Button,
  TextField,
  Input,
  Checkbox
} from '@mui/material'

// @mui icons
import { Mail, Lock } from '@mui/icons-material'

// @mui styles
import { makeStyles } from '@mui/styles'

// Application Users context
import { useUsersContext } from '../../context/UserContext'

// Generic Components
import { BasicLayout, MaskedTextField } from '../../components'

// Specific components
import { SignUp, Verify } from './Cognito'

// utils
import {
  createApplicationsLog,
  validateCNPJ,
  validateCPF
} from '../../utils/axios'
import FormLoadingComponent from '../../utils/FormLoading'
import { masks } from '../../utils/maskService'

// Images
import LifepetLogo from '../../assets/images/lifepet-pro.png'
// import icon from "../../assets/images/lifepetPro.png";

require('dotenv').config({ path: require('find-config')('.env') })

const useStyles = makeStyles(theme => ({
  eachForm: {
    display: 'flex',
    flexDirection: 'column'
  },
  perfil: {
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
    color: '#5A3D8A',
    marginTop: '5%'
  },
  titleDados: {
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '19px',
    /* identical to box height */

    color: '#535354'
  },
  root: {
    '& .MuiFilledInput-root': {
      background: '#fff'
    }
  },
  centerContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh' // Isso garante que ocupe toda a altura da tela
  }
}))

async function getSaldoTable (submit, email, tipo) {
  // getLoadingGuias(true);

  const api = `https://q7amrtd8t8.execute-api.us-east-1.amazonaws.com/dev/checkout/create_table`

  try {
    const response = await axios.post(
      'https://q7amrtd8t8.execute-api.us-east-1.amazonaws.com/dev/checkout/create_table',
      { submit, email, tipo }
    )
  } catch (error) {
    console.error(
      '🚀 ~ file: Recebimentos.jsx:88 ~ getSaldoTable ~ error:',
      error
    )
  }
}

const defaultValues = {
  nomeEmpresa: '',
  cnpj: '',
  endereco: '',
  nomeRepresentante: '',
  cpf: '',
  telefone: '',
  email: '',
  password: '',
  city: '',
  neighborhood: '',
  number: '',
  stateInitials: '',
  stateName: '',
  street: '',
  zipCode: '',
  nomeAutonomo: '',
  cpfAutonomo: '',
  nomeMae: '',
  telefoneAutonomo: '',
  nascimentoAutonomo: '',
  is_affiliate: 'false',
  indication_code: ''
}

const defaultCodigo = {
  codigo: ''
}

export default function Register () {
  const { changeLoadingPage, verifyB2B, getVerifyB2B } = useUsersContext()

  const [formValues, setFormValues] = useState(defaultValues)
  const [formCodigo, setFormCodigo] = useState(defaultCodigo)

  const [tipoCadastro, setTipoCadastro] = useState('empresa')
  const [checked, setChecked] = useState(false)
  const classes = useStyles()
  let navigate = useNavigate()

  const handleTipoCadastroChange = e => {
    setTipoCadastro(e.target.value)
  }

  const generateIndicationCode = () => {
    const randomNumbers = Math.floor(Math.random() * 90000) + 10000
    return `CA${randomNumbers}`
  }

  const handleChange = event => {
    setChecked(event.target.checked)

    setFormValues({
      ...formValues,
      is_affiliate: event.target.checked ? 'true' : 'false'
    })
  }

  const formSchema = Yup.object().shape({
    email: Yup.string()
      .email('E-mail inválido')
      .required('Obrigatório'),
    password: Yup.string()
      .required('Obrigatório')
      .min(8, 'A senha deve conter ao menos 8 caracteres')
      .matches(
        /^(?=(?:.*?[A-Z]))(?=(?:.*?[0-9]))(?=(?:.*?[!@#$%*()_+^&}{:;?.]){1})(?!.*\s)[0-9a-zA-Z!@#$%;*(){}_+^&]*$/,
        'A senha deve conter pelo menos um número, uma letra maiúscula e um caractere especial'
      ),

    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'As senhas não são iguais.')
      .required('Obrigatório')
  })

  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema: formSchema,
    onSubmit: values => {
      changeLoadingPage(true)

      
      setTimeout(() => {
        // alert(JSON.stringify(values, null, 2));
        getSaldoTable(formValues, values.email, tipoCadastro)
        SignUp(values.email, values.password, tipoCadastro)

        formik.setSubmitting(false)
        changeLoadingPage(false)
        getVerifyB2B(true)
      }, 3000)
    }
  })

  async function checkCNPJ (cnpj) {
    changeLoadingPage(true)
    const response = await validateCNPJ(cnpj)

    await createApplicationsLog({
      user: cnpj,
      function: 'checkCNPJ',
      page: 'CadastroB2B',
      application: 'lifepetPro',
      response: response
    })

    if (response.status === false) {
      changeLoadingPage(false)
      alert('CNPJ inválido')
      formValues.cnpj = ''
      return
    }
    const indicationCode = generateIndicationCode()

    formValues.indication_code = indicationCode
    formValues.city = response?.result?.municipio
    formValues.neighborhood = response?.result?.bairro
    formValues.number = response?.result?.numero
    formValues.street = response?.result?.logradouro
    formValues.zipCode = response?.result?.cep
    formValues.stateInitials = response?.result?.uf
    changeLoadingPage(false)
  }

  async function checkCPF (cpf) {
    changeLoadingPage(true)
    const response = await validateCPF(cpf)

    await createApplicationsLog({
      user: cpf,
      function: 'checkCPF',
      page: 'CadastroB2B',
      application: 'lifepetPro',
      response: response
    })

    if (response.status === false) {
      changeLoadingPage(false)
      alert('CPF inválido')
      formValues.cpf = ''
      return
    }

    changeLoadingPage(false)
  }

  const handleInputChange = e => {
    const { name, value } = e.target
    setFormValues({
      ...formValues,
      [name]: value
    })
  }

  const handleInputCodigo = e => {
    const { name, value } = e.target
    setFormCodigo({
      ...formCodigo,
      [name]: value
    })
  }
  const handleSubmit = event => {
    changeLoadingPage(true)
    event.preventDefault()
    Verify(formik.values.email, formCodigo.codigo)
    getVerifyB2B(false)
    changeLoadingPage(false)
    navigate('/')
  }

  const maskedDataDeNascimento =
    formValues.nascimentoAutonomo && masks.date(formValues.nascimentoAutonomo)

  return (
    <BasicLayout>
      {verifyB2B ? (
        <Card sx={{ marginBottom: '10%' }}>
        <Box
          variant='gradient'
          bgColor='#fff'
          borderRadius='lg'
          coloredShadow='success'
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign='center'
        >
          <img width='40%' src={LifepetLogo} alt='LifePet Logo' />
        </Box>
        <Box pt={4} pb={3} px={3} textAlign='center'> {/* Texto centralizado */}
          <Typography variant='h5' gutterBottom sx={{ fontFamily: 'inherit' }}> {/* Utiliza a mesma fonte do site */}
            Conta criada com sucesso!
          </Typography>
          <Typography variant='body1' gutterBottom sx={{ fontFamily: 'inherit' }}> {/* Utiliza a mesma fonte do site */}
            Sua conta foi criada com sucesso. Agora você pode fazer login.
          </Typography>
          <Box mt={4} mb={1}>
            <Button
              variant='gradient'
              color='info'
              onClick={() => navigate('/')}
              fullWidth
            >
              Ir para o login
            </Button>
          </Box>
        </Box>
      </Card>
        // <form onSubmitCapture={handleSubmit}>
        //   <Card sx={{ marginBottom: '10%' }}>
        //     <Box
        //       variant='gradient'
        //       bgColor='#fff'
        //       borderRadius='lg'
        //       coloredShadow='success'
        //       mx={2}
        //       mt={-3}
        //       p={3}
        //       mb={1}
        //       textAlign='center'
        //     >
        //       <img width='40%' src={LifepetLogo} />
        //     </Box>
        //     <Box pt={4} pb={3} px={3}>
        //       <Box component='form' role='form'>
        //         <Box mb={2}>
        //           <FormControl fullWidth>
        //             <InputLabel htmlFor='codigo'>
        //               Código recebido por e-mail
        //             </InputLabel>
                    
        //           </FormControl>
        //         </Box>

        //         <Box mt={4} mb={1}>
        //           <Button
        //             variant='gradient'
        //             color='info'
        //             type='submit'
        //             fullWidth
        //           >
        //             confirmar
        //           </Button>
        //         </Box>
        //       </Box>
        //     </Box>
        //   </Card>
        // </form>
      ) : (
        <form
          onSubmitCapture={formik.handleSubmit}
          className={classes.centerContent}
        >
          {formik.isSubmitting ? (
            <FormLoadingComponent />
          ) : (
            <Card sx={{ marginTop: '10%' }}>
              <Box textAlign='center' mt={2}>
                <img src={LifepetLogo} alt='Logo Lifepet Pro' width='40%' />
              </Box>

              <Box py={2} px={3}>
                <InputLabel id='tipoCadastroLabel' style={{ marginBottom: 10 }}>
                  Tipo de Cadastro
                </InputLabel>

                <FormControl fullWidth>
                  <Select
                    labelId='tipoCadastroLabel'
                    id='tipoCadastro'
                    name='tipoCadastro'
                    value={tipoCadastro}
                    onChange={handleTipoCadastroChange}
                  >
                    <MenuItem value='empresa'>Empresa</MenuItem>
                    <MenuItem value='autonomo'>Autônomo</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <Box pb={3} px={3}>
                <Box component='form' role='form'>
                  {tipoCadastro === 'empresa' ? (
                    <>
                      <Box mb={2}>
                        <TextField
                          type='text'
                          name='nomeEmpresa'
                          placeholder='Nome Empresa'
                          value={formValues.nomeEmpresa}
                          onChange={handleInputChange}
                          fullWidth
                        />
                      </Box>

                      <Box mb={2}>
                        <MaskedTextField
                          id='cnpj-empresa'
                          type='text'
                          name='cnpj'
                          placeholder='CNPJ/CPF (Somente números)'
                          mask={
                            formValues.cnpj.length > 11
                              ? '99.999.999/9999-99'
                              : '999.999.999-999999'
                          }
                          value={formValues.cnpj}
                          onChange={handleInputChange}
                          onBlur={() =>
                            formValues.cnpj.length > 11
                              ? checkCNPJ(formValues.cnpj)
                              : checkCPF(formValues.cnpj)
                          }
                          fullWidth
                        />
                      </Box>

                      <Box mb={2}>
                        <TextField
                          type='text'
                          name='nomeRepresentante'
                          placeholder='Representante da empresa'
                          value={formValues.nomeRepresentante}
                          onChange={handleInputChange}
                          fullWidth
                        />
                      </Box>

                      <Box mb={2}>
                        <MaskedTextField
                          id='cpf-input'
                          type='text'
                          name='cpf'
                          placeholder='CPF (Representante)'
                          mask={'999.999.999-99'}
                          value={formValues.cpf}
                          inputProps={{ maxLength: 11 }}
                          onChange={handleInputChange}
                          fullWidth
                        />
                      </Box>

                      <Box mb={2}>
                        <MaskedTextField
                          id='telefone'
                          type='text'
                          name='telefone'
                          placeholder='Telefone'
                          mask={
                            formValues.telefone.length > 10
                              ? '(99) 99999-9999'
                              : '(99) 9999-99999'
                          }
                          value={formValues.telefone}
                          onChange={handleInputChange}
                          fullWidth
                        />
                      </Box>
                      <Box
                        mb={2}
                        display='flex'
                        flexDirection='row'
                        alignItems='center'
                      >
                        <InputLabel id='tipoCadastroLabel'>
                          Deseja participar do nosso programa de indicações?
                        </InputLabel>
                        <Checkbox
                          checked={checked}
                          onChange={handleChange}
                          style={{ marginLeft: 10 }}
                        />
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box mb={2}>
                        <TextField
                          type='text'
                          name='nomeAutonomo'
                          placeholder='Nome'
                          value={formValues.nomeAutonomo}
                          onChange={handleInputChange}
                          fullWidth
                        />
                      </Box>

                      <Box mb={2}>
                        <MaskedTextField
                          id='cpf-input'
                          name='cpfAutonomo'
                          type='text'
                          placeholder='CPF'
                          mask={'999.999.999-99'}
                          value={formValues.cpfAutonomo}
                          inputProps={{ maxLength: 11 }}
                          onChange={handleInputChange}
                          fullWidth
                        />
                      </Box>

                      <Box mb={2}>
                        <TextField
                          type='text'
                          name='nomeMae'
                          placeholder='Nome da mãe'
                          value={formValues.nomeMae}
                          onChange={handleInputChange}
                          fullWidth
                        />
                      </Box>
                      <Box mb={2}>
                        <TextField
                          type='text'
                          name='nascimentoAutonomo'
                          placeholder='Data de nascimento'
                          value={maskedDataDeNascimento}
                          onChange={handleInputChange}
                          fullWidth
                        />
                      </Box>

                      <Box mb={2}>
                        <MaskedTextField
                          id='telefone'
                          type='text'
                          name='telefoneAutonomo'
                          placeholder='Telefone'
                          mask={
                            formValues.telefone.length > 10
                              ? '(99) 99999-9999'
                              : '(99) 9999-99999'
                          }
                          value={formValues.telefoneAutonomo}
                          onChange={handleInputChange}
                          fullWidth
                        />
                      </Box>
                      <Box
                        mb={2}
                        display='flex'
                        flexDirection='row'
                        alignItems='center'
                      >
                        <InputLabel id='tipoCadastroLabel'>
                          Deseja participar do nosso programa de indicações?
                        </InputLabel>
                        <Checkbox defaultChecked style={{ marginLeft: 10 }} />
                      </Box>
                    </>
                  )}

                  <Box mb={2}>
                    <TextField
                      type='email'
                      name='email'
                      placeholder='Email'
                      autoComplete='email'
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <Mail />
                          </InputAdornment>
                        )
                      }}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                      fullWidth
                    />
                  </Box>

                  <Box mb={2}>
                    <TextField
                      type='password'
                      name='password'
                      placeholder='Senha'
                      autoComplete='current-password'
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <Lock />
                          </InputAdornment>
                        )
                      }}
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                      }
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                      fullWidth
                    />
                  </Box>

                  <Box mb={2}>
                    <TextField
                      id='confirmPassword'
                      type='password'
                      name='confirmPassword'
                      placeholder='Confirme sua senha'
                      autoComplete='off'
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <Lock />
                          </InputAdornment>
                        )
                      }}
                      value={formik.values.confirmPassword}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.confirmPassword &&
                        Boolean(formik.errors.confirmPassword)
                      }
                      helperText={
                        formik.touched.confirmPassword &&
                        formik.errors.confirmPassword
                      }
                      fullWidth
                    />
                  </Box>

                  <Box mt={4} mb={1}>
                    <Button variant='contained' type='submit' fullWidth>
                      cadastrar
                    </Button>
                  </Box>

                  <Box mt={3} mb={1} textAlign='center'>
                    <Typography variant='button' color='text'>
                      Já possui uma conta?
                      <Typography
                        component={Link}
                        variant='button'
                        color='primary'
                        to='/'
                        fontWeight='medium'
                        marginLeft={1}
                      >
                        Faça o login
                      </Typography>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Card>
          )}
        </form>
      )}
    </BasicLayout>
  )
}
