import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import { Box } from "@mui/material";
import NotificationsIcon from "./components/NotificationsIcon";
import ProfileIcon from "./components/ProfileIcon";

function Header({ vet, notifications, asyncGetNotifications }) {
  const navigate = useNavigate();

  function handleNavigate(route) {
    navigate(route);
  }

  return (
    <Box
      display="flex"
      justifyContent="end"
      alignItems="center"
      gap={2}
      mx={2}
      py={3}
    >
      {vet === "true" ? null : (
        <>
          <NotificationsIcon
            notifications={notifications}
            asyncGetNotifications={asyncGetNotifications}
          />
          <ProfileIcon handleNavigate={handleNavigate} />
        </>
      )}
    </Box>
  );
}

Header.propTypes = {
  showNotifications: PropTypes.bool,
  showProfile: PropTypes.bool,
};

export default Header;
