import { Box, Typography, useTheme } from "@mui/material";

function TableRowField({ type = "text", ...rest }) {
  switch (type) {
    case "status":
      return <TableRowStatus {...rest} />;
    case "text":
      return <TableRowTypography {...rest} />;
    default:
      return <TableRowTypography {...rest} />;
  }
}

function TableRowTypography({ value, mask, date, ...rest }) {
  const typography = value ?? "-";

  const maskedTypography = mask ? mask(typography) : null;

  const dateCalendar = date ? value?.split(" ")[0] : "";
  const dateHour = date ? value?.split(" ")[1] : "";

  return (
    <Box display="flex" alignItems="center">
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography {...rest} variant="tableCell" color="gray.dark">
          {dateCalendar || maskedTypography || typography}
        </Typography>
        {dateHour && (
          <Typography variant="tableCell" fontSize={13} color="gray.dark">
            {dateHour}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

function TableRowStatus({ value, ...rest }) {
  const theme = useTheme();

  function handleStatusColor() {
    switch (value) {
      case "recusado":
      case "glosado totalmente":
        return { textColor: "error.main", boxColor: "error.light" };

      case "liberado":
        return { textColor: "success.main", boxColor: "success.light" };

      case "pagamento":
      case "assinatura":
      case "parcialmente glosado":
        return { textColor: "warning.main", boxColor: "warning.light" };

      default:
        return { textColor: "gray.main", boxColor: "gray.light" };
    }
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      padding={1}
      sx={{
        backgroundColor: handleStatusColor().boxColor,
        borderRadius: theme.borders.borderRadius.lg,
      }}
    >
      <Typography
        {...rest}
        variant="tableHeader"
        textTransform="uppercase"
        color={handleStatusColor().textColor}
      >
        {value}
      </Typography>
    </Box>
  );
}

export default TableRowField;
