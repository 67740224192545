// react hooks
import { useState, useCallback } from "react";

// @mui components
import {
  Box,
  Button,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";

// @mui icons
import { Search, Warning } from "@mui/icons-material";

// utils
import { maskRemoveService, masks } from "../../../utils/maskService";

export default function FindPetModal({
  findPetModalMessage,
  findPetModalState,
  handleFindPetModal,
  getTutorByCPF,
  activePets,
  getMicrochipByPetId,
  findActive,
}) {
  const [findPetModalData, setFindPetModalData] = useState({
    cpf: "",
    pet_id: "",
  });

  const handleFindPetModalData = useCallback((value, key) => {
    setFindPetModalData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  }, []);

  return (
    <Modal open={findPetModalState} onClose={() => handleFindPetModal(false)}>
      {!findActive ? (
        <Box
          position="absolute"
          display="flex"
          flexDirection="column"
          padding={{ xs: 4, md: 8 }}
          gap={4}
          top="50%"
          left="50%"
          width={{ xs: "70%", lg: "60%", xl: "50%" }}
          boxShadow={1}
          sx={{
            backgroundColor: "#FFFFFF",
            borderRadius: "7px",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Box display="flex" alignItems="center" gap={2}>
            <Warning fontSize="large" color="warning" />
            <Typography variant="title" color="black" fontWeight={500}>
              {findPetModalMessage}
            </Typography>
          </Box>

          <Box display="flex" flexDirection="column" gap={2}>
            <Typography
              variant="title"
              fontSize="18px"
              fontWeight="500"
              color="text.light"
            >
              Confira o número do microchip ou pesquise pelo CPF do tutor.
            </Typography>

            <TextField
              required
              name="cpf"
              label="CPF do tutor"
              type="text"
              value={masks.cpf(findPetModalData.cpf)}
              onChange={(e) => handleFindPetModalData(e.target.value, "cpf")}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  getTutorByCPF(maskRemoveService(findPetModalData.cpf));
                }
              }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() =>
                      getTutorByCPF(maskRemoveService(findPetModalData.cpf))
                    }
                  >
                    <Search position="start" />
                  </IconButton>
                ),
              }}
            />
          </Box>

          {activePets.length > 0 && (
            <Box display="flex" flexDirection="column" gap={2}>
              <Typography
                variant="title"
                fontSize="18px"
                fontWeight="500"
                color="text.light"
              >
                Selecione o pet que deseja buscar:
              </Typography>

              {activePets.map((pet) => (
                <Box
                  key={pet.id}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  border={
                    pet.id === findPetModalData.pet_id
                      ? "1px solid #32d1a0"
                      : "none"
                  }
                  gap={2}
                  padding={2}
                  borderRadius={2}
                  sx={{
                    backgroundColor: "#F5F5F5",
                    cursor: "pointer",
                  }}
                  onClick={(e) => handleFindPetModalData(pet.id, "pet_id")}
                >
                  <Typography variant="title" fontSize="18px" fontWeight={500}>
                    {pet?.metadata?.pet_name}
                  </Typography>
                </Box>
              ))}
            </Box>
          )}
          <Box display="flex" justifyContent="space-between">
            <Button
              variant="contained"
              color="error"
              onClick={() => handleFindPetModal(false)}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={!findPetModalData.pet_id}
              onClick={() => {
                getMicrochipByPetId(findPetModalData.pet_id).then(() => {
                  setFindPetModalData({
                    cpf: "",
                    pet_id: "",
                  });
                });
              }}
            >
              Selecionar
            </Button>
          </Box>
        </Box>
      ) : (
        <Box
          position="absolute"
          display="flex"
          flexDirection="column"
          padding={{ xs: 4, md: 8 }}
          gap={4}
          top="50%"
          left="50%"
          width={{ xs: "70%", lg: "60%", xl: "50%" }}
          boxShadow={1}
          sx={{
            backgroundColor: "#FFFFFF",
            borderRadius: "7px",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Box display="flex" alignItems="center" gap={2}>
            <Warning fontSize="large" color="warning" />
            <Typography variant="title" color="black" fontWeight={500}>
              {findPetModalMessage}
            </Typography>
          </Box>
        </Box>
      )}
    </Modal>
  );
}
