// react hooks
import { useCallback, useEffect } from "react";

// react-router-dom components and hooks
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui components
import { Box } from "@mui/material";

// @mui contexts
import { setLayout, useMaterialUIController } from "../../context/MUIContext";

// Application contexts
import { useUsersContext } from "../../context/UserContext";

// Generic Components
import ErrorModal from "../ErrorModal/ErrorModal";
import Header from "../Header/Header";
import Loading from "../Loading/Loading";

// Utils
import { getNotifications } from "../../utils/axios";

function DashboardLayout({ children }) {
  const [controller, dispatch] = useMaterialUIController();
  const {
    loadingDash,
    notifications,
    handleNotifications,
    errorData,
    errorModalState,
    handleErrorModalState,
  } = useUsersContext();

  const { pathname } = useLocation();
  const { miniSidenav } = controller;

  const cnpj = localStorage.getItem("cnpj");
  const vet = localStorage.getItem("vet");

  useEffect(() => {
    setLayout(dispatch, "dashboard");
  }, [pathname]);

  const asyncGetNotifications = useCallback(async () => {
    const { data, status } = await getNotifications(cnpj);

    if (status !== 200) {
      return;
    }

    if (data) {
      handleNotifications(data);
    }
  }, [cnpj, handleNotifications]);

  useEffect(() => {
    asyncGetNotifications();
  }, [cnpj]);

  return (
    <Box
      sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        p: 3,
        position: "relative",
        [breakpoints.up("xl")]: {
          marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
          transition: transitions.create(["margin-left", "margin-right"], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
          }),
        },
      })}
    >
      <ErrorModal
        errorData={errorData}
        errorModalState={errorModalState}
        handleErrorModalState={handleErrorModalState}
      />

      <Header
        vet={vet}
        notifications={notifications}
        asyncGetNotifications={asyncGetNotifications}
      />

      {loadingDash && <Loading />}

      {!loadingDash && children}
    </Box>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardLayout;
