import { useEffect, useState } from "react";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui components
import { Collapse, Divider, Icon, List, Typography, Box } from "@mui/material";

// Specific components
import SidenavCollapse from "./SidenavCollapse";
import SidenavRoot from "./SidenavRoot";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "../../context/MUIContext";

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    sidenavColor,
  } = controller;
  const location = useLocation();
  const collapseName = location.pathname;
  const [openOut, setOpenOut] = useState(false);

  let textColor = "white";
  const vet = localStorage.getItem("vet");
  const isAffiliate = localStorage.getItem("isAffiliate");


  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(
        dispatch,
        window.innerWidth < 1200 ? false : transparentSidenav
      );
      setWhiteSidenav(
        dispatch,
        window.innerWidth < 1200 ? false : whiteSidenav
      );
    }

    // The event listener that's calling the handleMiniSidenav function when resizing the window.
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, []);

  //Render all the routes from the routes.js (All the visible items on the Sidenav)
  function hasChildren(item) {
    const { items: children } = item;

    if (children === undefined) {
      return false;
    }

    if (children.constructor !== Array) {
      return false;
    }

    if (children.length === 0) {
      return false;
    }

    return true;
  }

  const SingleLevel = ({ item }) => {
    const handleLogout = () => {
      localStorage.removeItem("email");
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("cnpj");
    };

    if (item.key === "logout") {
      return (
        <NavLink key={item.key} to={item.route} onClick={handleLogout}>
          <SidenavCollapse
            name={item.name}
            icon={item.icon}
            active={item.route === collapseName}
            noCollapse={item.noCollapse}
          />
        </NavLink>
      );
    }

    return (
      <NavLink key={item.key} to={item.route}>
        <SidenavCollapse
          name={item.name}
          icon={item.icon}
          active={item.route === collapseName}
          noCollapse={item.noCollapse}
        />
      </NavLink>
    );
  };

  const MultiLevel = ({ item }) => {
    const { items: children } = item;
  
    const [openOut, setOpenOut] = useState(false);
  
    const handleClick = () => {
      setOpenOut((prev) => !prev);
    };

    const renderChildren = children.map((child, key) => {
      if (child.key === "indicacoes" && (!isAffiliate || isAffiliate === 'false' || isAffiliate === '' || isAffiliate === 'undefined')) {
        return null;
      }  
  
      return (
        <NavLink key={key} to={child.route}>
          <SidenavCollapse
            name={child.name}
            active={child.key === collapseName}
            noCollapse={child.noCollapse}
          />
        </NavLink>
      );
    });
  
    return (
      <>
        <SidenavCollapse
          onClick={handleClick}
          name={item.name}
          icon={item.icon}
          active={item.key === collapseName}
          noCollapse={item.noCollapse}
          open={openOut}
          isCollapse={true}
        />
  
        <Collapse in={openOut} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {renderChildren}
          </List>
        </Collapse>
      </>
    );
  };
  

  const MenuItem = ({ item }) => {
    const Component = hasChildren(item) ? MultiLevel : SingleLevel;
    return <Component item={item} />;
  };

  const renderRoutes = routes.map((item, key) => {
    // Verificar se a variável 'vet' é verdadeira e a chave é 'recebimentos'
    if (vet === "true" && item.key === "recebimentos") {
      return null;
    }

    return <MenuItem key={key} item={item} />;
  });

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <Box pt={3} pb={1} px={4} textAlign="center">
        <Box
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <Typography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </Typography>
        </Box>
        <Box
          component={NavLink}
          to="/"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {brand && (
            <Box component="img" src={brand} alt="Brand" width="8rem" />
          )}
        </Box>
      </Box>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <List>{renderRoutes}</List>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  brand: PropTypes.string,
  brandName: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
