// Generic Components
import { ActionButton, TableRowField } from "../../../components";

export default function PendingGuidesTableData({
  data,
  search,
  statusSearch,
  selectedDate,
}) {
  if (Object.keys(data).length === 0) return { columns: [], rows: [] };

  const cnpj = localStorage.getItem("cnpj");

  function createData(
    id,
    numero_guia,
    microchip_number,
    pet_name,
    tutor,
    created_at,
    status,
    cnpj_lab,
    url
  ) {
    return {
      id,
      numero_guia,
      microchip_number,
      pet_name,
      tutor,
      created_at,
      status,
      cnpj_lab,
      url
    };
  }

  const rows = data?.map((result) => {
    return createData(
      result?.id,
      result?.numero_guia,
      result?.microchip_number,
      result?.pet_name,
      result?.tutor,
      result?.created_at,
      result?.status,
      result?.cnpj_lab,
      result?.url
    );
  });

  let rowsTeste = [];

  function convertDateFormat(dateTimeString) {
    if (dateTimeString) {
      const [date, time] = dateTimeString.split(" ");

      if (time) {
        const [day, month, year] = date.split("/");
        const [hour, minute, second] = time.split(":");
        return `${month}/${day}/${year} ${hour}:${minute}:${second}`;
      } else {
        const [day, month, year] = date.split("/");
        return `${month}/${day}/${year}`;
      }
    }

    return "";
  }

  // Ordenar os dados com base na propriedade numero, status e data
  const sortedRows = rows
    ?.filter((row) => row?.cnpj_lab === cnpj)
    .filter((row) => row?.status !== "cancelada")
    .filter((row) => row?.status !== "liberado")
    .filter((row) => {
      if (search === "") return row;
      return row?.numero_guia.includes(search);
    })
    .filter((row) => {
      if (statusSearch === "") return row;
      return row?.status.includes(statusSearch);
    })
    .filter((row) => {
      if (selectedDate === undefined || selectedDate === "") return row;

      const selectedDateString = selectedDate?.toLocaleDateString("pt-BR");
      return row?.created_at?.split(" ")[0] === selectedDateString?.split(" ")[0];
    })
    .sort(
      (a, b) =>
        new Date(convertDateFormat(b?.created_at)) -
        new Date(convertDateFormat(a?.created_at))
    );

  sortedRows.map((guide, index) => {
    return (rowsTeste[index] = {
      guia: <TableRowField value={guide.numero_guia} />,
      microchip: <TableRowField value={guide.microchip_number} />,
      pet: <TableRowField value={guide.pet_name} />,
      tutor: <TableRowField value={guide.tutor} />,
      status: <TableRowField value={guide.status} type="status" />,
      data: <TableRowField value={guide.created_at} date />,
      acoes: <ActionButton guide={guide} />,
    });
  });

  return {
    columns: [
      { Header: "guia", accessor: "guia", width: "15%", align: "left" },
      { Header: "microchip", accessor: "microchip", align: "center" },
      { Header: "pet", accessor: "pet", align: "center" },
      { Header: "tutor", accessor: "tutor", align: "center" },
      {
        Header: "data de solicitação",
        accessor: "data",
        width: "5%",
        align: "center",
      },
      { Header: "status", accessor: "status", width: "5%", align: "center" },
      { Header: "ações", accessor: "acoes", width: "5%", align: "center" },
    ],

    rows: rowsTeste,
  };
}
