
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js'
import jwt_decode from 'jwt-decode'
import AWS from 'aws-sdk'

let cognitoAttributeList = [];

//udnaBackOffice
// const poolData = {
//     UserPoolId : "us-east-1_hc7MEZfw3",
//     ClientId : "50m686qt9ipkcpbd2ctdvjak81"
// };


//udnaB2B
const poolData = {
    UserPoolId : process.env.REACT_APP_POOL_KEY,
    ClientId : process.env.REACT_APP_CLIENT_KEY
};

const attributes = (key, value) => {
    return {
        Name : key,
        Value : value
    }
};

export function setCognitoAttributeList(email, agent) {
    let attributeList = [];
    attributeList.push(attributes('email',email));
    attributeList.forEach(element => {
        cognitoAttributeList.push(new AmazonCognitoIdentity.CognitoUserAttribute(element));
    });
}

export function getCognitoAttributeList() {
    return cognitoAttributeList;
}

export function getCognitoUser(email) {
    const userData = {
        Username : email,
        Pool: getUserPool()
    };
    return new AmazonCognitoIdentity.CognitoUser(userData);
}


export function getUserPool(){
    return new AmazonCognitoIdentity.CognitoUserPool(poolData);
}

export function getAuthDetails(email, password){
    var authenticationData = {
        Username: email,
        Password : password,
    }
    return new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);
}

export function initAWS (region = process.env.REACT_APP_LOCATION_KEY, identityPoolId = process.env.REACT_APP_POOL_KEY) {
    AWS.config.region = region;
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: identityPoolId,
    });
}

export function decodeJWTToken(token){
    const { email, exp, auth_time, token_use, sub} = jwt_decode(token.idToken);
    return {token, email, exp, uid: sub, auth_time, token_use};
}


