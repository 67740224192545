// react hooks
import { useState } from "react";

// @mui components
import {
  Box,
  Button,
  Modal,
  TextField,
  Typography,
  Autocomplete,
} from "@mui/material";

// Application context
import { useUsersContext } from "../../../context/UserContext";

// Utils functions and components
import {
  createUserWithRandomPassword,
  updateInfosPro,
} from "../../../utils/axios";
import { generateRandomPassword } from "../../../utils/generateRandomPassword";
import { formattedStateCode } from "../../../utils/StateCodes";

export default function RegisterVeterinariansModal({
  veterinarianModalState,
  handleVeterinarianModal,
}) {
  const { changeLoadingDash, handleErrorData, handleErrorModalState } =
    useUsersContext();

  const [veterinarianData, setVeterinarianData] = useState({
    vet_name: "",
    vet_cpf: "",
    vet_crmv: "",
    vet_state: formattedStateCode[0],
    vet_ativo: true,
    vet_email: "",
  });
  const [inputValue, setInputValue] = useState("");

  const cnpj = localStorage.getItem("cnpj");
  const email = localStorage.getItem("email");

  const handleChangeInput = (key, value) => {
    setVeterinarianData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  async function handleCreateUser(email) {
    const password = await generateRandomPassword();
    const cnpj = localStorage.getItem("cnpj");

    const { data, status } = await createUserWithRandomPassword(
      email,
      password,
      cnpj
    );

    if (status !== 200) {
      handleErrorData({
        title: "Erro ao carregar os dados",
        message: data?.message,
      });

      handleErrorModalState(true);
      changeLoadingDash(false);
      return;
    }

    handleErrorData({
      title: "Criação concluída!",
      message: `Veterinario cadastrado com sucesso.`,
      type: "success",
    });
    handleErrorModalState(true);

    changeLoadingDash(false);
  }

  async function handleRegisterVeterinarian(item) {
    changeLoadingDash(true);

    const body = { ...item, clinica_cnpj: cnpj, clinica_email: email };

    const { data, status } = await updateInfosPro(body, "colaboradores");

    if (status !== 200) {
      handleErrorData({
        title: "Erro ao carregar os dados",
        message: data?.message,
      });

      handleErrorModalState(true);
      changeLoadingDash(false);
      return;
    }

    handleCreateUser(item.vet_email);
  }

  const textFields = [
    {
      label: "Nome",
      value: veterinarianData.vet_name,
      type: "input",
      onChangeKey: "vet_name",
    },
    {
      label: "CPF/CNPJ",
      value: veterinarianData.vet_cpf,
      type: "input",
      onChangeKey: "vet_cpf",
    },
    {
      label: "CRMV",
      value: veterinarianData.vet_crmv,
      type: "input",
      onChangeKey: "vet_crmv",
    },
    {
      label: "E-mail",
      value: veterinarianData.vet_email,
      type: "input",
      onChangeKey: "vet_email",
    },
    {
      label: "Estado",
      value: veterinarianData.vet_state,
      type: "autoComplete",
      onChangeKey: "vet_state",
      optionsArray: formattedStateCode,
    },
  ];

  return (
    <Modal
      open={veterinarianModalState}
      onClose={() => handleVeterinarianModal(false)}
    >
      <Box
        position="absolute"
        top="50%"
        left="50%"
        width="400px"
        padding="18px"
        component="form"
        boxShadow={3}
        sx={{
          backgroundColor: "#FFFFFF",
          borderRadius: "12px",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Box display="flex" flexDirection="column" gap={4}>
          <Typography variant="title" color="primary">
            Cadastrar Veterinário
          </Typography>

          <Box display="flex" flexDirection="column" gap={3}>
            {textFields.map((textField) => {
              return textField.type === "autoComplete" ? (
                <Autocomplete
                  required
                  key={textField.onChangeKey}
                  value={textField.value}
                  onChange={(event, value) =>
                    handleChangeInput(textField.onChangeKey, value)
                  }
                  inputValue={inputValue}
                  onInputChange={(event, value) => {
                    setInputValue(value);
                  }}
                  getOptionDisabled={false}
                  options={textField.optionsArray}
                  renderInput={(params) => (
                    <TextField {...params} label="Estado" />
                  )}
                />
              ) : (
                <TextField
                  required
                  helperText={textField.helperText}
                  key={textField.onChangeKey}
                  disabled={textField.disabled}
                  label={textField.label}
                  value={textField.value}
                  onChange={(event) =>
                    handleChangeInput(textField.onChangeKey, event.target.value)
                  }
                />
              );
            })}
          </Box>

          <Button
            variant="contained"
            onClick={() => handleRegisterVeterinarian(veterinarianData)}
          >
            Cadastrar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
