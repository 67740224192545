// Generic Components
import { ActionButton, TableRowField } from "../../../components";

export default function HistoryGuidesTableData(props) {
  if (Object.keys(props?.data).length === 0) return { columns: [], rows: [] };

  const cnpj = localStorage.getItem("cnpj");

  function createData(
    id,
    numero_guia,
    microchip_number,
    pet_name,
    name_credenciado,
    tipo_atendimento,
    created_at,
    status,
    cnpj_solicitante,
    cnpj_lab
  ) {
    return {
      id,
      numero_guia,
      microchip_number,
      pet_name,
      name_credenciado,
      tipo_atendimento,
      created_at,
      status,
      cnpj_solicitante,
      cnpj_lab,
    };
  }

  const rows = props.data.map((result) => {
    return createData(
      result?.id,
      result?.numero_guia,
      result?.microchip_number,
      result?.pet_name,
      result?.name_credenciado,
      result?.tipo_atendimento,
      result?.created_at,
      result?.status,
      result?.cnpj_solicitante,
      result?.cnpj_lab
    );
  });

  let rowsTeste = [];

  function convertDateFormat(dateTimeString) {
    if (dateTimeString) {
      const [date, time] = dateTimeString.split(" ");

      if (time) {
        const [day, month, year] = date.split("/");
        const [hour, minute, second] = time.split(":");
        return `${month}/${day}/${year} ${hour}:${minute}:${second}`;
      } else {
        const [day, month, year] = date.split("/");
        return `${month}/${day}/${year}`;
      }
    }

    return "";
  }

  // Ordenar os dados com base na propriedade numero, status e data
  const sortedRows = rows
    ?.filter((row) => row?.cnpj_solicitante === cnpj || row?.cnpj_lab === cnpj)
    .filter((row) => {
      if (props.search === "") return row;
      return row?.numero_guia.includes(props.search);
    })
    .filter((row) => {
      if (props.searchByMicrochip === "") return row;
      return row?.microchip_number.includes(props.searchByMicrochip);
    })
    .filter((row) => {
      if (props.typeSearch === "") return row;
      return row?.tipo_atendimento.includes(props.typeSearch);
    })
    .filter((row) => {
      if (props?.selectedDate === undefined || props?.selectedDate === "")
        return row;

      const selectedDateString =
        props?.selectedDate?.toLocaleDateString("pt-BR");
      return (
        row?.created_at?.split(" ")[0] === selectedDateString?.split(" ")[0]
      );
    })
    .sort(
      (a, b) =>
        new Date(convertDateFormat(b.created_at)) -
        new Date(convertDateFormat(a.created_at))
    );

  sortedRows.map((guide, index) => {
    return (rowsTeste[index] = {
      guia: <TableRowField value={guide.numero_guia} />,
      pet: <TableRowField value={guide.pet_name} />,
      clinica: <TableRowField value={guide.name_credenciado} />,
      tipo: (
        <TableRowField
          value={guide.tipo_atendimento}
          textTransform="capitalize"
        />
      ),
      data: <TableRowField value={guide.created_at} date />,
      status: <TableRowField value={guide.status} type="status" />,
      acoes: <ActionButton guide={guide} />,
    });
  });

  return {
    columns: [
      { Header: "guia", accessor: "guia", width: "15%", align: "left" },
      { Header: "pet", accessor: "pet", align: "center" },
      { Header: "clínica", accessor: "clinica", align: "left" },
      { Header: "tipo", accessor: "tipo", width: "10%", align: "left" },
      {
        Header: "data de solicitação",
        accessor: "data",
        width: "5%",
        align: "center",
      },
      { Header: "status", accessor: "status", width: "5%", align: "center" },
      { Header: "ações", accessor: "acoes", width: "5%", align: "center" },
    ],

    rows: rowsTeste,
  };
}
