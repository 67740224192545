import { Person } from "@mui/icons-material";
import { IconButton } from "@mui/material";

function ProfileIcon({ handleNavigate }) {
  return (
    <IconButton
      onClick={() => handleNavigate("/pro/perfil")}
      sx={{
        background: "#FFFFFF",
        border: "2px solid #FFFFFF",
        borderRadius: "55px",
        width: "55px",
        height: "55px",
      }}
    >
      <Person fontSize="medium" color="primary" />
    </IconButton>
  );
}

export default ProfileIcon;
