import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import jwt_decode from "jwt-decode";
import AWS from "aws-sdk";
import * as AwsConfig from "./AwsConfig";

function failureCallback(error) {
  console.log("It failed with " + error);
}

function signUp(email, password, agent = "none") {
  return new Promise((resolve) => {
    AwsConfig.initAWS();
    AwsConfig.setCognitoAttributeList(email, agent);
    AwsConfig.getUserPool().signUp(
      email,
      password,
      AwsConfig.getCognitoAttributeList(),
      null,
      function (err, result) {
        if (err) {
          alert("Houve um problema com a criação da conta");
          return resolve({ statusCode: 422, response: err });
        }
        const response = {
          username: result.user.username,
          userConfirmed: result.userConfirmed,
          userAgent: result.user.client.userAgent,
        };
        alert("Conta criada com sucesso");

        return resolve({ statusCode: 201, response: response });
      }
    );
  });
}

function verify(email, code) {
  return new Promise((resolve) => {
    AwsConfig.getCognitoUser(email).confirmRegistration(
      code,
      true,
      (err, result) => {
        if (err) {
          alert("Erro ao confirmar o código");
          return resolve({ statusCode: 422, response: err });
        }

        return resolve({ statusCode: 400, response: result });
      }
    );
  });
}

function resetPassword(email) {
  return new Promise((resolve) => {
    const cognitoUser = AwsConfig.getCognitoUser(email);
    cognitoUser.forgotPassword({
      onSuccess: function () {
        alert(
          "Um código de redefinição de senha foi enviado para o seu email."
        );
        return resolve({ statusCode: 200, response: "CodeSent" });
      },
      onFailure: function (err) {
        alert("Erro ao solicitar a redefinição de senha: " + err.message);
        return resolve({ statusCode: 400, response: err });
      },
    });
  });
}

function confirmPassword(email, code, newPassword) {
  return new Promise((resolve) => {
    const cognitoUser = AwsConfig.getCognitoUser(email);
    cognitoUser.confirmPassword(code, newPassword, {
      onSuccess: function () {
        alert("Senha redefinida com sucesso.");
        return resolve({ statusCode: 200, response: "PasswordReset" });
      },
      onFailure: function (err) {
        alert("Erro ao redefinir a senha: " + err.message);
        return resolve({ statusCode: 400, response: err });
      },
    });
  });
}

function signIn(email, password) {
  const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
    Username: email,
    Pool: AwsConfig.getUserPool(),
  });
  return new Promise((resolve) => {
    AwsConfig.getCognitoUser(email).authenticateUser(
      AwsConfig.getAuthDetails(email, password),
      {
        onSuccess: (result) => {
          const token = {
            accessToken: result.getAccessToken().getJwtToken(),
            idToken: result.getIdToken().getJwtToken(),
            refreshToken: result.getRefreshToken().getToken(),
          };

          return resolve({
            statusCode: 201,
            response: AwsConfig.decodeJWTToken(token),
          });
        },

        onFailure: (err) => {
          return resolve({
            statusCode: 400,
            response: err.message || JSON.stringify(err),
          });
        },
      }
    );
  }).catch(failureCallback);
}

export async function SignUp(email, password) {
  const response = await signUp(email, password);
}

export async function Verify(email, codigo) {
  const response = await verify(email, codigo);
}

export async function ResetPassword(email) {
  const response = await resetPassword(email);
}

export async function ConfirmPassword(email, code, newPassword) {
  const response = await confirmPassword(email, code, newPassword);
}
