// react-router-dom hooks and components
import { Navigate, Outlet } from "react-router-dom";

// Application Users context
import { useUsersContext } from "../context/UserContext";

export default function ProtectedRoutes(props) {
  const { isAuthenticated, loading } = useUsersContext();

  const useAuth = () => {
    const user = { loggedIn: localStorage.getItem("token") };
    return user && user.loggedIn;
  };

  const isAuth = useAuth();

  return isAuth ? <Outlet /> : <Navigate to="/" />;
}
