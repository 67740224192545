import Guides from "../assets/images/guias.png";
import Logout from "../assets/images/logout-pro.png";
import HistoryGuides from "../assets/images/historico-guias.png";
import GenerateGuide from "../assets/images/gerar-guia.png";
import LaboratoryGuides from "../assets/svg/guias-laboratoriais.svg";
import PendingGuides from "../assets/svg/guias-pendentes.svg";

const routes = [
  {
    type: "collapse",
    name: "Gerar guia",
    key: "guias",
    icon: (
      <img
        src={GenerateGuide}
        alt="Ícone de gerar guia"
        width={22}
        height={22}
      />
    ),
    route: "/pro/guias",
  },
  {
    type: "collapse",
    name: "Guias pendentes",
    key: "guiasPendentes",
    icon: (
      <img
        src={PendingGuides}
        alt="Ícone guias pendentes"
        width={22}
        height={22}
      />
    ),
    route: "/pro/pendentes",
  },
  {
    type: "collapse",
    name: "Guias laboratoriais",
    key: "guiasLaboratoriais",
    icon: (
      <img
        src={LaboratoryGuides}
        alt="Ícone guias laboratoriais"
        width={22}
        height={22}
      />
    ),
    route: "/pro/laboratoriais",
  },
  {
    type: "collapse",
    name: "Guias glosadas",
    key: "guiasGlosadas",
    icon: (
      <img src={Guides} alt="Ícone de guias glosas" width={22} height={22} />
    ),
    route: "/pro/glosadas",
  },
  {
    type: "collapse",
    name: "Histórico de guias",
    key: "guiasEmitidas",
    icon: (
      <img src={Guides} width={22} alt="Ícone histórico de guias" height={22} />
    ),
    route: "/pro/historico",
  },
  // {
  //   type: "collapse",
  //   name: "Recebimentos",
  //   key: "recebimentos",
  //   icon: (
  //     <img
  //       src={HistoryGuides}
  //       alt="Ícone de recebimentos do credenciado"
  //       width={22}
  //       height={22}
  //     />
  //   ),
  //   route: "/pro/recebimentos",
  // },
  {
    type: "collapse",
    name: "Recebimentos",
    key: "recebimentos",
    icon: <img src={HistoryGuides}  width={22} height={22}/>,
    route: "/pro/recebimentos",
    items: [
      {
        type: "collapse",
        name: "Honorários",
        key: "honorarios",
        route: "/pro/recebimentos",
      },
      {
        type: "collapse",
        name: "Indicações",
        key: "indicacoes",
        route: "/pro/recebimentos/indicacoes",
      },
    ],
  },
  {
    type: "collapse",
    name: "Sair da conta",
    key: "logout",
    icon: <img src={Logout} alt="Ícone de logout" width={22} height={22} />,
    route: "/",
  },
];

export default routes;
