import typography from "../../base/typography";

// Material Dashboard 2 React Helper Functions
import pxToRem from "../../functions/pxToRem";
import colors from "../../base/colors";
import border from "../../base/borders";

const { white, secondary, primary, error, success, download } = colors;
const { size } = typography;
const { borderRadius } = border;

const contained = {
  base: {
    backgroundColor: white.main,
    minHeight: pxToRem(40),
    color: white.main,
    padding: `${pxToRem(10)} ${pxToRem(24)}`,
    borderRadius: borderRadius.xl,

    "&:hover": {
      backgroundColor: white.main,
    },

    "&:active, &:active:focus, &:active:hover": {
      opacity: 0.85,
    },

    "& .material-icon, .material-icons-round, svg": {
      fontSize: `${pxToRem(16)} !important`,
    },
  },

  small: {
    minHeight: pxToRem(32),
    padding: `${pxToRem(6)} ${pxToRem(16)}`,
    fontSize: size.xs,

    "& .material-icon, .material-icons-round, svg": {
      fontSize: `${pxToRem(12)} !important`,
    },
  },

  large: {
    minHeight: pxToRem(47),
    padding: `${pxToRem(12)} ${pxToRem(28)}`,
    fontSize: size.sm,

    "& .material-icon, .material-icons-round, svg": {
      fontSize: `${pxToRem(22)} !important`,
    },
  },

  primary: {
    backgroundColor: primary.main,

    "&:hover": {
      backgroundColor: primary.main,
    },
  },

  secondary: {
    backgroundColor: secondary.light,
    color: secondary.main,

    "&:hover": {
      backgroundColor: secondary.light,
    },
  },

  download: {
    backgroundColor: download.light,
    color: download.main,

    "&:hover": {
      backgroundColor: download.light,
    },
  },

  success: {
    backgroundColor: success.lighter,
    color: success.main,
    fontSize: size.sm,

    "&:hover": {
      backgroundColor: success.light,
    },
  },

  error: {
    backgroundColor: error.lighter,
    color: error.main,
    fontSize: size.sm,

    "&:hover": {
      backgroundColor: error.light,
    },
  },
};

export default contained;
