// input mask component
import PropTypes from "prop-types";
import InputMask from "react-input-mask";

// @mui components
import { TextField } from "@mui/material";

const onlyNumbers = (str) => str.replace(/[^0-9]/g, "");

const MaskedTextField = ({
  value,
  onChange,
  name,
  mask,
  placeholder,
  onBlur,
}) => {
  function handleChange(event) {
    onChange({
      ...event,
      target: {
        ...event.target,
        name,
        value: onlyNumbers(event.target.value),
      },
    });
  }

  return (
    <InputMask
      name={name}
      mask={mask}
      maskChar=""
      value={value}
      onChange={handleChange}
      onBlur={(e) => {
        if (onlyNumbers(e.target.value).length === 0 || !onBlur) return;
        onBlur(onlyNumbers(e.target.value));
      }}
    >
      {() => (
        <TextField
          required
          style={{ width: "100%" }}
          placeholder={placeholder}
        />
      )}
    </InputMask>
  );
};

MaskedTextField.propTypes = {
  mask: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
};

export default MaskedTextField;
