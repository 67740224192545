// react hooks
import { useState, useEffect } from "react";

// axios instance
import axios from "axios";

// @mui components
import {
  Box,
  Button,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";

// @mui styles
import { styled } from "@mui/material/styles";

// Application context
import { useUsersContext } from "../../../context/UserContext";

const PetSubscriptionBox = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400px",
  backgroundColor: "white",
  border: " 2px solid #B8BBFF",
  paddingTop: "18px",
  paddingBottom: "18px",
  borderRadius: "12px",
}));

const PetSubscriptionForm = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "18px",
  width: "90%",
  marginLeft: "auto",
  marginRight: "auto",
}));

const petGenders = [
  {
    value: "Macho",
    label: "Macho",
  },
  {
    value: "Fêmea",
    label: "Fêmea",
  },
];

const petSpecimens = [
  {
    value: "Gato",
    label: "Gato",
  },
  {
    value: "Cachorro",
    label: "Cachorro",
  },
];

export default function RegisterPetModal(props) {
  const [open, setOpen] = useState(props.open);
  const [petId, setPetId] = useState();
  const [petRegisterData, setPetRegisterData] = useState({
    pet_name: "",
    pet_id: "",
    pet_nascimento: "",
    pet_especie: "",
    pet_raca: "",
    pet_sexo: "",
  });
  const [isUpgrade, setIsUpgrade] = useState(false);
  const [upgradeOption, setUpgradeOption] = useState("");
  const [upgradeOptionData, setUpgradeOptionData] = useState(null);

  const [listaPets, setListaPets] = useState();
  const [petSpecie, setPetSpecie] = useState(
    props.dados?.metadata?.M?.pet_especie?.S.trim() || ""
  ); // Use o estado para Espécie
  const [petGender, setPetGender] = useState(
    props.dados?.metadata?.M?.pet_sexo?.S.trim() || ""
  );

  const { changeLoadingDash } = useUsersContext();
  const changed_by = localStorage.getItem("email");
  function handleModalOpen() {
    setOpen(true);
  }

  useEffect(() => {
    setOpen(props.open);
    if (props.dados) {
      setPetRegisterData((prevData) => ({
        ...prevData,
        pet_id: props.dados?.id?.S,
        pet_name: props.dados?.metadata?.M?.pet_name?.S || "",
        pet_nascimento: props.dados?.metadata?.M?.pet_nascimento?.S || "",
        pet_especie: props.dados?.metadata?.M?.pet_especie?.S.trim() || "",
        pet_raca: props.dados?.metadata?.M?.pet_raca?.S || "",
        pet_sexo: props.dados?.metadata?.M?.pet_sexo?.S.trim() || "",
      }));
    }
  }, [props.open, props.dados]);

  let pets = [];

  listaPets
    ?.filter((pet) => pet?.metadata?.pet_name !== "")
    .map((pet) =>
      pets.push({
        pet_name: pet?.metadata?.pet_name,
        pet_id: pet?.metadata?.pet_id,
        pet_nascimento: pet?.metadata?.pet_nascimento,
        pet_sexo: pet?.metadata?.pet_sexo,
        pet_raca: pet?.metadata?.pet_raca,
        pet_especie: pet?.metadata?.pet_especie,
      })
    );

  function getTablePet(id) {
    const api = `https://q7amrtd8t8.execute-api.us-east-1.amazonaws.com/dev/customer/pets/list_by_params?params=id&values=${id}`;
    // 'https://q7amrtd8t8.execute-api.us-east-1.amazonaws.com/dev/customer/pets/list_all'
    // 'https://q7amrtd8t8.execute-api.us-east-1.amazonaws.com/dev/erp_management/pets'

    axios
      .get(
        api,
        // { params: { data: dados } },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        setPetId(response?.data[0]?.pet_id?.S);
        setPetRegisterData({ pet_id: response?.data[0]?.pet_id?.S });
      })
      .catch((error) => {
        console.error(
          "🚀 ~ file: RegisterPetModal.jsx:147 ~ getTablePet ~ error:",
          error
        );
      });
  }

  function getPets(customer_id) {
    // changeLoadingDash(true)
    axios
      .get(
        `https://q7amrtd8t8.execute-api.us-east-1.amazonaws.com/dev/customer/pets/get_vindi?id=${customer_id}`,
        {
          headers: {
            "Content-type": "application/json",
          },
        }
      )
      .then((resp) => {
        setListaPets(resp?.data);
      })
      .catch((error) => {
        console.error(
          "🚀 ~ file: RegisterPetModal.jsx:169 ~ getPets ~ error:",
          error
        );
      });
  }

  const handleModalClose = () => {
    setOpen(false);
  };

  const handleChangeInput = (key, value) => {
    setPetRegisterData((prevState) => ({
      ...prevState,
      [key]: value,
    }));

    // Atualize os estados petSpecie e petGender quando o usuário faz uma seleção
    if (key === "pet_especie") {
      setPetSpecie(value);
    } else if (key === "pet_sexo") {
      setPetGender(value);
    }
  };

  const handleChangeUpgrade = (key, value) => {
    setUpgradeOptionData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const upgradeOptions = [
    { value: "opcao1", label: "Opção 1" },
    { value: "opcao2", label: "Opção 2" },
    { value: "opcao3", label: "Opção 3" },
  ];

  function handlePetRegistrationLambda(item) {
    changeLoadingDash(true);

    const api =
      "https://q7amrtd8t8.execute-api.us-east-1.amazonaws.com/dev/vindi/pets/send_metadata";

    axios
      .put(
        api,
        {
          id: props?.dados?.id?.S,
          log: {
            edited_by: changed_by,
            application: "pro",
            type: "atualizar dados pet",
            info: petRegisterData,
            customer_id: props?.dados?.id?.S,
          },
          trigger: {
            metadata: petRegisterData,
            // bill_items: [
            //   {
            //     product_id: 1308376,
            //     amount:
            //       this.state.valorTotalEscolhido === ''
            //         ? guiaSelecionada.value_to_pay
            //         : this.state.valorTotalEscolhido,
            //     quantity: 1,
            //     pricing_schema: {
            //       price:
            //         this.state.valorTotalEscolhido === ''
            //           ? guiaSelecionada.value_to_pay
            //           : this.state.valorTotalEscolhido,
            //       minimum_price: null,
            //       schema_type: 'flat',
            //       pricing_ranges: [],
            //     },
            //   },
            // ],
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        changeLoadingDash(false);
        alert("Dados atualizados com sucesso.");
        setOpen(false);
      })
      .catch((error) => {
        console.error(
          "🚀 ~ file: RegisterPetModal.jsx:260 ~ handlePetRegistrationLambda ~ error:",
          error
        );
        changeLoadingDash(false);
        alert("Ocorreu algum erro. Tente novamente");
        setOpen(false);
      });
  }

  const textFields = [
    {
      label: "Nome",
      value: petRegisterData.pet_name,
      onChangeKey: "pet_name",
    },
    {
      label: "Espécie",
      onChangeKey: "pet_especie",
      select: true,
      selectProps: { sx: { height: "48px" } },
      selectArray: petSpecimens,
    },
    {
      label: "Sexo",
      onChangeKey: "pet_sexo",
      select: true,
      selectProps: { sx: { height: "48px" } },
      selectArray: petGenders,
    },
    {
      label: "Raça",
      value: petRegisterData.pet_raca,
      onChangeKey: "pet_raca",
      helperText: "SRD caso não saiba a raça",
    },
    {
      label: "Data de Nascimento",
      value: petRegisterData.pet_nascimento,
      onChangeKey: "pet_nascimento",
      helperText: "DD/MM/AAAA",
    },
  ];

  return (
    <>
      <Modal open={open} onClose={() => handleModalClose()}>
        <PetSubscriptionBox component="form" noValidate sx={{ boxShadow: 3 }}>
          <PetSubscriptionForm>
            <Typography variant="h3" color="#32D1A0">
              Atualizar Dados
            </Typography>

            <>
              {textFields.map((textField) => {
                return textField.select ? (
                  <TextField
                    required
                    key={textField.onChangeKey}
                    label={textField.label}
                    value={textField.value}
                    onChange={(event) =>
                      handleChangeInput(
                        textField.onChangeKey,
                        event.target.value
                      )
                    }
                    select
                    SelectProps={textField.selectProps}
                  >
                    {textField.selectArray.map((select) => (
                      <MenuItem key={select.value} value={select.value}>
                        {select.label}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : (
                  <TextField
                    required
                    helperText={textField.helperText}
                    key={textField.onChangeKey}
                    disabled={textField.disabled}
                    label={textField.label}
                    value={textField.value}
                    onChange={(event) =>
                      handleChangeInput(
                        textField.onChangeKey,
                        event.target.value
                      )
                    }
                  />
                );
              })}
            </>

            <Button
              sx={{ alignSelf: "center" }}
              onClick={() => handlePetRegistrationLambda(props?.user)}
            >
              Cadastrar
            </Button>
          </PetSubscriptionForm>
        </PetSubscriptionBox>
      </Modal>
    </>
  );
}
