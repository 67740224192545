// @mui components
import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

// Generic Components
import { TableRowField } from "../../../components"

const ResponsiveButton = styled(Button)(({ theme }) => ({
  width: "100%",
  marginBottom: "10px",
  [theme.breakpoints.up("sm")]: {
    width: "auto",
    marginBottom: 0,
  },
}));

export default function IndicacoesTableData({
  indicationsData,
  handleDescativeVeterinarian,
}) {
  if (indicationsData?.length === 0) return { columns: [], rows: [] };

  function createData(name, CPF, CRMV, state, active, createdDate) {
    return {
      name,
      CPF,
      CRMV,
      state,
      active,
      createdDate,
    };
  }

  const rows = indicationsData?.filter(result => result?.payment_status === 'paid').map((result) => {
    return (
      result &&
      createData(
        result?.customer_name,
        result.vet_cpf?.S,
        result.vet_crmv?.S,
        result?.pets[0]?.plano_nome,
        result?.amount,
        result.created_at?.S
      )
    );
  });

  let formattedRows = [];

  rows.map((user, index) => {
    const commissionValue = (user?.active * 0.1).toFixed(2);
    return (formattedRows[index] = {
      name: <TableRowField value={user?.name} />,
      CPF: <TableRowField value={user?.CPF} />,
      CRMV: <TableRowField value={user?.CRMV} />,
      state: <TableRowField value={user?.state} />,
      price: <TableRowField value={user?.active} />,
      comissao: <TableRowField value={commissionValue} />,
      status:  <ResponsiveButton
      variant="contained"
      color="primary"
      disableElevation
      disabled
      fontWeight="bold"
      style={{
        height: "40px",
        width: "120px",
        background: "#C5F4D9",
        boxShadow:
          "0px 1px 1px rgba(205, 205, 205, 0.25)",
        borderRadius: "5px",
        color: "#20CB98",
        fontWeight: "bold",
      }}
    >
      Ativo
    </ResponsiveButton>,
    });
  });

  return {
    columns: [
      { Header: "Nome", accessor: "name", width: "25%", align: "left" },
      { Header: "CPF do Tutor", accessor: "CPF", align: "left" },
      { Header: "Nome do Pet", accessor: "CRMV", align: "left" },
      { Header: "Plano", accessor: "state", width: "10%", align: "center" },
      { Header: "Valor", accessor: "price", width: "10%", align: "center" },
      { Header: "Comissão", accessor: "comissao", width: "10%", align: "center" },
      { Header: "Status", accessor: "status", width: "10%", align: "center" },
    ],

    rows: formattedRows,
  };
}



