// react hooks
import { useState } from "react";

// react-router-dom components and hooks
import { useNavigate } from "react-router-dom";

import axios from "axios";

// @mui components
import {
  Button,
  TextField,
  FormControlLabel,
  Grid,
  Box,
  Typography,
  Paper,
  Switch,
  useTheme,
  Link,
} from "@mui/material";

// Application Users context
import { useUsersContext } from "../../context/UserContext";

// Generic Components
import { PageLayout } from "../../components";

// Specific components
import ModalClinica from "./components/ModalClinica";

import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import jwt_decode from "jwt-decode";

// Utils functions and components
import { getUserProByParams } from "../../utils/axios";

// Images
import LifepetLogo from "../../assets/images/lifepet-pro.png";
import VeterinarianWithDog from "../../assets/images/life-pet-pro-veterinarian.png";

export default function SignIn() {
  const theme = useTheme();
  const {
    getLoggedUser,
    getEmail,
    getCnpj,
    handleCompletedOnboarding,
    changeLoadingPage,
    handleErrorModalState,
    handleErrorData,
  } = useUsersContext();
  const navigate = useNavigate();

  const [rememberMe, setRememberMe] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [cnpjs, setCnpjs] = useState([]);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const poolData = {
    UserPoolId: process.env.REACT_APP_POOL_KEY,
    ClientId: process.env.REACT_APP_CLIENT_KEY,
  };

  async function getCnpjCredenciado(email) {
    const api = `https://q7amrtd8t8.execute-api.us-east-1.amazonaws.com/dev/autorizador/get_veterinarian?params=${email}&timestamp=${new Date().getTime()}`;

    try {
      const response = await axios.get(api, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const clinicaCnpjs = response.data[0]?.clinica_cnpj;

    

      setCnpjs(Array.isArray(clinicaCnpjs) ? clinicaCnpjs : [clinicaCnpjs]);

      if (Array.isArray(clinicaCnpjs) && clinicaCnpjs.length > 0) {
       
        changeLoadingPage(false);
        setOpenModal(true);
      } else if (typeof clinicaCnpjs === "string") {
      
        localStorage.setItem("cnpj", clinicaCnpjs);
        navigate("/pro/guias");
      }
    } catch (error) {
      changeLoadingPage(false);
      alert("Erro! Verifique com o administrador.");
      console.error(
        "🚀 ~ file: index.js:68 ~ getLaboratorialGuides ~ error:",
        error
      );
    }
  }

  function getCognitoUser(email) {
    const userData = {
      Username: email,
      Pool: getUserPool(),
    };

    return new AmazonCognitoIdentity.CognitoUser(userData);
  }

  function getUserPool() {
    return new AmazonCognitoIdentity.CognitoUserPool(poolData);
  }

  function getAuthDetails(email, password) {
    const authenticationData = {
      Username: email,
      Password: password,
    };
    return new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);
  }

  function decodeJWTToken(token) {
    const {
      email,
      exp,
      auth_time,
      token_use,
      sub,
      "cognito:groups": groups,
    } = jwt_decode(token.idToken);
    return { token, email, exp, uid: sub, auth_time, token_use, groups };
  }

  //Cognito
  function failureCallback(error) {
    console.log("It failed with " + error);
  }

  function handleLoginErrors(error) {
    switch (error) {
      case "User is disabled.":
        changeLoadingPage(false);
        handleErrorModalState(true);
        handleErrorData({
          title: "Erro ao realizar login",
          message:
            "Usuário desabilitado, entre em contato com o administrador!",
        });
        break;

      case "Incorrect username or password.":
        changeLoadingPage(false);
        handleErrorModalState(true);
        handleErrorData({
          title: "Erro ao realizar login",
          message: "Verifique seu email e senha e tente novamente!",
        });
        break;

      default:
        changeLoadingPage(false);
        handleErrorModalState(true);
        handleErrorData({
          title: "Erro ao realizar login",
          message: "Erro inesperado, tente novamente!",
        });
        break;
    }
  }

  async function checkUserGroup(email, password) {
    try {
      // Configura o pool do usuário
      const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

      // Obtém o usuário a partir do email
      const userData = {
        Username: email,
        Pool: userPool,
      };
      const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

      // Autentica o usuário para obter o CognitoUserSession
      const authDetails = getAuthDetails(email, password);
      const userSession = await new Promise((resolve, reject) => {
        cognitoUser.authenticateUser(authDetails, {
          onSuccess: (session) => resolve(session),
          onFailure: (err) => reject(err),
        });
      });

      const userGroups = userSession?.getIdToken()?.payload?.["cognito:groups"];

      const userGroup = "veterinarios";
      const isInGroup = userGroups?.includes(userGroup);

      if (isInGroup) {
        return userGroup;
      } else {
        return "Usuário Básico";
      }
    } catch (error) {
      console.error("Erro ao verificar o grupo do usuário:", error);

      return null;
    }
  }

  async function signIn(email, password) {
    try {
      return await new Promise((resolve) => {
        const cognitoUser = getCognitoUser(email);
        cognitoUser.authenticateUser(getAuthDetails(email, password), {
          onSuccess: async (result) => {
            getLoggedUser(JSON.stringify(result.getIdToken().payload));

            const token = {
              accessToken: result.getAccessToken().getJwtToken(),
              idToken: result.getIdToken().getJwtToken(),
              refreshToken: result.getRefreshToken().getToken(),
            };

            if (result.getIdToken()?.payload?.['cognito:groups']) {
              localStorage.setItem(
                'grupo',
                result.getIdToken()?.payload?.['cognito:groups'][0]
              )
            }

            localStorage.setItem("email", result.getIdToken().payload.email);
            localStorage.setItem(
              "token",
              result.getAccessToken().getJwtToken()
            );

            const customTipoConta =
              result.getIdToken().payload["custom:tipoConta"];


            if (customTipoConta) {
              localStorage.setItem("tipoConta", customTipoConta);
            }

            if (result.getIdToken()?.payload?.["cognito:groups"]) {
              localStorage.setItem(
                "grupo",
                result.getIdToken()?.payload?.["cognito:groups"][0]
              );
            }

            localStorage.setItem(
              "user",
              JSON.stringify(result.getIdToken().payload)
            );

            const userGroup = await checkUserGroup(email);

            return resolve({
              statusCode: 201,
              response: decodeJWTToken(token),
            });
          },

          onFailure: (err) => {
            return resolve({
              statusCode: 400,
              response: err.message || JSON.stringify(err),
            });
          },
        });
      });
    } catch (error) {
      console.error("🚀 ~ file: Login.jsx:224 ~ signIn ~ error:", error);
      return failureCallback(error);
    }
  }

  const SignInVerify = async (e) => {
    const email = e.target[0].value;
    const password = e.target[2].value;

    e.preventDefault();
    changeLoadingPage(true);

    const response = await signIn(email, password);
 
    if (response.statusCode === 400) {
      handleLoginErrors(response.response);
    } else {
      if (response.response.email) {
        const accredited = await getUserProByParams(
          "email",
          response.response.email
        );

        const accreditedDataCnpj = accredited?.data?.cnpj;
        const accreditedDataOnboarding =
          accredited?.data?.onBoarding === undefined
            ? false
            : accredited?.data?.onBoarding;
        const accreditedAuthorized = accredited?.data?.liberado;



        localStorage.setItem("email", response.response.email);
        localStorage.setItem("cnpj", accreditedDataCnpj);
        localStorage.setItem("onBoarding", accreditedDataOnboarding);
        localStorage.setItem("authorized", accreditedAuthorized);
        localStorage.setItem("vet", "false");

        getEmail(response?.response.email);
        getCnpj(accreditedDataCnpj);
        handleCompletedOnboarding(accreditedDataOnboarding);
      }

      if (response.response.groups !== undefined) {
        
        getCnpjCredenciado(response?.response?.email);
        // changeLoadingPage(false);
        // navigate("/pro/guias", { replace: true });
      } else {
        changeLoadingPage(false);

        const onBoarding = localStorage.getItem("onBoarding");
        const accreditedAuthorized = localStorage.getItem("authorized");

        if (onBoarding === "false" && accreditedAuthorized === "true") {
          navigate("/pro/guias", { replace: true });

          return;
        } else if (onBoarding === "false" && accreditedAuthorized === "false") {
          navigate("/pro/guias", { replace: true });
          return;
        } else {
          navigate("/pro/guias", { replace: true });

          return;
        }
      }
    }
  };

  return (
    <PageLayout>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <Grid
          item
          xs={12}
          sm={9}
          md={8}
          lg={5}
          component={Paper}
          elevation={6}
          square
          sx={{ background: theme.palette.background.default }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width="70%"
            marginX="auto"
            marginTop={8}
          >
            <Box
              display="flex"
              justifyContent="start"
              width="100%"
              marginBottom={8}
              gap={1}
            >
              <img
                src={LifepetLogo}
                alt="lifepet-logo"
                width={200}
                height="auto"
              />
            </Box>
            <Box
              sx={{
                background: theme.palette.white.main,
                padding: 8,
                borderRadius: "20px",
                width: "100%",
              }}
            >
              <Box display="center" justifyContent="center" marginBottom={4}>
                <Typography variant="title" color="primary">
                  Seja bem vindo(a)!
                </Typography>
              </Box>

              <ModalClinica
                open={openModal}
                onClose={() => setOpenModal(false)}
                cnpjs={cnpjs}
              />

              <Box component="form" onSubmit={SignInVerify}>
                <TextField
                  id="email"
                  type="email"
                  name="email"
                  placeholder="Email"
                  autoComplete="email"
                  margin="normal"
                  required
                  fullWidth
                  autoFocus
                  InputLabelProps={{ sx: { alignItems: "center", padding: 1 } }}
                  InputProps={{ sx: { height: "60px", alignItems: "center" } }}
                />

                <TextField
                  id="password"
                  type="password"
                  name="password"
                  placeholder="Senha"
                  autoComplete="current-password"
                  margin="normal"
                  required
                  fullWidth
                  InputLabelProps={{ sx: { alignItems: "center", padding: 1 } }}
                  InputProps={{ sx: { height: "60px", alignItems: "center" } }}
                />

                <Grid container justifyContent="space-between" marginTop={1}>
                  <Grid>
                    <FormControlLabel
                      labelPlacement="end"
                      control={
                        <Switch
                          checked={rememberMe}
                          onChange={handleSetRememberMe}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label={
                        <Typography variant="subtitle" color="label.main">
                          Lembrar
                        </Typography>
                      }
                    />
                  </Grid>
                  <Grid>
                    <Link
                      data-cy="forgot-password"
                      href="/recuperar"
                      color="primary"
                      variant="subtitle"
                      textAlign="center"
                    >
                      Esqueceu a senha?
                    </Link>
                  </Grid>
                </Grid>

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{
                    fontSize: "20px",
                    fontWeight: "500",
                    lineHeight: "14px",
                    letterSpacing: "-1px",
                    textTransform: "capitalize",
                    marginTop: 6,
                    marginBottom: 2,
                    padding: 4,
                  }}
                >
                  entrar
                </Button>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  marginTop={2}
                >
                  <Typography
                    sx={{
                      color: "#9C9DA9",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "14px",
                      letterSpacing: "-1px",
                      marginRight: 1,
                    }}
                  >
                    Ainda não possui uma conta?
                  </Typography>

                  <Link
                    data-cy="register"
                    href="/cadastrar"
                    color={theme.palette.primary.main}
                    sx={{
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "14px",
                      letterSpacing: "-1px",
                    }}
                  >
                    Cadastre-se
                  </Link>
                </Grid>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  marginTop={2}
                >
                  <Typography
                    sx={{
                      color: "#9C9DA9",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "14px",
                      letterSpacing: "-1px",
                      marginRight: 1,
                    }}
                  >
                    É um colaborador? Clique e crie sua senha.
                  </Typography>
                  <Link
                    data-cy="create-password"
                    href="/recuperar"
                    color={theme.palette.primary.main}
                    sx={{
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "14px",
                      letterSpacing: "-1px",
                    }}
                  >
                    Criar
                  </Link>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={false}
          sm={3}
          md={4}
          lg={7}
          sx={{
            backgroundImage: `url(${VeterinarianWithDog})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: "#F5F7FB",
            backgroundSize: "1000px",
            backgroundPosition: "center",
          }}
        />
      </Grid>
    </PageLayout>
  );
}
