import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

// @mui components
import { Box, Button, Menu, Typography } from "@mui/material";

// Application contexts

// Utils
import { updateNotificationsViewed } from "../../../utils/axios";

//assets
import GreenDotSVG from "./GreenDotSVG";
import { useUsersContext } from '../../../context/UserContext';

function handleNotificationType(notification) {
  const date = notification?.date?.split(" ")[0].replaceAll("/", "-");
  const formattedDate = dayjs(date, "D M Y", "pt-br").format(`D [de] MMM`);

  const hour = notification?.date.split(" ")[1];
  const formattedHour = dayjs(hour, "HH mm", "pt-br").format(`HH:mm`);

  switch (notification.type) {
    case "SIGNED_GUIDE":
      return {
        message: `Guia ${notification.data.numero_guia}, do pet ${notification.data.pet_name} e tutor ${notification.data.tutor} foi assinada.`,
        date: `${formattedDate} às ${formattedHour}`,
      };
    case "PAID_GUIDE":
      return {
        message: `Guia ${notification.data.numero_guia}, do pet ${notification.data.pet_name} e tutor ${notification.data.tutor} foi paga.`,
        date: `${formattedDate} às ${formattedHour}`,
      };
    case "GLOSSED_GUIDE":
      return {
        message: `Guia ${notification.data.numero_guia}, do pet ${notification.data.pet_name} e tutor ${notification.data.tutor} foi glosada.`,
        date: `${formattedDate} às ${formattedHour}`,
      };
    default:
      return "Notificação desconhecida";
  }
}

function NotificationsMenu({
  anchorEl,
  handleClose,
  open,
  notifications,
  asyncGetNotifications,
}) {
  const { changeLoadingDash, handleErrorData, handleErrorModalState } =
    useUsersContext();

  const navigation = useNavigate();

  async function handleNotificationClick() {
    changeLoadingDash(true);

    const { data, status } = await updateNotificationsViewed(notifications);

    if (status !== 200) {
      handleErrorData({
        title: "Erro ao carregar os dados",
        message: data?.message,
      });

      handleErrorModalState(true);
      changeLoadingDash(false);
      return;
    }

    handleErrorData({
      title: "Atualização concluída!",
      message: "Notificações atualizadas com sucesso.",
      type: "success",
    });
    handleErrorModalState(true);

    await asyncGetNotifications();

    changeLoadingDash(false);
    handleClose();
  }

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      marginThreshold={55}
      PaperProps={{
        style: {
          marginTop: "5px",
          maxHeight: "550px",
          maxWidth: "420px",
          borderRadius: "20px",
          padding: "30px",
        },
        sx: {
          backgroundColor: "#FAFCFE !important",
        },
      }}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={2}
      >
        <Box>
          <Typography fontSize="18px" color="gray.dark">
            Suas notificações
          </Typography>
        </Box>
        <Box display="flex">
          <Button
            variant="text"
            color="primary"
            onClick={handleNotificationClick}
          >
            Marcar como lidas
          </Button>
        </Box>
      </Box>

      {notifications?.map((notification) => (
        <Box
          key={notification.id}
          display="flex"
          flexDirection="row"
          paddingX={2}
          paddingY={1}
          minHeight="90px"
          borderRadius="5px"
          border="0.8px solid #F5F7FB"
          mb={2}
          sx={{ backgroundColor: "#FFFFFF", cursor: "pointer" }}
          onClick={() =>
            navigation(`/pro/guia/${notification.data.numero_guia}`)
          }
        >
          <Box display="flex">
            <GreenDotSVG />
          </Box>
          <Box px={1}>
            <Box>
              <Typography fontSize="14px" color="gray.dark">
                {handleNotificationType(notification).message}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="end">
              <Typography fontSize="12px" color="#AFBCD2">
                {handleNotificationType(notification).date}
              </Typography>
            </Box>
          </Box>
        </Box>
      ))}
    </Menu>
  );
}

export default NotificationsMenu;
