import PropTypes from "prop-types"

// @mui components
import { Box, CircularProgress } from "@mui/material";

function Loading({ fullscreen = false }) {
  return (
    <Box
      position={fullscreen ? "fixed" : "relative"}
      top={fullscreen ? "0" : "auto"}
      left={fullscreen ? "0" : "auto"}
      width={fullscreen ? "100vw" : "auto"}
      height={fullscreen ? "100vh" : "auto"}
      display="flex"
      justifyContent="center"
      alignItems="center"
      marginBottom={fullscreen ? "0" : "20px"}
      zIndex={fullscreen ? "9999" : "0"}
      bgcolor={fullscreen ? "rgba(0, 0, 0, 0.5)" : "transparent"}
    >
      <CircularProgress color="primary" size={fullscreen ? "10rem" : "2rem"} />
    </Box>
  );
}

Loading.propTypes = {
  fullscreen: PropTypes.bool
}

export default Loading;
