import { useRef } from 'react'
import { Button } from '@mui/material'

function InputFileButton ({ status }) {
  const isDisabled = status !== 'Pendente'
  const inputRef = useRef(null)
  const [inputFiles, setInputFiles] = useState([])

  const handleFileInput = () => {
    inputRef.current.click()
  }

  const handleFileChange = event => {
    const fileObj = event.target.files && event.target.files[0]
    if (!fileObj) {
      return
    }

    // 👇️ reset file input
    event.target.value = null

  }

  const uploadToS3 = async (presignedUrl, file) => {
    try {
      const response = await axios.put(presignedUrl, file, {
        headers: {
          'Content-Type': file.type,
          'x-amz-acl': 'public-read'
        }
      })
      // console.log('Upload do arquivo para o S3 bem-sucedido:', response)
    } catch (error) {
      console.error(
        `Erro ao fazer upload do arquivo ${file.name} para o S3:`,
        error
      )
      throw error
    }
  }

  const handleSendToLambda = async () => {
    if (!inputFiles || inputFiles.length === 0) {
      console.error('Nenhum arquivo fornecido.')
      return
    }
    changeLoadingDash(true)

    const promises = inputFiles.map(async file => {
      try {
        const formData = new FormData()
        formData.append('file_name', file.name)
        formData.append('guia', numeroGuia)

        const response = await axios.post(
          'https://q7amrtd8t8.execute-api.us-east-1.amazonaws.com/dev/autorizador/generate_attached_url',
          formData,
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json'
            }
          }
        )

        const presignedUrl = response.data.presigned_url

        await uploadToS3(presignedUrl, file)
        changeLoadingDash(false)
        // console.log(`Arquivo ${file.name} enviado com sucesso!`)
      } catch (error) {
        changeLoadingDash(false)
        alert('Algo deu errado no envio dos arquivos')
        console.error(`Erro ao enviar arquivo ${file.name}:`, error)
      }
    })

    await Promise.all(promises)
    changeLoadingDash(false)
    // console.log('Todos os arquivos foram enviados com sucesso!')
  }
  const handleUploadFile = event => {
    const files = event.target.files

    setInputFiles([...inputFiles, ...files])
  }

  return (
    <>
      <input
        id='contained-button-file'
        style={{ display: 'none' }}
        ref={inputRef}
        type='file'
        onChange={handleFileChange}
        disabled={isDisabled}
      />
      <label htmlFor='contained-button-file'>
        <Button
          color='secondary'
          variant='contained'
          component='span'
          disabled={isDisabled}
          onClick={handleFileInput}
        >
          Anexo
        </Button>
      </label>
    </>
  )
}

export default InputFileButton
