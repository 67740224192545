export async function generateRandomPassword(length = 12) {
  const lowercaseChars = "abcdefghijklmnopqrstuvwxyz";
  const uppercaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const digitChars = "0123456789";
  const specialChars = "!@#$%^&*()-_=+[]{}|;:,.<>?";

  const allChars = lowercaseChars + uppercaseChars + digitChars + specialChars;

  let password = "";

  // Ensure at least one lowercase letter
  password += lowercaseChars[Math.floor(Math.random() * lowercaseChars.length)];

  // Ensure at least one uppercase letter
  password += uppercaseChars[Math.floor(Math.random() * uppercaseChars.length)];

  // Ensure at least one digit
  password += digitChars[Math.floor(Math.random() * digitChars.length)];

  // Ensure at least one special character
  password += specialChars[Math.floor(Math.random() * specialChars.length)];

  // Fill the remaining password length with random characters
  for (let i = password.length; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * allChars.length);
    password += allChars[randomIndex];
  }

  // Shuffle the password to ensure the special characters, numbers, and uppercase letters are distributed randomly
  password = password
    .split("")
    .sort(() => Math.random() - 0.5)
    .join("");

  return password;
}
