// react hooks
import { useState } from "react";

// @mui components
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";

// Specific components
import InputFileButton from "./InputFileButton";

export default function DefenseDialog({
  procedure,
  openDefense,
  handleDefenseDialog,
  handleProcedureDefense,
  handleProcedureFile,
}) {
  const [procedureDefense, setProcedureDefense] = useState(procedure.defesa);

  return (
    <Dialog
      open={openDefense}
      onClose={() => handleDefenseDialog(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">Defender glosa</DialogTitle>
      <DialogContent>
        <DialogContentText>Insira a defesa para a glosa.</DialogContentText>
        <Box display="flex" alignItems="baseline" gap={1}>
          <TextField
            label="Defesa"
            fullWidth
            multiline
            required
            value={procedureDefense}
            onChange={(e) => setProcedureDefense(e.target.value)}
            sx={{ marginTop: 2 }}
          />
          <InputFileButton
            handleProcedureFile={handleProcedureFile}
            index={procedure.index}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={() => handleDefenseDialog(false)}>
          Cancelar
        </Button>
        <Button
          color="primary"
          autoFocus
          disabled={!procedureDefense}
          onClick={() => {
            handleProcedureDefense(procedureDefense, procedure.index);
            setProcedureDefense("");
            handleDefenseDialog(false);
          }}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
