// react hooks
import { useState } from "react";

// @mui components
import {
  Box,
  Button,
  Modal,
  TextField,
  Typography,
  Autocomplete,
} from "@mui/material";

// Application context
import { useUsersContext } from "../../../context/UserContext";

// Utils functions and components
import { updateInfosPro } from "../../../utils/axios";
import formattedBankCodes from "../../../utils/BankCodes";

export default function RegisterBankAccountModal({
  bankAccountModalState,
  handleBankAccountModal,
}) {
  const { changeLoadingDash, handleErrorData, handleErrorModalState } =
    useUsersContext();

  const cnpj = localStorage.getItem("cnpj");

  const [bankAccountData, setBankAccountData] = useState({
    recebedor: "Conta primária",
    conta: "",
    agencia: "",
    banco: formattedBankCodes[0],
    tipo_conta: "Corrente",
  });
  const [inputValue, setInputValue] = useState("");

  const handleChangeInput = (key, value) => {
    setBankAccountData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  async function handleBankAccountLambda(item) {
    changeLoadingDash(true);

    if (
      !bankAccountData.conta ||
      !bankAccountData.agencia ||
      !bankAccountData.banco
    ) {
      handleErrorData({
        title: "Erro ao enviar os dados",
        message: "Preencha todos os campos.",
      });

      handleErrorModalState(true);
      changeLoadingDash(false);
      return;
    }

    const { data, status } = await updateInfosPro(item, "conta_bancaria", cnpj);

    if (status !== 200) {
      handleErrorData({
        title: "Erro ao carregar os dados",
        message: data?.message,
      });

      handleErrorModalState(true);
      changeLoadingDash(false);
      return;
    }

    handleErrorData({
      title: "Criação concluída!",
      message: "Conta cadastrada com sucesso.",
      type: "success",
    });
    handleErrorModalState(true);

    changeLoadingDash(false);
    handleBankAccountModal(false);
  }

  const textFields = [
    {
      label: "Recebedor",
      value: bankAccountData.recebedor,
      type: "input",
      disabled: true,
    },
    {
      label: "Conta",
      value: bankAccountData.conta,
      type: "input",
      onChangeKey: "conta",
    },
    {
      label: "Agência",
      value: bankAccountData.agencia,
      type: "input",
      onChangeKey: "agencia",
    },
    {
      label: "Banco",
      value: bankAccountData.banco,
      onChangeKey: "banco",
      type: "autoComplete",
      optionsArray: formattedBankCodes,
    },
    {
      label: "Tipo de conta",
      value: bankAccountData.tipo_conta,
      type: "input",
      onChangeKey: "tipo_conta",
      disabled: true,
    },
  ];

  return (
    <Modal
      open={bankAccountModalState}
      onClose={() => handleBankAccountModal(false)}
    >
      <Box
        position="absolute"
        top="50%"
        left="50%"
        width="400px"
        padding="18px"
        component="form"
        boxShadow={3}
        sx={{
          backgroundColor: "#FFFFFF",
          borderRadius: "12px",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Box display="flex" flexDirection="column" gap={4}>
          <Typography variant="title" color="primary">
            Cadastrar Conta bancária
          </Typography>

          <Box display="flex" flexDirection="column" gap={3}>
            {textFields.map((textField) => {
              return textField.type === "autoComplete" ? (
                <Autocomplete
                  required
                  key={textField.onChangeKey}
                  value={textField.value}
                  onChange={(event, value) =>
                    handleChangeInput(textField.onChangeKey, value)
                  }
                  inputValue={inputValue}
                  onInputChange={(event, value) => {
                    setInputValue(value);
                  }}
                  getOptionDisabled={false}
                  options={textField.optionsArray}
                  renderInput={(params) => (
                    <TextField {...params} label="Banco" />
                  )}
                />
              ) : (
                <TextField
                  required
                  key={textField.onChangeKey}
                  disabled={textField.disabled}
                  label={textField.label}
                  value={textField.value}
                  onChange={(event) =>
                    handleChangeInput(textField.onChangeKey, event.target.value)
                  }
                />
              );
            })}
          </Box>

          <Button
            variant="contained"
            onClick={() => handleBankAccountLambda(bankAccountData)}
          >
            Cadastrar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
