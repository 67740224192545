import { Box, Typography, useTheme } from "@mui/material";

function StatusTypography({ value, ...rest }) {
  const theme = useTheme();

  function handleStatusColor() {
    switch (value) {
      case "Em processamento":
        return { textColor: "info.main", boxColor: "info.light" };
      case "Fechamento":
        return { textColor: "gray.main", boxColor: "black.light" };
      case "Pendente":
        return { textColor: "error.main", boxColor: "error.light" };
      case "Em análise":
        return { textColor: "warning.main", boxColor: "warning.light" };
      case "Pago":
        return { textColor: "success.main", boxColor: "success.light" };
      default:
        return { textColor: "gray.main", boxColor: "gray.light" };
    }
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{
        backgroundColor: handleStatusColor().boxColor,
        minWidth: "150px",
        minHeight: "40px",
        borderRadius: theme.borders.borderRadius.lg,
      }}
    >
      <Typography
        {...rest}
        variant="tableHeader"
        textTransform="uppercase"
        color={handleStatusColor().textColor}
      >
        {value}
      </Typography>
    </Box>
  );
}

export default StatusTypography;
