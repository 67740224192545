// react hooks
import { useState, useCallback, useEffect } from 'react'

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types'

// react-countdown is a library for countdown
import Countdown, { zeroPad } from 'react-countdown'

// axios is a library for making HTTP requests
import axios from 'axios'

// @mui components
import {
  Box,
  Button,
  Modal,
  Typography,
  TextField,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material'

// @mui icons
import { Warning } from '@mui/icons-material'

// Application context
import { useUsersContext } from '../../../context/UserContext'

// utils
import {
  deleteBillByParams,
  getGuides,
  getVindiBillByParams,
  updateGuideInfo
} from '../../../utils/axios'

// images
import WaitingImage from '../../../assets/images/dog-cat-love.png'
import FinishPayment from '../../../assets/images/finish-checkout.png'

export default function ChoosePaymentModal ({
  choosePaymentModalState,
  handleChoosePaymentModal,
  paymentData
}) {
  const { changeLoadingDash } = useUsersContext()

  const [chooseSubscription, setChooseSubscription] = useState({
    assinatura: ''
    // pet_id: "",
  })

  const [paymentMethod, setPaymentMethod] = useState('')
  const [isCredit, setIsCredit] = useState(false)
  const [isQrCode, setIsQrCode] = useState(false)
  const [paymentConfirmed, setPaymentConfirmed] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [assinado, setAssinado] = useState(false)
  const [qrCodePath, setQrCodePath] = useState()
  const [billID, setBillID] = useState()
  const [selectedButton, setSelectedButton] = useState('aplicativo') // Estado para rastrear o botão selecionado
  const [waitingTutor, setWaitingTutor] = useState(false)
  const [confirmedPaymentMethod, setConfirmedPaymentMethod] = useState('') // Estado para rastrear o botão de pagamento selecionado
  const [countdownState, setCountdownState] = useState(false) // Estado para rastrear o botão de pagamento selecionado

  const handleChooseSubscription = useCallback((value, key) => {
    setChooseSubscription(prevState => ({
      ...prevState,
      [key]: value
    }))
  }, [])

  const handleButtonClick = (event, newSelectedButton) => {
    if (newSelectedButton !== null) {
      setSelectedButton(newSelectedButton)
    }
  }

  const handlePaymentMethod = (event, newSelectedButton) => {
    if (newSelectedButton !== null) {
      setPaymentMethod(newSelectedButton)
    }
  }

  function onPressAssinarGuia () {
    changeLoadingDash(true)
    axios
      .post(
        'https://q7amrtd8t8.execute-api.us-east-1.amazonaws.com/dev/autorizador/generate?update=true',
        // { user: email, token_user: token },
        {
          microchip_number: paymentData?.microchip_number,
          assinado: 'true',
          id: paymentData?.guia,
          status: 'liberado'
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        }
      )
      .then(response => {
        if (response.data) {
          console.warn('passou', response.data)
          alert('Guia assinada')
        } else {
          console.warn('passou erro else')
          alert('Guia assinada')
        }
        changeLoadingDash(false)
        setAssinado(true)
        // getRow(response.data)
        // navigate('/teleconsultas/chamada', {
        //   state: { user: email, token_user: token }
        // })
      })
      .catch(error => {
        console.error(
          '🚀 ~ file: ChoosePaymentModal.jsx:122 ~ onPressAssinarGuia ~ error:',
          error
        )
        alert('Erro na assinatura')
        changeLoadingDash(false)
      })
  }

  function sleep (ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  function getIsCreditCard (id) {
    const api = `https://q7amrtd8t8.execute-api.us-east-1.amazonaws.com/dev/vindi/payment?id=${id}`

    axios
      .get(api, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      })
      .then(response => {
        if (response?.data?.payment_profiles.length > 0) {
          setIsCredit(true)
        }
      })
      .catch(error => {
        console.error(
          '🚀 ~ file: ChoosePaymentModal.jsx:149 ~ getIsCreditCard ~ error:',
          error
        )
      })
  }

  async function onPressAssinarPagarGuia (type) {
    // changeLoadingDash(true)
    setRefresh(true)
    setConfirmedPaymentMethod(true)

    const date = new Date()

    await axios
      .post(
        `https://q7amrtd8t8.execute-api.us-east-1.amazonaws.com/dev/autorizador/generate`,
        {
          id: paymentData.guia,
          microchip_number: paymentData.microchip_number,
          trigger: {
            customer_id: paymentData.customer_id,
            payment_method_code: type,
            due_at: date,
            bill_items: [
              {
                product_id: 1308376,
                amount: paymentData.value_to_pay,
                quantity: 1,
                pricing_schema: {
                  price: paymentData.value_to_pay,
                  minimum_price: null,
                  schema_type: 'flat',
                  pricing_ranges: []
                }
              }
            ]
          }
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        }
      )
      .then(async resp => {
        const retorno = JSON.parse(resp.data.message.replace(/'/g, '"'))

        if (type === 'pix') {
          setIsQrCode(true)
          setQrCodePath(retorno?.gateway_response_fields?.qrcode_path)
        }

        if (retorno.status === 'paid') {
          setWaitingTutor(true)
          changeLoadingDash(false)
          setPaymentConfirmed(true)
        } else {
          changeLoadingDash(false)
          await waitForPaymentConfirmation(retorno.id)
        }

        setRefresh(false)
      })
      .catch(error => {
        alert('Erro na operação. Tente novamente ')
        console.warn('erro', error)
      })
  }

  async function waitForPaymentConfirmation (faturaId) {

    const pollInterval = 240000 // Intervalo de 20s segundos (ajuste conforme necessário)

    while (!faturaId) {
      // console.log('entrou')
      setWaitingTutor(true)
      faturaId = await handleGetGuides()
      // console.log('fa', faturaId)

      await sleep(pollInterval)
    }

    setBillID(faturaId)

    while (true) {
      if (countdownState) {
        alert('Pagamento não confirmado')
        setRefresh(false)
        break
      }

      const status = await handleGetVindiBillByParams(faturaId)


      if (status === 'paid') {
        setPaymentConfirmed(true)
        setRefresh(false)
        break
      }

      if (status === 'canceled') {
        alert('Pagamento cancelado')
        setRefresh(false)
        break
      }

      await sleep(pollInterval)
    }
  }

  async function handleGetGuides () {
    if (!paymentData.guia) return

    const { data } = await getGuides(paymentData.guia, 'guia')

    const id = data[0]?.url?.id || ''

    setBillID(id)

    return id
  }

  async function handleGetVindiBillByParams (billID) {
    const { data } = await getVindiBillByParams(billID)

    const status = data?.bill?.status || 'pending'

    return status
  }

  async function handleUpdateGuideInfo () {
    changeLoadingDash(true)
    const numeroGuia = paymentData?.guia
    const microchip = paymentData?.microchip_number

    const { status } = await updateGuideInfo(
      numeroGuia,
      microchip,
      { motivo_canc: 'Espera PRO' },
      'status',
      'cancelada'
    )

    if (status === 200) {
      changeLoadingDash(false)
      handleChoosePaymentModal(false)
      setPaymentConfirmed(false)
      setWaitingTutor(false)
      setConfirmedPaymentMethod(false)
      setBillID('')
      alert('Guia cancelada com sucesso.')
    }
    else{
      changeLoadingDash(false)
      handleChoosePaymentModal(false)
      setPaymentConfirmed(false)
      setWaitingTutor(false)
      setConfirmedPaymentMethod(false)
      setBillID('')
      alert('Algo deu errado. Tente novamente.')
    }


    return status
  }

  async function handleDeleteBillByParams () {
    if (!billID) return 'canceled'
    const { data } = await deleteBillByParams(billID)

    return data?.bill?.status
  }

  async function handleCancelGuide () {
    changeLoadingDash(true)

    handleChoosePaymentModal(false)
      setPaymentConfirmed(false)
      setWaitingTutor(false)
      setConfirmedPaymentMethod(false)
      setBillID('')
    // const lambdaStatus = await handleUpdateGuideInfo()
    // const vindiStatus = await handleDeleteBillByParams()

    // if (vindiStatus === 'canceled' && lambdaStatus === 200) {
    //   changeLoadingDash(false)
    //   alert(
    //     countdownState === true
    //       ? 'Guia cancelada após tempo limite, favor recomeçar.'
    //       : 'Guia cancelada com sucesso.'
    //   )

    //   handleChoosePaymentModal(false)
    //   setPaymentConfirmed(false)
    //   setWaitingTutor(false)
    //   setConfirmedPaymentMethod(false)
    //   setBillID('')

    //   return
    // }

    changeLoadingDash(false)
    // alert('Algo deu errado, favor tentar cancelar novamente.')
  }

  async function handleFinishPayment () {
    handleChoosePaymentModal(false)
    setPaymentConfirmed(false)
    setWaitingTutor(false)
    setConfirmedPaymentMethod(false)
    setBillID('')
  }

  useEffect(() => {
    setCountdownState(false)
    getIsCreditCard(paymentData.customer_id)
  }, [])

  useEffect(() => {
    if (countdownState === true) {
      handleCancelGuide()
    }
  }, [countdownState])

  return (
    <Modal open={choosePaymentModalState}>
      <Box
        position='absolute'
        display='flex'
        flexDirection='column'
        padding={{ xs: 4, md: 8 }}
        gap={4}
        top='50%'
        left='50%'
        width={{ xs: '70%', lg: '60%', xl: '50%' }}
        boxShadow={1}
        sx={{
          backgroundColor: '#FFFFFF',
          borderRadius: '7px',
          transform: 'translate(-50%, -50%)'
        }}
      >
        {waitingTutor ? (
          <Box display='flex' flexDirection='column' alignItems='center'>
            {paymentConfirmed ? (
              <>
                <Box width={500} marginBottom={3}>
                  <Typography variant='h5'>
                    Uhul! Guia paga com sucesso. Você já pode prestar o serviço.
                  </Typography>
                </Box>

                <img src={FinishPayment} alt='Imagem de espera' />

                <Box
                  display='flex'
                  justifyContent='end'
                  width='100%'
                  marginTop={3}
                >
                  <Button
                    variant='contained'
                    onClick={() => handleFinishPayment()}
                  >
                    Finalizar
                  </Button>
                </Box>
              </>
            ) : (
              <>
                <Box width={500} marginBottom={2}>
                  <Typography variant='h5' fontWeight='bold' textAlign='center'>
                    Agora é com o Tutor! Peça para ele realizar o pagamento da
                    guia no aplicativo Lifepet.
                  </Typography>
                </Box>

                <img src={WaitingImage} alt='Imagem de espera' />

                <Countdown
                  date={Date.now() + 600000}
                  onComplete={() => setCountdownState(true)}
                  zeroPadTime={2}
                  renderer={({ minutes, seconds }) => {
                    return (
                      <Typography variant='h5' fontWeight='bold'>
                        {zeroPad(minutes)}:{zeroPad(seconds)}
                      </Typography>
                    )
                  }}
                />

                <Box
                  display='flex'
                  justifyContent='start'
                  width='100%'
                  marginTop={2}
                >
                  <Button
                    variant='contained'
                    color='error'
                    onClick={() => handleUpdateGuideInfo()}
                  >
                    Cancelar Guia
                  </Button>
                </Box>
              </>
            )}
          </Box>
        ) : (
          <>
            {!confirmedPaymentMethod && (
              <>
                <Typography variant='h5' fontWeight='bold'>
                  Você deseja que o tutor realize a assinatura ou pagamento da
                  coparticipação no:
                </Typography>

                <Box display='flex' justifyContent='space-between'>
                  <ToggleButtonGroup
                    value={selectedButton}
                    exclusive
                    onChange={handleButtonClick}
                    sx={{ width: '50%' }}
                  >
                    <ToggleButton
                      value='aplicativo'
                      variant='contained'
                      color='primary'
                    >
                      Aplicativo do tutor
                    </ToggleButton>
                  </ToggleButtonGroup>

                  <Box sx={{ marginInlineStart: 'auto' }}>
                    <ToggleButtonGroup
                      value={selectedButton}
                      exclusive
                      onChange={handleButtonClick}
                    >
                      <ToggleButton
                        value='painel'
                        variant='contained'
                        color='primary'
                      >
                        Painel do LifepetPro
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Box>
                </Box>
              </>
            )}

            {selectedButton === 'aplicativo' ? (
              <Button
                variant='contained'
                color='primary'
                onClick={() => waitForPaymentConfirmation()}
              >
                Confirmar
              </Button>
            ) : (
              <>
                {paymentData.status === 'assinatura' ? (
                  <>
                    {assinado ? (
                      <Typography
                        variant='h5'
                        fontWeight='bold'
                        sx={{ marginBottom: 2, alignSelf: 'center' }}
                      >
                        Guia assinada com sucesso!
                      </Typography>
                    ) : (
                      <>
                        <TextField
                          required
                          name='assinatura'
                          label='Assinatura (4 primeiros dígitos do CPF)'
                          type='text'
                          value={chooseSubscription.assinatura}
                          onChange={e =>
                            handleChooseSubscription(
                              e.target.value,
                              'assinatura'
                            )
                          }
                          onKeyDown={e => {
                            if (e.key === 'Enter') {
                              // console.log(chooseSubscription.assinatura)
                            }
                          }}
                        />
                        <Button
                          variant='contained'
                          color='primary'
                          onClick={() => onPressAssinarGuia()}
                        >
                          Confirmar
                        </Button>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {isQrCode ? (
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center'
                        }}
                      >
                        {refresh ? (
                          <>
                            <Typography
                              variant='h5'
                              fontWeight='bold'
                              textAlign='center'
                              marginBottom={5}
                            >
                              Agora é com o Tutor!
                            </Typography>

                            <Typography
                              variant='h5'
                              fontWeight='bold'
                              marginTop={2}
                            >
                              Valor a pagar: R$ {paymentData?.value_to_pay}
                            </Typography>

                            <object data={qrCodePath} type='image/svg+xml'>
                              Seu navegador não suporta SVG
                            </object>

                            <Countdown
                              date={Date.now() + 600000}
                              onComplete={() => setCountdownState(true)}
                              zeroPadTime={2}
                              renderer={({ minutes, seconds }) => {
                                return (
                                  <Typography variant='h5' fontWeight='bold'>
                                    {zeroPad(minutes)}:{zeroPad(seconds)}
                                  </Typography>
                                )
                              }}
                            />

                            <Box
                              display='flex'
                              justifyContent='start'
                              width='100%'
                              marginTop={2}
                            >
                              <Button
                                variant='contained'
                                color='error'
                                onClick={() => handleUpdateGuideInfo()}
                              >
                                Cancelar Guia
                              </Button>
                            </Box>
                          </>
                        ) : (
                          <>
                            <Typography
                              variant='h5'
                              fontWeight='bold'
                              marginBottom={5}
                            >
                              Guia paga com sucesso!
                            </Typography>

                            <Box
                              display='flex'
                              justifyContent='end'
                              width='100%'
                              marginTop={3}
                            >
                              <Button
                                variant='contained'
                                onClick={() => handleFinishPayment()}
                              >
                                Finalizar
                              </Button>
                            </Box>
                          </>
                        )}
                      </Box>
                    ) : (
                      <>
                        <Typography variant='h7'>
                          Selecione a forma de pagamento:
                        </Typography>

                        {!isCredit ? (
                          <Box display='flex' alignItems='center' gap={2}>
                            <Warning fontSize='large' color='warning' />
                            <Typography
                              variant='h7'
                              color='black'
                              fontWeight={500}
                            >
                              O tutor não possui cartão cadastrado. O cadastro é
                              realizado no aplicativo Lifepet.
                            </Typography>
                          </Box>
                        ) : null}

                        <Box
                          display='flex'
                          flexDirection='column'
                          alignItems='space-between'
                        >
                          <ToggleButtonGroup
                            value={paymentMethod}
                            exclusive
                            onChange={handlePaymentMethod}
                            sx={{ width: '100%' }}
                          >
                            <ToggleButton
                              value='pix'
                              variant='contained'
                              color='primary'
                              sx={{ width: '100%' }}
                            >
                              PIX
                            </ToggleButton>
                          </ToggleButtonGroup>

                          <ToggleButtonGroup
                            value={paymentMethod}
                            exclusive
                            onChange={handlePaymentMethod}
                            sx={{ width: '100%', marginTop: 2 }}
                          >
                            <ToggleButton
                              value='credit_card'
                              variant='contained'
                              color='primary'
                              sx={{ width: '100%' }}
                              disabled={!isCredit}
                            >
                              Cartão de crédito
                            </ToggleButton>
                          </ToggleButtonGroup>
                        </Box>

                        <Button
                          variant='contained'
                          color='primary'
                          onClick={() => onPressAssinarPagarGuia(paymentMethod)}
                          disabled={!paymentMethod}
                        >
                          Confirmar
                        </Button>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </Box>
    </Modal>
  )
}

ChoosePaymentModal.propTypes = {
  choosePaymentModalState: PropTypes.any,
  handleChoosePaymentModal: PropTypes.func,
  numberGuide: PropTypes.any,
  paymentData: PropTypes.shape({
    customer_id: PropTypes.any,
    guia: PropTypes.any,
    microchip_number: PropTypes.any,
    status: PropTypes.string,
    value_to_pay: PropTypes.any
  })
}
